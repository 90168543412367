import { RouteChildrenProps } from 'react-router-dom'

import usePage from '../hooks/usePage'
import PageLayout from 'components/PageLayout'
import { useTranslation } from 'react-i18next'
import useSaveOnBack from 'hooks/useSaveOnBack'
import { AllergiesAndMedicationsForm } from '@dentalux/ui-library-core'

const AllergiesPage: React.FC<RouteChildrenProps> = () => {
  const { onBack, initialValues = {}, onSubmit } = usePage()
  const { t } = useTranslation()
  const formikRef = useSaveOnBack({ onBack })

  return (
    <PageLayout title={t('category.anamnesis')} subtitle={t('subcategory.allergies_and_medications')}>
      <AllergiesAndMedicationsForm
        loading={false}
        onSubmit={onSubmit}
        initialValues={initialValues}
        innerRef={formikRef}
      />
    </PageLayout>
  )
}

export default AllergiesPage
