import { ToggleMultiCheckbox, combinedFields } from '@dentalux/ui-library-core'
import { useField } from 'formik'
import { useState } from 'react'

const MedicationsItem: React.FC = () => {
  const [field, , helpers] = useField('medications')

  const [takesOtherMedications, setTakesOtherMedications] = useState(field.value ? field.value.length > 0 : false)

  const handleValuesChange = (values: string[]) => {
    helpers.setValue(values)
  }

  const handleToggleChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setTakesOtherMedications(checked)
  }

  return (
    <ToggleMultiCheckbox
      label="field.do_you_regularly_take_any_other_medication"
      value={takesOtherMedications}
      options={combinedFields.medications.options}
      onSelectedValuesChange={handleValuesChange}
      onToggleChange={handleToggleChange}
      selectedValues={field.value}
      helperText={combinedFields.medications.helperText}
      allowCustomOptions
    />
  )
}

export default MedicationsItem
