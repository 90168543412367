import PageLayout from 'components/PageLayout'
import { PageProps } from 'hooks/usePage'
import referralSources from 'config/referralSources'
import ReferralItem from 'components/ReferralItem'
import { useTranslation } from 'react-i18next'
import useSaveOnBack from 'hooks/useSaveOnBack'
import { FormLayout, SubmitButton } from '@dentalux/ui-library-core'
import { Field, FieldProps, Formik, Form as FormikForm } from 'formik'

import { LayoutItem, Form } from '@dentalux/ui-library-core'

export type ReferralScreenProps = PageProps

const layout: LayoutItem[] = referralSources.map((source) => ({
  type: 'custom',
  xs: 6,
  key: source.label,
  render: () => {
    return <ReferralItem referralKey={source.label} label={`option.${source.label}`} Icon={source.icon} />
  },
}))

const ReferralScreen: React.FC<ReferralScreenProps> = ({ initialValues = {}, onSubmit, onBack }) => {
  const formikRef = useSaveOnBack({ onBack })
  const { t } = useTranslation()

  const handleSubmit = (values: Partial<Form>) => onSubmit(values)

  const submitButton = (
    <Field>
      {(props: FieldProps) => {
        return (
          <SubmitButton
            loading={false}
            name={t('next', { postProcess: [] })}
            type="submit"
            disabled={!props.form.isValid}
          >
            {t('next')}
          </SubmitButton>
        )
      }}
    </Field>
  )

  return (
    <PageLayout title={t('subcategory.how_did_you_hear_from_us')} subtitle={t('subcategory.referral')}>
      <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={handleSubmit}>
        <FormikForm>
          <FormLayout items={layout} submitButton={submitButton} spacingX={2} spacingY={2} />
        </FormikForm>
      </Formik>
    </PageLayout>
  )
}

export default ReferralScreen
