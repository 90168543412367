import { Components, Theme } from '@mui/material'

const MuiStepLabel: Components<Theme>['MuiStepLabel'] = {
  defaultProps: {},
  styleOverrides: {
    iconContainer: {
      padding: 0,
    },
  },
}

export default MuiStepLabel
