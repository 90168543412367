import { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'

import Title from '../Title'
import Group from '../Group'
import { labelsDirection, wishesToRatingTopic } from './Wishes.helpers'
import { PreviewFieldProps } from '../PreviewField/PreviewField'
import PreviewField from '../PreviewField'
import { combinedFields } from '@dentalux/ui-library-core'
import { wishesAndExpectations, filterRequired } from '@dentalux/ui-library-core'
import { Form, FieldSettings, WishAndExpectation } from '@dentalux/ui-library-core'

type Props = {
  values: Partial<Form>
  getEditableFieldProps: (settings: Omit<FieldSettings, '__type'>) => Omit<PreviewFieldProps, 'transform'>
}

const extendedFields = {
  ...combinedFields,
  referralField: {
    label: 'summary.how_did_you_hear_from_us',
    name: 'referral',
  },
}

const SummaryWishes = ({ values, getEditableFieldProps }: Props) => {
  const { t } = useTranslation()

  const isChecked = useCallback((wish: WishAndExpectation, values: Partial<Form>) => {
    return values?.wishesAndExpectations?.find(({ key }) => key === wish.key)?.flag || false
  }, [])

  const getRating = useCallback((wish: WishAndExpectation, values: Partial<Form>) => {
    return values?.wishesAndExpectations?.find(({ key }) => key === wish.key)?.score || 0
  }, [])

  const getWishValue = (wish: WishAndExpectation) => {
    if (wish.score !== undefined) {
      const score = getRating(wish, values)
      // The needs to have a boolean field is for setting it in Solutio
      // if user has not checked rating it will get converted to {checked: false} in the backend
      if (score === 0) return t('not_selected')

      const direction = wishesToRatingTopic[wish.key]
      const translation = t(`${labelsDirection[direction][score].dictionaryKey}`)
      return translation
    }
    return isChecked(wish, values) ? t('yes') : t('no')
  }

  const renderWish = (wish: WishAndExpectation) => {
    return (
      filterRequired(wish, values.wishesAndExpectations) && (
        <Grid key={wish.key} item xs={12} md={6}>
          <PreviewField label={t(`summary.${wish.key}`)} value={getWishValue(wish)} />
        </Grid>
      )
    )
  }

  return (
    <>
      <Title>{t('category.whishes_expectations')}</Title>

      <Group>
        {wishesAndExpectations.map(renderWish)}

        <Grid item xs={12} md={12}>
          <PreviewField
            {...getEditableFieldProps(extendedFields.referralField)}
            transform={(value) => t(`option.${value?.toString()}`, { defaultValue: value })}
          />
        </Grid>
      </Group>
    </>
  )
}

export default SummaryWishes
