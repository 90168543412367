import Revision from '../@types/Revision'

const getAuthor = (revision: Revision, isFirst = false): string | null => {
  if (typeof revision.createdBy === 'string') {
    return revision.createdBy
  }

  if (isFirst) {
    return 'Original'
  }

  return null
}

export default {
  getAuthor,
}
