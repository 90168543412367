import {
  AllergiesField,
  combinedFields as fields,
  diseasesByType,
  Translate,
  toBoolean,
} from '@dentalux/ui-library-core'
import DiseaseType from '../@types/DiseaseType'
import { differenceInYears, parse } from 'date-fns'
import { Grid } from '@mui/material'
import referralSources from 'config/referralSources'
import { CreateLayoutParams, createPreConditionsItems, createRenderDiseaseValue, createWishItem } from 'config/summary'
import ReferralItem from 'components/ReferralItem'
import MedicationsItem from 'components/Summary/components/MedicationsItem'
import { SummaryLayoutItem } from 'components/Summary/components/SummaryLayoutItem'
import DiffArrayValue from 'components/DiffArrayValue'

const getAge = (dateString: string): number | null => {
  try {
    const date = parse(dateString, 'dd.MM.yyyy', new Date())
    const age = differenceInYears(new Date(), date)

    return age
  } catch {
    return null
  }
}

const ageIsUnder19 = ({ birthday }: { birthday?: string }) => {
  if (!birthday) return false

  const age = getAge(birthday)

  if (!age) return false

  return age < 19
}

export const createLayout = (params: CreateLayoutParams): SummaryLayoutItem[] => {
  const { t, diseasesDiff, medicationsDiff } = params

  return [
    {
      type: 'custom',
      key: fields.birthday.name,
      label: fields.birthday.label,
      name: fields.birthday.name,
      visibleWhen: ageIsUnder19,
      renderValue: (value) => {
        if (typeof value !== 'string') return '-'

        const age = getAge(value)

        if (!age) return value

        return `${value} ${age} ${t('years_old')}`
      },
    },
    {
      type: 'field',
      field: fields.type,
      renderValue: (value) => {
        if (typeof value !== 'string') return ''

        return <Translate fallback={value}>{`option.${value.toLowerCase()}`}</Translate>
      },
    },
    { type: 'field', field: fields.occupation, visibleWhen: ({ occupation }) => Boolean(occupation) },
    {
      type: 'field',
      field: fields.isPregnant,
      renderValue: (_, form) => {
        const isPregnant = toBoolean(form.isPregnant)

        if (!isPregnant) return t('no')
        if (!form.pregnantMonths) return t('yes')

        return `${t('yes')}, ${form.pregnantMonths} ${t('months')}`
      },
    },
    ...createPreConditionsItems(params)
      .filter((item) => item.type !== 'title')
      .map((item): SummaryLayoutItem => {
        if (item.type !== 'custom') return item

        return {
          ...item,
          visibleWhen: ({ diseases }) => {
            if (!diseases) return false
            if (item.type !== 'custom') return true

            return diseases.some((disease) => disease.type === item.key)
          },
        }
      }),
    {
      type: 'custom',
      key: DiseaseType.MEDICATION_ALLERGIES,
      name: 'diseases',
      label: 'allergies_towards_medication',
      itemKey: DiseaseType.MEDICATION_ALLERGIES,
      visibleWhen: ({ diseases = [] }) => diseases.some((disease) => disease.type === DiseaseType.MEDICATION_ALLERGIES),
      renderValue: createRenderDiseaseValue({ targetDiseaseType: DiseaseType.MEDICATION_ALLERGIES, t, diseasesDiff }),
      renderField: (values) => {
        if (values.hasAllergies !== 'YES') return null

        const hasAllergySelected =
          values?.diseases?.some(
            (disease) =>
              disease.type === DiseaseType.MEDICATION_ALLERGIES || disease.type === DiseaseType.OTHER_ALLERGIES
          ) || false

        return (
          <AllergiesField
            label="allergies_towards_medication"
            diseaseType={DiseaseType.MEDICATION_ALLERGIES}
            options={diseasesByType.MEDICATION_ALLERGIES}
            error={!hasAllergySelected ? 'allergy_required' : undefined}
          />
        )
      },
    },
    {
      type: 'custom',
      key: DiseaseType.OTHER_ALLERGIES,
      name: 'diseases',
      label: 'other_allergies',
      itemKey: DiseaseType.OTHER_ALLERGIES,
      visibleWhen: ({ diseases = [] }) => diseases.some((disease) => disease.type === DiseaseType.OTHER_ALLERGIES),
      renderValue: createRenderDiseaseValue({ targetDiseaseType: DiseaseType.OTHER_ALLERGIES, t, diseasesDiff }),
      renderField: (values) => {
        const hasAllergySelected =
          values?.diseases?.some(
            (disease) =>
              disease.type === DiseaseType.MEDICATION_ALLERGIES || disease.type === DiseaseType.OTHER_ALLERGIES
          ) || false

        return (
          <AllergiesField
            label="other_allergies"
            diseaseType={DiseaseType.OTHER_ALLERGIES}
            options={diseasesByType.OTHER_ALLERGIES}
            error={!hasAllergySelected ? 'allergy_required' : undefined}
          />
        )
      },
    },
    {
      type: 'custom',
      key: 'medications',
      name: 'medications',
      label: 'field.do_you_regularly_take_any_other_medication',
      visibleWhen: ({ medications = [] }) => medications.length > 0,
      renderValue: (value) => {
        if (!Array.isArray(value) || !value.length) return t('no')

        const values = value.map((item) => ({ key: item, label: t(`option.${item}`, { defaultValue: item }) }))

        return <DiffArrayValue prefix="yes" values={values} diff={medicationsDiff || {}} />
      },
      renderField: () => {
        return <MedicationsItem />
      },
    },
    {
      type: 'custom',
      key: 'referral',
      label: 'summary.how_did_you_hear_from_us',
      name: 'referral',
      visibleWhen: ({ referral }) => Boolean(referral),
      renderValue: (value) => {
        if (typeof value !== 'string') return '-'

        return <Translate fallback={value} children={`option.${value}`} />
      },
      renderField: () => {
        return (
          <Grid container spacing={1}>
            {referralSources.map((source) => (
              <Grid item xs={6}>
                <ReferralItem referralKey={source.label} label={`option.${source.label}`} Icon={source.icon} />
              </Grid>
            ))}
          </Grid>
        )
      },
    },
    {
      ...createWishItem(
        {
          key: 'afraid_of_dentist_treatment',
          flag: false,
        },
        t
      ),
      visibleWhen: (values) =>
        values.wishesAndExpectations?.some((wish) => wish.key === 'afraid_of_dentist_treatment' && wish.flag) || false,
    } as SummaryLayoutItem,
  ]
}
