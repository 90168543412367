import { ReactNode } from 'react'
import * as S from './Callout.styles'

export type CalloutProps = {
  icon?: ReactNode
  children: ReactNode
  className?: string
}

const Callout: React.FC<CalloutProps> = ({ children, icon, className }) => {
  return (
    <S.Container className={className}>
      {icon && <S.IconWrapper>{icon}</S.IconWrapper>}

      <S.Text>{children}</S.Text>
    </S.Container>
  )
}

export default Callout
