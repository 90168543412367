import { Field, FieldProps, Form as FormikForm, Formik } from 'formik'
import PageLayout from 'components/PageLayout'
import SignatureModal from '../../components/SignatureModal'
import { useCallback, useMemo, useState } from 'react'
import { PageProps } from '../../hooks/usePage'
import { Document, Page, pdfjs } from 'react-pdf'
import { CircularProgress, Grid } from '@mui/material'
import range from 'lodash/range'
import * as S from './FactoringAgreement.styles'
import { useTranslation } from 'react-i18next'
import { FormLayout, Translate, getSignerName, SubmitButton } from '@dentalux/ui-library-core'
import { Loader } from '@dentalux/ui-library-core'
import { LayoutItem } from '@dentalux/ui-library-core'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

type DocumentLoadPros = {
  numPages: number
}

export type Props = PageProps & {
  factoringAgreementPdfLink?: string
  onSignatureSubmit: (signature: string) => void
  signatureLoading?: boolean
}

const FactoringAgreement: React.FC<Props> = ({
  initialValues = {},
  factoringAgreementPdfLink,
  signatureLoading,
  onSignatureSubmit,
}) => {
  const { t, i18n } = useTranslation()
  const [signature, setSignature] = useState<string | undefined>()
  const [showSignatureModal, setShowSignatureModal] = useState<boolean>(false)
  const [isSignatureSubmitted, setIsSignatureSubmitted] = useState<boolean>(false)
  const [pdfStatus, setPdfStatus] = useState<'loading' | 'error' | 'success'>('success')

  const [numPages, setNumPages] = useState<number>(0)

  const pdfLoading = pdfStatus === 'loading'

  const handleSubmit = useCallback(() => {
    if (!isSignatureSubmitted) {
      setShowSignatureModal(true)
    }
  }, [isSignatureSubmitted, setShowSignatureModal])

  const handleSignatureSubmit = useCallback(
    (signature: string) => {
      setSignature(signature)
      setIsSignatureSubmitted(true)

      onSignatureSubmit(signature)
    },
    [setSignature, onSignatureSubmit, setIsSignatureSubmitted]
  )

  const handleSignatureChange = useCallback(
    (signature: string) => {
      setSignature(signature)

      if (!signature) {
        // Reset it when the user clicks to "Clear" the signature pad.
        setIsSignatureSubmitted(false)
      }
    },
    [setSignature, setIsSignatureSubmitted]
  )

  const handleCloseSignatureModal = useCallback(() => {
    setSignature('')
    setShowSignatureModal(false)
  }, [setShowSignatureModal])

  const button = useMemo(
    () => (
      <Field>
        {(props: FieldProps) => {
          return (
            <SubmitButton loading={false} type="submit" onClick={handleSubmit} disabled={!props.form.isValid}>
              <Translate children="sign" />
            </SubmitButton>
          )
        }}
      </Field>
    ),
    [handleSubmit]
  )

  const handlePdfLoadSuccess = ({ numPages }: DocumentLoadPros) => {
    setShowSignatureModal(false)
    setPdfStatus('success')
    setNumPages(numPages)
  }

  const handlePdfLoadError = (_error: Error) => {
    setPdfStatus('error')
  }

  const layout: LayoutItem[] = [
    {
      type: 'custom',
      key: 'factoringAgreementDescription',
      xs: 12,
      render: () => <S.TextContainer>{t('screen.factoring_agreement.description_v2')}</S.TextContainer>,
    },
    {
      type: 'custom',
      key: 'factoringAgreementPDF',
      xs: 12,
      render: () => (
        <Grid item xs={12}>
          <S.DocumentPaper elevation={0}>
            {(signatureLoading || pdfLoading) && <CircularProgress size={20} />}

            {!signatureLoading && factoringAgreementPdfLink && pdfStatus !== 'error' && (
              <Document
                file={factoringAgreementPdfLink}
                loading={<Loader />}
                onLoadSuccess={handlePdfLoadSuccess}
                onLoadError={handlePdfLoadError}
                onSourceError={handlePdfLoadError}
              >
                {range(1, numPages + 1).map((page) => (
                  <Page key={page} pageNumber={page} />
                ))}
              </Document>
            )}

            {!signatureLoading && factoringAgreementPdfLink && pdfStatus === 'error' && (
              <S.PdfIframe
                src={factoringAgreementPdfLink}
                frameBorder="0"
                width="100%"
                height="auto"
                language={i18n.language}
              />
            )}
          </S.DocumentPaper>
        </Grid>
      ),
    },
  ]

  return (
    <PageLayout title={t('screen.factoring_agreement.title')} subtitle={t('subcategory.convenient_payment')}>
      <SignatureModal
        isOpen={showSignatureModal}
        name={getSignerName(initialValues)}
        signature={signature}
        onSignatureChange={handleSignatureChange}
        onClose={handleCloseSignatureModal}
        onSubmit={handleSignatureSubmit}
        isSubmitting={signatureLoading || pdfLoading}
      />
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <FormikForm>
          <FormLayout items={layout} submitButton={button} />
        </FormikForm>
      </Formik>
    </PageLayout>
  )
}

export default FactoringAgreement
