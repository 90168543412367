import { useState, useContext, createContext, Dispatch, SetStateAction } from 'react'

import qs from 'query-string'

import { getCurrentLanguage } from '../services/i18n'
import { wishesAndExpectations } from '@dentalux/ui-library-core'
import { PostAnamnesisResponse } from 'services/api/ana'

import { Form } from '@dentalux/ui-library-core'

export type Answers = Partial<
  Form & PostAnamnesisResponse['healthAssistantSuccessActionInfoResult'] & { shouldSkipReferral: boolean }
>

type SetAnswersContextType = Dispatch<SetStateAction<Answers>>

export const AnswersContext = createContext<Answers>({})
export const SetAnswersContext = createContext<SetAnswersContextType>(() => {})

type Props = {
  // The context initial values, should be used only to simplify the tests purposes
  initialValues?: Answers
}

const { code: codeFromParams } = qs.parse(window.location.search)
const parsedCode = typeof codeFromParams === 'string' ? codeFromParams : undefined

export const defaultAnswers: Answers = {
  code: parsedCode,
  pdfReference: 'placeholder', // DEPRECATED
  isSmoker: false,
  hasFamilyInsurance: false,
  livesOnDifferentAddress: false,
  diseases: [],
  medications: [],
  isPregnant: undefined,
  allergyPass: false,
  consumesAlcohol: false,
  consumesDrugs: false,
  wishesAndExpectations: wishesAndExpectations,
  hasCivilServiceAllowance: false,
  hasDentalInsurance: false,
  language: getCurrentLanguage().toUpperCase(),
  weightOver120Kg: false,
  heightOver2M: false,
}

const AnswersProvider: React.FC<Props> = ({ initialValues = defaultAnswers, children }) => {
  const [answers, setAnswers] = useState<Answers>(initialValues)

  return (
    <AnswersContext.Provider value={answers}>
      <SetAnswersContext.Provider value={setAnswers}>{children}</SetAnswersContext.Provider>
    </AnswersContext.Provider>
  )
}

export const useAnswers = (): Answers => {
  const context = useContext(AnswersContext)

  if (context === undefined) {
    throw new Error('useAnswers must be used within AnswersProvider')
  }

  return context
}

export const useSetAnswers = (): SetAnswersContextType => {
  const context = useContext(SetAnswersContext)

  if (!context === undefined) {
    throw new Error('useSetAnswers must be used within AnswersProvider')
  }

  return context
}

export default AnswersProvider
