import { useState, useEffect, useContext, createContext } from 'react'
import { useLocation } from 'react-router-dom'
import { paths, routes } from '../config/routes'
import { useAnswers } from './AnswersProvider'
import { usePatient } from './PatientProvider'

const VisitedStepsContext = createContext<string[]>([])
const SetVisitedStepsContext = createContext<React.Dispatch<React.SetStateAction<string[]>>>(() => {})

type Props = {
  // The context initial values, should be used only to simplify the tests purposes
  initialValues?: string[]
}

const VisitedStepsProvider: React.FC<Props> = ({ initialValues, children }) => {
  const answers = useAnswers()
  const patient = usePatient()
  const location = useLocation()

  const [visitedSteps, setVisitedSteps] = useState<string[]>(() => {
    if (initialValues) return initialValues
    if (location.pathname === routes.intro.path) return [location.pathname]

    return []
  })

  useEffect(() => {
    setVisitedSteps((previous) => {
      const guard = Object.values(routes).find(
        (route) => route.path === location.pathname && route.previous !== location.pathname
      )?.guard

      const newVisitedSteps = (() => {
        if (
          !previous.includes(location.pathname) &&
          location.pathname === paths.wishes4 &&
          answers.shouldSkipReferral
        ) {
          return [...previous, paths.referral, location.pathname]
        }

        return [...previous, location.pathname]
      })()

      const guardResult = guard?.(answers, patient, newVisitedSteps)
      const isAllowedToVisitNextStep = Boolean(guardResult?.isValid)

      return isAllowedToVisitNextStep ? newVisitedSteps : previous
    })
  }, [location.pathname, setVisitedSteps, answers, patient])

  return (
    <VisitedStepsContext.Provider value={visitedSteps}>
      <SetVisitedStepsContext.Provider value={setVisitedSteps}>{children}</SetVisitedStepsContext.Provider>
    </VisitedStepsContext.Provider>
  )
}

export const useVisitedSteps = (): string[] => {
  const context = useContext(VisitedStepsContext)

  if (context === undefined) {
    throw new Error('useVisitedSteps must be used within VisitedStepsProvider')
  }

  return context
}

export const useSetVisitedSteps = (): React.Dispatch<React.SetStateAction<string[]>> => {
  const context = useContext(SetVisitedStepsContext)

  if (context === undefined) {
    throw new Error('useSetVisitedSteps must be used within VisitedStepsProvider')
  }

  return context
}

export default VisitedStepsProvider
