import { CSSObject, styled } from '@mui/material/styles'
import MuiTypography from '@mui/material/Typography'
import MuiList from '@mui/material/List'
import MuiListItemIcon from '@mui/material/ListItemIcon'
import MuiListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import MuiDrawer from '@mui/material/Drawer'
import MuiIconButton from '@mui/material/IconButton'
import MuiCheckIcon from '@mui/icons-material/Check'

export const List = styled(MuiList)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
}))

export const ListItemSecondaryAction = styled(MuiListItemSecondaryAction)(({ theme }) => ({
  color: '#219653',
}))

export const ListItemProgressContainer = styled('div')<{ completed?: boolean }>(({ completed, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: completed ? 'row-reverse' : 'row',
  color: completed ? '#219653' : theme.palette.primary.main,
  fontSize: '1rem',

  '.MuiSvgIcon-root': {
    marginLeft: completed ? theme.spacing(1) : undefined,
  },
}))

export const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
  minWidth: 'unset',
  width: theme.spacing(3),
  height: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 0,
  marginRight: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
  },
}))

export const ListItemTextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  ...(theme.typography['Sarabun Body 1'] as CSSObject),
}))

export const ListItemCompleted = styled(MuiTypography)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  color: '#98a1aa',

  '&.MuiTypography-root': {
    fontSize: 11,
  },
}))

export const ListItemProgress = styled(MuiTypography)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
}))

export const Container = styled(MuiDrawer)(({ theme }) => ({
  '.MuiBackdrop-root': {
    visibility: 'hidden',
  },
  '.MuiPaper-root': {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100vw',
    maxWidth: 'unset',
    minHeight: '100vh',
    // minHeight: '-webkit-fill-available',
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.up('sm')]: {
      minWidth: '305px',
      width: '305px',
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.common.white,
    },
  },
}))

export const TopContainer = styled('div')(
  ({ theme }) => ({
    '& .MuiList-root + .MuiList-root': {
      marginTop: theme.spacing(2),
    },
  }),
  {
    name: 'TopContainer',
  }
)

export const BottomContainer = styled('div')(
  {},
  {
    name: 'BottomContainer',
  }
)

export const CloseContainer = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: theme.spacing(6),
    backgroundColor: theme.palette.common.white,

    [theme.breakpoints.up('md')]: {
      height: 70,

      '& .MuiIconButton-root': {
        display: 'none',
      },
    },
  }),
  {
    name: 'CloseContainer',
  }
)

export const CloseButton = styled(MuiIconButton)(() => ({
  '.MuiSvgIcon-root': {
    fontSize: '24px',
  },
}))

export const StepIconWrapper = styled('div')(({ theme }) => ({
  marginTop: '6px',

  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
  },
}))

export const CheckIcon = styled(MuiCheckIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}))
