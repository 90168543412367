import * as Sentry from '@sentry/react'

// Each of these values must be available in `window.console`
type LogType = 'info' | 'log' | 'error' | 'warn' | 'debug'

type LoggerFn = (message: string, data?: Record<string, unknown>) => void

const logTypes: LogType[] = ['info', 'log', 'error', 'warn', 'debug']

const logSeverity: Record<LogType, Sentry.SeverityLevel> = {
  log: 'log',
  info: 'info',
  debug: 'debug',
  warn: 'warning',
  error: 'error',
}

const isSentryAvailable = process.env.REACT_APP_SENTRY_DSN

const createLoggerFn = (type: LogType): LoggerFn => {
  return (message: string, data?: Record<string, unknown>) => {
    if (!isSentryAvailable) return console[type]?.(message, data)

    Sentry.addBreadcrumb({
      message,
      data,
      level: logSeverity[type],
      type: `console.${type}`,
      timestamp: Date.now(),
    })
  }
}

const Logger = Object.fromEntries(logTypes.map((type) => [type, createLoggerFn(type)])) as Record<LogType, LoggerFn>

export default Logger
