import { Paper } from '@mui/material'
import { styled } from '@mui/material/styles'
import Callout from '../../components/Callout'

export const TextContainer = styled(Callout)(() => ({
  padding: '0px 16px',

  '& p': {
    fontSize: '13px',
    lineHeight: '17px',
  },
}))

export const DocumentPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  padding: theme.spacing(1.25, 2),
  overflow: 'hidden',

  '& .MuiCircularProgress-root': {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
  },

  '& .react-pdf__Page': {
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(7),
    },
  },

  '& canvas': {
    marginRight: 0,
    /* we need to add !important here to be able to override the inline-styles set
     * by react-pdf */
    width: '100% !important',
    height: 'auto !important',
  },
}))

export const PdfIframe = styled('iframe')<{ language?: string }>(({ language, theme }) => ({
  width: '100%',
  height: 'auto',
  minHeight: '214px',
  [theme.breakpoints.up('sm')]: {
    minHeight: '400px',
    ...(language === 'en' && {
      minHeight: '841px',
    }),
  },
  ...(language === 'en' && {
    minHeight: '500px',
  }),
}))
