import { useEffect, useRef } from 'react'
import { PageProps } from './usePage'

type UseSaveOnBackParams = {
  onBack: PageProps['onBack']
  // If true, the hook will not need the values from formikValue to execute
  skipRef?: boolean
}

const useSaveOnBack = ({ onBack, skipRef }: UseSaveOnBackParams) => {
  const formikRef = useRef(null)

  useEffect(() => {
    if (!onBack) return

    const $backBtn = document.getElementById('back-btn')

    if (!$backBtn) return

    const handleBackClick: EventListener = (e) => {
      // @ts-ignore
      const values = formikRef.current?.values

      if (values || skipRef) {
        e.stopPropagation()
        onBack(values)
      }
    }

    $backBtn.addEventListener('click', handleBackClick)

    return () => $backBtn.removeEventListener('click', handleBackClick)
  }, [onBack, skipRef])

  return formikRef
}

export default useSaveOnBack
