import DiseaseType from '../@types/DiseaseType'

const translationKeyByDiseaseType: Record<DiseaseType, string> = {
  [DiseaseType.INFECTIOUS]: 'do_you_have_infectious_disease',
  [DiseaseType.HEART]: 'do_you_have_heart_disease',
  [DiseaseType.CIRCULATORY_VASCULAR]: 'do_you_have_a_circulatory_or_vascular_disease',
  [DiseaseType.LUNG]: 'do_you_have_a_lung_disease',
  [DiseaseType.BONE_MUSCULAR_COGNITIVE_TISSUE]: 'do_you_have_a_bone_disease',
  [DiseaseType.CANCER]: 'do_you_have_cancer',
  [DiseaseType.METABOLIC]: 'do_you_have_metabolic_disease',
  [DiseaseType.GASTRIC_INTESTINAL]: 'do_you_have_a_gastric_disease',
  [DiseaseType.LIVER_BILIARY]: 'do_you_have_liver_disease',
  [DiseaseType.KIDNEY_URINARY_TRACT]: 'do_you_have_a_kidney_disease',
  [DiseaseType.PSYCHOLOGICAL]: 'do_you_have_psychological_disorder',
  [DiseaseType.MEDICATION_ALLERGIES]: 'do_you_have_any_allergies_towards_medication',
  [DiseaseType.SURGERY]: 'did_you_ever_have_surgery',
  [DiseaseType.OTHER_ALLERGIES]: 'do_you_have_any_other_allergies',
  [DiseaseType.OTHER]: 'do_you_have_any_other_disease',
}

const getQuestionTranslationKey = (diseaseType: DiseaseType): string => {
  const translationKey = translationKeyByDiseaseType[diseaseType]

  return `field.${translationKey}`
}

const getQuestionSubtitle = (diseaseType: DiseaseType): string => {
  const translationKey = translationKeyByDiseaseType[diseaseType]

  return `field.${translationKey}_subtitle`
}

export default {
  getQuestionTranslationKey,
  getQuestionSubtitle,
}
