import { Theme, useMediaQuery } from '@mui/material'

import { useLayoutEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Navbar from '../Navbar'
import SideNav from '../SideNav'
import * as S from './AppShell.styles'

export type AppShellProps = {
  className?: string
}

const AppShell: React.FC<AppShellProps> = ({ className, children }) => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false)
  const { pathname } = useLocation()

  const handleToggleSideNav = () => setIsSideNavOpen((previous) => !previous)
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [pathname])

  return (
    <S.Root className={className}>
      <SideNav currentStep={pathname} open={isDesktop || isSideNavOpen} onClose={handleToggleSideNav} />

      <S.Container>
        <Navbar pathname={pathname} onMenuOpen={handleToggleSideNav} />

        <S.Content>{children}</S.Content>
      </S.Container>
    </S.Root>
  )
}

export default AppShell
