import { gql } from '@apollo/client'

import usePage from '../hooks/usePage'
import { useInsuranceCompanies } from 'hooks/useInsuranceCompanies'
import PageLayout from 'components/PageLayout'
import { InsuranceForm } from '@dentalux/ui-library-core'
import { useTranslation } from 'react-i18next'
import useSaveOnBack from 'hooks/useSaveOnBack'

export const INSURANCE_COMPANIES = gql`
  query INSURANCE_COMPANIES {
    insuranceCompanies {
      referenceId
      insurerName
      plans {
        referenceId
        name
        type
      }
    }
  }
`

const InsurancePage: React.FC = () => {
  const { onBack, onSubmit, initialValues = {} } = usePage()
  const { data: insuranceCompanies = [] } = useInsuranceCompanies()

  const { t } = useTranslation()
  const formikRef = useSaveOnBack({ onBack })

  return (
    <PageLayout title={t('category.general_information')} subtitle={t('subcategory.insurance_details')}>
      <InsuranceForm
        insuranceCompanies={insuranceCompanies}
        onSubmit={onSubmit}
        innerRef={formikRef}
        initialValues={initialValues}
        loading={false}
      />
    </PageLayout>
  )
}

export default InsurancePage
