import ServerError from '../../../../@types/ServerError'
import { DialogContent, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useStyles, { StyledDialog } from './ServerErrorOverlay.styles'
import { combinedFields } from '@dentalux/ui-library-core'
import { ReactComponent as ErrorIcon } from 'assets/icons/error-icon.svg'

type Props = {
  error: ServerError
  open?: boolean
  onClose: () => void
}

const ServerErrorOverlay: React.FC<Props> = ({ error, open = false, onClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const translateField = (field: string) => {
    const translationFromConfig = Object.values(combinedFields).find(({ name }) => name === field)

    if (translationFromConfig) return t(translationFromConfig.label.replace('field.', 'summary.'))

    return field
  }

  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogContent className={classes.content}>
        <div>
          <span className={classes.iconWrapper}>
            <ErrorIcon />
          </span>
        </div>

        <div>
          <Typography className={classes.title}>
            {t(`backend.error.title_${error.status}`, {
              defaultValue: t('backend.error.generic_title', { postProcess: [] }),
            })}
          </Typography>

          <div>
            <Typography className={classes.subtitle}>
              {t(`backend.error.subtitle_${error.status}`, {
                defaultValue: t('backend.error.generic_subtitle', { postProcess: [] }),
              })}
            </Typography>

            {(error.status === 422 || error.status === 400) && (
              <ul className={classes.list}>
                {error.fields.map((field) => (
                  <li key={field.field}>
                    {translateField(field.field)} (<span className={classes.errorDescription}>{field.message}</span>)
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </DialogContent>

      <div className={classes.actions}>
        <Button onClick={onClose} className={classes.closeBtn}>
          {t('close_error_modal')}
        </Button>
      </div>

      <div className={classes.errorDetails}>
        {t('error')}:{' '}
        <span className={classes.errorCode}>
          {error.status}. <strong>{error.message}</strong>
        </span>
      </div>
    </StyledDialog>
  )
}

export default ServerErrorOverlay
