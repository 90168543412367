import * as api from 'services/api/ana'
import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query'
import ServerError from '../@types/ServerError'
import { AxiosError } from 'axios'
import { Optins } from '@dentalux/ui-library-core'

type ErrorType = AxiosError<ServerError>

type UseVersionReturn = [Optins | undefined, Omit<QueryObserverResult<Optins, ErrorType>, 'data'>]

export const useOptins = (
  {
    anamnesisReferenceId,
  }: {
    anamnesisReferenceId: string
  },
  config: Omit<UseQueryOptions<Optins, ErrorType>, 'queryFn' | 'queryKey'> = {}
): UseVersionReturn => {
  const { data, ...rest } = useQuery<Optins, ErrorType>(
    ['anamnesis-optins', anamnesisReferenceId],
    () => api.getOptins(anamnesisReferenceId),
    {
      enabled: Boolean(anamnesisReferenceId),
      ...config,
    }
  )

  return [data, rest]
}
