import { Components, CSSObject, Theme } from '@mui/material'

const MuiFormHelperText: Components<Theme>['MuiFormHelperText'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...(theme.typography['Sarabun Caption'] as CSSObject),
      color: theme.palette.custom.grey[800],
      margin: theme.spacing(0.5, 2, 0, 2),

      '&.Mui-error': {
        color: theme.palette.custom.error[500],
      },

      a: {
        ...(theme.typography['Sarabun Caption'] as CSSObject),
      },
    }),
  },
}

export default MuiFormHelperText
