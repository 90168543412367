import { RouteChildrenProps } from 'react-router-dom'

import usePage from '../hooks/usePage'
import { MiscellaneousForm } from '@dentalux/ui-library-core'
import useSaveOnBack from 'hooks/useSaveOnBack'
import PageLayout from 'components/PageLayout'
import { useTranslation } from 'react-i18next'

const OtherPersonalInformationPage: React.FC<RouteChildrenProps> = () => {
  const { onBack, onSubmit, initialValues = {} } = usePage()

  const formikRef = useSaveOnBack({ onBack })

  const { t } = useTranslation()

  return (
    <PageLayout title={t('category.general_information')} subtitle={t('subcategory.miscellaneous')}>
      <MiscellaneousForm loading={false} innerRef={formikRef} onSubmit={onSubmit} initialValues={initialValues} />
    </PageLayout>
  )
}

export default OtherPersonalInformationPage
