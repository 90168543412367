import * as contentful from 'contentful'
import _set from 'lodash/set'

const shouldUsePreview = ['development', 'preview'].includes(process.env.REACT_APP_ENV || '')

type LegalDocument = {
  clinicReferenceId: string
  name: string
  topic: string
  doc: contentful.Asset
}

type Application = {
  name: string
  slug: string
}

type Translation = {
  application: contentful.Entry<Application>
  key: string
  translation: string
}

type TranslationsJSON = Record<string, string | Record<string, unknown>>

const client = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID || '',
  accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY || '',
  host: shouldUsePreview ? 'preview.contentful.com' : undefined,
})

const toTranslationObject = (result: contentful.EntryCollection<Translation>): TranslationsJSON => {
  return result.items.reduce<TranslationsJSON>((acc, item) => {
    return _set({ ...acc }, item.fields.key, item.fields.translation)
  }, {})
}

type ToLegalDocObjectReturn = Record<string, Record<string, contentful.Asset>>

const toLegalDocObject = (result: contentful.EntryCollection<LegalDocument>): ToLegalDocObjectReturn => {
  return result.items.reduce<ToLegalDocObjectReturn>((acc, item) => {
    return _set({ ...acc }, item.fields.topic, {
      ...acc[item.fields.topic],
      [item.fields.clinicReferenceId || 'all']: result.includes.Asset.find(
        (asset: contentful.Asset) => asset?.sys?.id === item?.fields?.doc?.sys?.id
      ),
    })
  }, {})
}

export type GetTranslationsReturn = {
  en: TranslationsJSON
  de: TranslationsJSON
}

export const getTranslations = async (applicationSlug: string): Promise<GetTranslationsReturn> => {
  const defaultQuery = {
    content_type: 'translation',
    'sys.contentType.sys.id': 'translation',
    'fields.application.fields.slug': applicationSlug,
    'fields.application.sys.contentType.sys.id': 'application',
    limit: 1000,
  }

  const [de, en] = await Promise.all([
    client.getEntries<Translation>({ ...defaultQuery, locale: 'de-DE' }).then(toTranslationObject),
    client.getEntries<Translation>({ ...defaultQuery, locale: 'en-US' }).then(toTranslationObject),
  ])

  return { de, en }
}

export type GetLegalDocumentsReturn = {
  en: Record<string, Record<string, contentful.Asset>>
  de: Record<string, Record<string, contentful.Asset>>
}

export const getLegalDocuments = async (): Promise<GetLegalDocumentsReturn> => {
  const defaultQuery = { 'sys.contentType.sys.id': 'legalDoc', limit: 1000 }

  const [de, en] = await Promise.all([
    client.getEntries<LegalDocument>({ ...defaultQuery, locale: 'de-DE' }).then(toLegalDocObject),
    client.getEntries<LegalDocument>({ ...defaultQuery, locale: 'en-US' }).then(toLegalDocObject),
  ])

  return { de, en }
}
