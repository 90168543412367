import { styled } from '@mui/material/styles'
import { Grid, Card } from '@mui/material'

import { printableMediaQuery } from '../../../../helpers/mediaQuery'

export const Root = styled(Card)(({ theme }) => ({
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  margin: theme.spacing(2.5),

  [printableMediaQuery]: {
    margin: 0,
    paddingBottom: 20,
    backgroundColor: 'transparent',
    boxShadow: 'unset',
  },

  '& .MuiCardContent-root': {
    padding: 0,
  },

  [theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

export const GridContainer = styled(Grid)(() => ({
  [printableMediaQuery]: {
    display: 'block',
  },
}))
