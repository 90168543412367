import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import MenuIcon from '@mui/icons-material/Menu'

import { routes } from 'config/routes'
import D21Logo from '../icons/D21Logo'

import * as S from './Navbar.styles'

export type NavbarProps = {
  onBack: () => void
  onMenuOpen: () => void
  pathname?: string
}

const pathsToHideBackButton = {
  [routes.success.path]: true,
}

const Navbar: React.FC<NavbarProps> = ({ onBack, onMenuOpen, pathname }) => {
  return (
    <S.Root>
      <S.Container>
        <S.MenuItemsContainer>
          <S.MenuItem>
            {!pathsToHideBackButton[pathname || ''] && (
              <S.Button id="back-btn" aria-label="Back" name="Back" onClick={onBack} disableRipple>
                <ArrowBackIcon />
              </S.Button>
            )}
          </S.MenuItem>

          <S.MenuItem>
            <D21Logo />
          </S.MenuItem>

          <S.MenuItem>
            <S.Button aria-label="Menu" name="Menu" onClick={onMenuOpen} disableRipple>
              <MenuIcon />
            </S.Button>
          </S.MenuItem>
        </S.MenuItemsContainer>

        <S.Stepper />
      </S.Container>
    </S.Root>
  )
}

export default Navbar
