import { useEffect, useState } from 'react'
import qs from 'query-string'

import PrintableSummaryScreen from '../screens/PrintableSummary'
import { useLocation } from 'react-router-dom'
import { useInsuranceCompanies } from 'hooks/useInsuranceCompanies'
import { InsuranceCompany, Clinic, Form, Optins } from '@dentalux/ui-library-core'

/*
 * The backend service uses Puppeteer to generate the PDF
 * from this page. Before visiting this page, Puppeteer
 * saves the anamnesis in the localstorage with a id
 * as key, then we get this data from local storage using
 * this id provided as query param then we use the data
 * from localStorage to render the anamnesis summary.
 */
const getDataFromLocalStorage = (id: string) => {
  const data = window.localStorage.getItem(id)

  if (data) {
    try {
      const parsedValues = JSON.parse(data)

      return parsedValues
    } catch (e) {
      return {}
    }
  }

  return {}
}

const PrintableSummaryPage: React.FC = () => {
  const location = useLocation()
  const { data: insuranceCompanies = [], isLoading } = useInsuranceCompanies()

  /*
   * This is the id which Puppeteer used as key when saving
   * the anamnesis in the localStorage.
   */
  const id = qs.parse(location.search).id as string

  // Then we load this anamnesis from the localStorage to able to render it
  const [values, setValues] = useState<{ anamnesis: Partial<Form>; clinic?: Clinic; currentOptIns?: Optins }>(
    getDataFromLocalStorage(id)
  )

  useEffect(() => {
    /*
     * We also need to listen to storage changes. It's useful
     * in case the localStorage is updated after the page is
     * loaded, then we can detect the changes and update the
     * state (values).
     */
    window.addEventListener('storage', () => {
      const anamnesis = getDataFromLocalStorage(id)

      setValues(anamnesis)
    })
  }, [setValues, id])

  const isCompany = (referenceId: string) => (company: Pick<InsuranceCompany, 'referenceId'>) =>
    company.referenceId === referenceId

  const getInsurerName = (referenceId: string): string | undefined =>
    insuranceCompanies.find(isCompany(referenceId))?.insurerName

  // Here we enrich the anamnesis data using the insurance companies name
  const anamnesisWithInsuranceCompanyName: Partial<Form> = {
    ...values.anamnesis,

    insuranceCompany: values.anamnesis?.insuranceCompany ?? getInsurerName(values.anamnesis?.insuranceCompanyId || ''),

    dentalInsuranceCompany:
      values.anamnesis?.dentalInsuranceCompany ?? getInsurerName(values.anamnesis?.dentalInsuranceCompanyId || ''),
  }

  // We keep the summary hidden until we receive the insuranceCompanies,
  // this way we make sure that the PrintableSummaryScreen will be rendered
  // with the correct initial values
  if (isLoading) return null

  // As the PrintableSummaryScreen doesn't uses the `values` to render the
  // data, but instead, creates an internal copy, we need to unmount the component
  // when the values changes, that's why we set the key to be the values, it makes
  // possible to unmount and mount a new component using the fresh `values`.
  return (
    <PrintableSummaryScreen
      key={JSON.stringify(values)}
      values={anamnesisWithInsuranceCompanyName}
      clinic={values.clinic}
      optins={values.currentOptIns}
    />
  )
}

export default PrintableSummaryPage
