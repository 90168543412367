import usePage from '../../hooks/usePage'
import { usePatient } from '../../providers/PatientProvider'

const useNavbar = () => {
  const { onBack } = usePage()
  const patient = usePatient()

  return {
    onBack,
    clinicName: patient?.data?.clinic?.officialName || '',
    clinicLogoUrl: patient?.data?.clinic?.clinicLogoUrl,
  }
}

export default useNavbar
