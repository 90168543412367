import { FormHelperText, IconButton as MuiIconButton, Typography, typographyClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Label = styled(Typography)({})

Label.defaultProps = { variant: 'Sarabun Caption' }

export const Value = styled(Typography)({})

export const ValueContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.custom.grey[800],
}))

export const ActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const ActionButton = styled(MuiIconButton)(({ theme }) => ({
  marginTop: -6,
  marginBottom: -6,
  color: theme.palette.primary.main,

  '& .MuiSvgIcon-root': {
    fontSize: theme.spacing(3),
  },

  '&:last-of-type': {
    marginRight: theme.spacing(-2),
  },
}))

export const MainContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

export const EditContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1),
}))

export const EditContainerActions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(5),

  '& > :first-of-type': {
    marginRight: theme.spacing(1),
  },
}))

export const Container = styled('div')<{ isExpanded?: boolean; error?: boolean; changed?: boolean }>(
  ({ theme, isExpanded, error, changed }) => ({
    padding: theme.spacing(1, 2),
    backgroundColor: isExpanded ? theme.palette.custom.grey[200] : 'transparent',

    ...(changed || error
      ? {
          [`.${typographyClasses.root}`]: {
            color: changed ? '#219653' : theme.palette.custom.error[500],
          },
        }
      : {}),
  })
)

export const Content = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',

  '& > *': {
    flex: 1,
  },
})

export const Comment = styled(Typography)<{ changed?: boolean }>(({ theme, changed }) => ({
  fontStyle: 'italic',
  fontSize: '13px',
  lineHeight: '17px',
  color: changed ? '#219653' : undefined,
}))

export const ErrorMessage = styled(FormHelperText)({
  marginLeft: 0,
  marginTop: 0,
})

ErrorMessage.defaultProps = {
  error: true,
}
