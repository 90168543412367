import { CSSObject, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled('section')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  margin: theme.spacing(0, 'auto'),
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    maxWidth: 'var(--container-w-sm-up)',
  },
}))

const HeadlineBase = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.grey[900],

  [theme.breakpoints.up('sm')]: {
    color: theme.palette.custom.grey[800],
  },
}))

export const Title = styled(HeadlineBase)(({ theme }) => ({
  margin: theme.spacing(3, 0, 0, 0),
  ...(theme.typography['Sarabun H5'] as CSSObject),

  [theme.breakpoints.up('sm')]: {
    ...(theme.typography['Sarabun H4'] as CSSObject),
  },
}))

export const Subtitle = styled(HeadlineBase)(({ theme }) => ({
  marginTop: theme.spacing(1),

  ...(theme.typography['Sarabun Body 1'] as CSSObject),

  [theme.breakpoints.up('sm')]: {
    ...(theme.typography['Sarabun Subtitle 1'] as CSSObject),
  },
}))

export const Header = styled('header')(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
}))

export const Body = styled('main')(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(5),

  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}))
