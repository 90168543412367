import { ReactElement } from 'react'
import * as Yup from 'yup'

import { personalInformationSchema, addressSchema, insuranceSchema, lifestyleSchema } from '@dentalux/ui-library-core'
import { routes } from './routes'

import { ReactComponent as GeneralInformationIcon } from '../assets/icons/general-information-icon.svg'
import { ReactComponent as AnamnesisIcon } from '../assets/icons/anamnesis-icon.svg'
import { ReactComponent as WishesIcon } from '../assets/icons/wishes-icon.svg'
import { ReactComponent as FinishIcon } from '../assets/icons/finish-icon.svg'

import mergeSchemas from '../helpers/mergeValidationSchemas'
import Patient from '../@types/Patient'
import { canSkipAnamnesis, canSkipFactoring } from 'helpers/steps'

export type MenuItem = {
  path: string
  label: string
  icon: ReactElement
  steps: string[]
  getSteps?: (patient: Patient | undefined) => string[]
  validation?: Yup.ObjectSchema
}

const ADDITIONAL_DETAIL_MANDATORY_STEPS = [
  routes.wishes.path,
  routes.wishes2.path,
  routes.wishes4.path,
  routes.referral.path,
]

const menuItems: MenuItem[] = [
  {
    path: routes.personalInformation.path,
    label: 'category.general_information',
    icon: <GeneralInformationIcon />,
    steps: [routes.personalInformation.path, routes.address.path, routes.insurance.path, routes.extraInformation.path],
    validation: mergeSchemas(personalInformationSchema, addressSchema, insuranceSchema, lifestyleSchema),
  },
  {
    path: routes.preConditions1.path,
    label: 'category.anamnesis',
    icon: <AnamnesisIcon />,
    steps: [routes.preConditions1.path, routes.preConditions2.path, routes.preConditions3.path, routes.allergies.path],
  },
  {
    path: routes.wishes.path,
    label: 'category.whishes_expectations', // need to make new translation in Contentful before change
    icon: <WishesIcon />,
    steps: [...ADDITIONAL_DETAIL_MANDATORY_STEPS],
  },
  {
    path: routes.summary.path,
    label: 'category.finalize',
    icon: <FinishIcon />,
    steps: [routes.consents.path, routes.summary.path, routes.factoringAgreement.path, routes.success.path],
    getSteps: (patient) => {
      const stepsCompleted = patient?.anamnesisState || []

      const result = [
        !canSkipAnamnesis(stepsCompleted) && routes.consents.path,
        !canSkipAnamnesis(stepsCompleted) && routes.summary.path,
        !canSkipFactoring(stepsCompleted) && routes.factoringAgreement.path,
        routes.success.path,
      ].filter((entry) => typeof entry === 'string') as string[]

      return result
    },
  },
]

export default menuItems
