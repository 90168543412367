import { Components, CSSObject, Theme } from '@mui/material'

const MuiFormLabel: Components<Theme>['MuiFormLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.custom.grey[800],
      ...(theme.typography['Sarabun Body 1'] as CSSObject),

      '&.Mui-error': {
        color: theme.palette.custom.error[500],
      },

      '&.MuiInputLabel-shrink': {
        ...(theme.typography['Sarabun Caption'] as CSSObject),
      },
    }),
  },
}

export default MuiFormLabel
