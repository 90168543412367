import CreateIcon from '@mui/icons-material/Create'
import CommentIcon from '@mui/icons-material/Comment'
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react'

import * as S from './SummaryItem.styles'
import { Formik, Form as FormikForm, Field, FieldProps } from 'formik'
import { Translate, FormValueProvider, FormHelpersProvider, TextInput, createField } from '@dentalux/ui-library-core'

import { FieldSettings, Form } from '@dentalux/ui-library-core'

import { useTranslation } from 'react-i18next'
import SubmitCountProvider from 'providers/SubmitCountProvider'
import buildValidationSchema from 'helpers/buildValidationSchema'
import getSummaryLabel from 'helpers/getSummaryLabel'
import { Comments, KeyedComment } from '../../@types/Comments'
import replaceOrAppend from 'helpers/replaceOrAppend'
import { Button } from '@mui/material'

type FormKeys = keyof Form

export type SummaryItemProps = {
  label: string
  name: string
  formValues?: Partial<Form>
  comments?: Partial<Comments>
  error?: string
  isExpanded?: boolean
  fieldSettings?: FieldSettings
  renderField?: (formValues: Partial<Form>, field?: FieldSettings) => ReactNode
  renderValue?: (value: unknown, values: Partial<Form>) => ReactNode
  renderLabel?: (field?: FieldSettings) => ReactNode
  itemKey?: string
  onCancel?: () => void
  onSave?: (values: Partial<Form>) => void
  onEdit?: (field: FormKeys) => void
  onComment?: (field: FormKeys) => void
  onSaveComment?: (field: FormKeys, value: string | KeyedComment[]) => void
  /* If true, the component will not have edit capabilities */
  readOnly?: boolean
  canComment?: boolean
  /* If true, the field will be highlighted in green */
  changed?: boolean
  commentChanged?: boolean
}

enum ActionType {
  Edit = 'Edit',
  Comment = 'Comment',
}

const fieldWithNestedComments = ['wishesAndExpectations', 'diseases']

const SummaryItem = React.memo(
  ({
    label,
    name,
    fieldSettings,
    formValues = {},
    error,
    isExpanded = false,
    renderField = (_: unknown, field?: FieldSettings) => field && createField(field),
    renderValue,
    renderLabel,
    readOnly = false,
    canComment,
    comments,
    itemKey,
    changed,
    commentChanged,
    onSave,
    onCancel,
    onEdit,
    onComment,
    onSaveComment,
  }: SummaryItemProps) => {
    const initialComment = useMemo(() => {
      const commentForField = comments?.[name as keyof Comments]

      if (!commentForField) return ''

      if (typeof commentForField === 'string') return commentForField

      if (fieldWithNestedComments.includes(name)) {
        const comments: KeyedComment[] = (commentForField as KeyedComment[]) || []

        return comments.find((comment) => comment.key === itemKey)?.value || ''
      }

      return ''
    }, [name, itemKey, comments])

    const value = formValues[name as keyof Form]

    // This is the scroll position before the user opens the edit section of a field
    const [scrollPosition, setScrollPosition] = useState<number | null>(null)

    const containerRef = useRef<HTMLDivElement | null>(null)
    const { t } = useTranslation()
    const [activeAction, setActiveAction] = useState<ActionType>(ActionType.Edit)
    const [comment, setComment] = useState(initialComment || '')

    const handleSubmit = (values: Partial<Form>) => {
      onSave?.(values)

      // move the scroll back to the original place
      if (scrollPosition) window.scrollTo({ top: scrollPosition })
    }

    const handleEdit = () => {
      onEdit?.(name as keyof Form)
      setActiveAction(ActionType.Edit)

      setScrollPosition(window.scrollY)
    }

    const handleComment = () => {
      if (!onComment) return

      onComment(name as keyof Form)
      setActiveAction(ActionType.Comment)
    }

    const formattedValue = useMemo(() => {
      if (renderValue) return renderValue(value, formValues)

      if (typeof value === 'boolean') return value ? t('yes') : t('no')

      if (typeof value === 'undefined' || value === null) return '–'

      if (value === 'true') return t('yes')

      if (value === 'false') return t('no')

      return String(value)
    }, [renderValue, value, t, formValues])

    const validationSchema = useMemo(() => {
      if (fieldSettings && fieldSettings.validation) {
        return buildValidationSchema({ [fieldSettings.name]: fieldSettings })
      }

      return
    }, [fieldSettings])

    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setComment(e.target.value)
    }

    const handleSaveComment = () => {
      const commentsForField = comments?.[name as keyof Comments]

      if (!fieldWithNestedComments.includes(name)) return onSaveComment?.(name as keyof Form, comment)
      if (typeof commentsForField === 'string') return

      const newComment = { key: itemKey || '', value: comment }
      const newComments = replaceOrAppend<KeyedComment>(
        (comment) => comment.key === itemKey,
        newComment,
        commentsForField || []
      )

      return onSaveComment?.(name as keyof Form, newComments)
    }

    const handleCancelComment = () => {
      setComment(initialComment || '')
      onCancel?.()
    }

    useEffect(() => {
      setTimeout(() => {
        if (!containerRef.current) return
        if (!isExpanded) return

        if (activeAction === ActionType.Edit) return containerRef.current.querySelector('input')?.focus()

        if (activeAction === ActionType.Comment) {
          const textArea = containerRef.current.querySelector('textarea')

          if (textArea) {
            textArea.focus()

            // Move the care to the end of the textarea
            textArea.select()
            textArea.selectionStart = textArea.selectionEnd
          }
        }
      }, 0)
    }, [isExpanded, activeAction])

    useEffect(() => {
      setComment(initialComment || '')
    }, [initialComment, name])

    return (
      <S.Container
        ref={containerRef}
        isExpanded={isExpanded && !readOnly}
        error={Boolean(error)}
        changed={changed && !readOnly}
        data-testid="summary-item"
      >
        <S.MainContainer>
          <S.Content data-hj-supress>
            <S.ValueContainer>
              <S.Label>
                {fieldSettings && renderLabel ? (
                  renderLabel(fieldSettings)
                ) : (
                  <Translate>{getSummaryLabel(label)}</Translate>
                )}
              </S.Label>

              <S.Value data-testid="summary-item-value" data-hj-supress>
                {formattedValue}
              </S.Value>

              {error && !isExpanded && (
                <S.ErrorMessage data-summary-error>
                  <Translate>{error}</Translate>
                </S.ErrorMessage>
              )}
            </S.ValueContainer>

            {initialComment && (
              <S.Comment data-hj-suppress changed={commentChanged}>
                {initialComment}
              </S.Comment>
            )}
          </S.Content>

          {!isExpanded && !readOnly && (onEdit || onComment) && (
            <S.ActionsContainer>
              {onEdit && (
                <S.ActionButton
                  title={t('edit').toString()}
                  aria-label={t('edit')}
                  name={t('edit')}
                  onClick={handleEdit}
                >
                  <CreateIcon />
                </S.ActionButton>
              )}

              {canComment && onComment && (
                <S.ActionButton
                  title={t('comment').toString()}
                  aria-label={t('comment')}
                  name={t('comment')}
                  onClick={handleComment}
                >
                  <CommentIcon />
                </S.ActionButton>
              )}
            </S.ActionsContainer>
          )}
        </S.MainContainer>

        {isExpanded && activeAction === ActionType.Edit && !readOnly && (
          <Formik initialValues={formValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            <FormikForm>
              <SubmitCountProvider>
                <FormValueProvider>
                  <FormHelpersProvider>
                    <S.EditContainer>
                      <Field>{(props: FieldProps) => renderField?.(props.form.values, fieldSettings)}</Field>

                      <S.EditContainerActions>
                        <Button size="lg" variant="borderless" onClick={onCancel} type="button">
                          <Translate children="cancel" />
                        </Button>

                        <Field>
                          {(props: FieldProps) => (
                            <Button type="submit" size="lg" disabled={!props.form.isValid}>
                              <Translate children="confirm" />
                            </Button>
                          )}
                        </Field>
                      </S.EditContainerActions>
                    </S.EditContainer>
                  </FormHelpersProvider>
                </FormValueProvider>
              </SubmitCountProvider>
            </FormikForm>
          </Formik>
        )}

        {isExpanded && activeAction === ActionType.Comment && !readOnly && (
          <S.EditContainer>
            <TextInput
              name="comment"
              label="Comment"
              rows={3}
              multiline
              value={comment}
              onChange={handleCommentChange}
            />

            <S.EditContainerActions>
              <Button type="button" onClick={handleCancelComment}>
                <Translate children="cancel" />
              </Button>

              <Button type="submit" onClick={handleSaveComment}>
                <Translate children="confirm" />
              </Button>
            </S.EditContainerActions>
          </S.EditContainer>
        )}
      </S.Container>
    )
  }
)

export default SummaryItem
