import { Theme } from '@mui/material'
import customTheme from '../theme'

export const printableMediaQuery = '@media print'
export const getMobileBreakpoint = (theme: Theme): string =>
  theme?.breakpoints?.down('xs') || customTheme.breakpoints.down('xs')

export const getTabletBreakpoint = (theme: Theme): string =>
  theme?.breakpoints?.down('sm') || customTheme.breakpoints.down('sm')

export const getLargetBreakpoint = (theme: Theme): string =>
  theme?.breakpoints?.down('lg') || customTheme.breakpoints.down('lg')
