import { Grid, styled } from '@mui/material'
import { printableMediaQuery } from '../../../../helpers/mediaQuery'

export const Root = styled(Grid)(({ theme }) => ({
  '&.MuiGrid-item': {
    border: 0,
    margin: 0,
    marginBottom: theme.spacing(3),

    [printableMediaQuery]: {
      pageBreakAfter: 'avoid',
      display: 'block',
    },
  },
}))

Root.defaultProps = { item: true, xs: 12 }
