import { Components, Theme } from '@mui/material'

const MuiStep: Components<Theme>['MuiStep'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: 0,
      backgroundColor: theme.palette.common.white,
      borderRadius: '50%',
    }),
  },
}

export default MuiStep
