import SuccessScreen from '../screens/Success'
import { useAnalytics } from '../providers/AnalyticsProvider'
import { useLocation } from 'react-router-dom'
import { useAnswers } from '../providers/AnswersProvider'
import useResetFlow from '../hooks/useResetFlow'
import qs from 'qs'
import useOnMount from '../hooks/useOnMount'
import { useMemo } from 'react'
import { SHORT_CODE_LENGTH } from 'providers/PatientProvider'

enum Mode {
  InClinic = 'InClinic',
  WithAssistantLink = 'WithAssistantLink',
}

const SuccessPage: React.FC = () => {
  const analytics = useAnalytics()
  const answers = useAnswers()
  const handleReset = useResetFlow()
  const location = useLocation()
  const { variant }: { variant?: string } = qs.parse(location.search.substring(1))
  const alreadyRegistered = !!answers?.patientHasHealthAssistant

  // We consider that the anamnesis is used in the clinic when the code
  // has the short form (4 digits)
  const isInClinic = answers?.code?.length === SHORT_CODE_LENGTH

  useOnMount(() => analytics.setStartedAt(null))

  const mode = useMemo((): Mode => (isInClinic ? Mode.InClinic : Mode.WithAssistantLink), [isInClinic])

  const inIframe = (() => {
    try {
      return window.self !== window.top
    } catch (e) {
      return true
    }
  })()

  const isShortRegistrationLink = (url: string) => {
    return /^(?!.*\/(registration|login)$).*/.test(url)
  }

  const handleSubmit = () => {
    const getQueryString = (query: { [key: string]: string }) =>
      Object.entries(query)
        .map(([key, value]) => (value ? `${key}=${encodeURIComponent(value)}` : null))
        .filter((value) => !!value)
        .join('&')

    if (answers.linkToHealthAssistant && mode === Mode.WithAssistantLink) {
      if (alreadyRegistered) {
        const { linkToHealthAssistant } = answers

        if (inIframe) {
          window.parent.postMessage({ message: 'redirect', destination: linkToHealthAssistant }, '*')
        } else {
          window.location.href = linkToHealthAssistant
        }
      } else {
        const query = {
          firstName: answers.firstName || '',
          lastName: answers.lastName || '',
          dateOfBirth: answers.birthday || '',
          email: answers.email || '',
          mobilePhone: answers.phone || '',
          patientRegistrationSource: 'ANAMNESIS',
        }

        const link = isShortRegistrationLink(answers.linkToHealthAssistant)
          ? answers.linkToHealthAssistant
          : `${answers.linkToHealthAssistant}?${getQueryString(query)}`

        if (inIframe) {
          window.parent.postMessage({ message: 'redirect', destination: link }, '*')
        } else {
          window.location.href = link
        }
      }
    } else {
      handleReset()
    }
  }

  return (
    <SuccessScreen
      variant={variant}
      inClinic={mode === Mode.InClinic}
      registered={alreadyRegistered}
      onSubmit={handleSubmit}
    />
  )
}

export default SuccessPage
