import { Components, Theme } from '@mui/material'

const MuiStepIcon: Components<Theme>['MuiStepIcon'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      width: theme.spacing(2.25),
      height: theme.spacing(2.25),
      color: theme.palette.custom.grey[400],

      '&.Mui-active, &.Mui-completed': {
        color: theme.palette.custom.aqua[800],
      },
    }),
    text: ({ theme }) => ({
      fontFamily: theme.typography['Sarabun Overline'].fontFamily,
      fontSize: theme.typography['Sarabun Overline'].fontSize,
      fontWeight: theme.typography['Sarabun Overline'].fontWeight,
    }),
  },
}

export default MuiStepIcon
