import { Typography } from '@mui/material'
import * as S from './Title.styles'
import { ReactNode } from 'react'

const Title = ({ children }: { children: ReactNode | ReactNode[] }) => {
  return (
    <S.Root>
      <Typography variant="Sarabun H6">{children}</Typography>
    </S.Root>
  )
}

export default Title
