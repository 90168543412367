import React from 'react'

import { Form } from '@dentalux/ui-library-core'

import menuItems, { MenuItem } from '../../config/sideMenu'
import { createMenuItem } from '../../factory/menuItem'

import * as S from './Stepper.styles'
import { usePatient } from '../../providers/PatientProvider'
import { Step, StepLabel, Stepper as MuiStepper } from '@mui/material'

export type StepperProps = {
  className?: string
  answers: Partial<Form>
  visitedSteps: string[]
  progress: number
  activeStepIndex: number
}

const Stepper: React.FC<StepperProps> = ({ className, answers, visitedSteps, progress, activeStepIndex }) => {
  const patient = usePatient()
  const renderStep = (menuItem: MenuItem, index: number) => {
    const menuItemHelpers = createMenuItem(menuItem, patient?.data)
    const isCompleted = menuItemHelpers.isCompleted(answers, visitedSteps)

    return (
      <Step key={menuItem.path} completed={isCompleted}>
        <StepLabel icon={index + 1} />
      </Step>
    )
  }

  return (
    <S.Container className={className}>
      <S.Progress aria-label={`${progress}% completed`} value={progress} />

      <MuiStepper activeStep={activeStepIndex}>{menuItems.map(renderStep)}</MuiStepper>
    </S.Container>
  )
}

export default Stepper
