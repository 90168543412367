import { useCallback, useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import PageLayout from 'components/PageLayout'
import SignatureModal from 'components/SignatureModal'

import { PageProps } from 'hooks/usePage'

import ServerError from '../../@types/ServerError'

import ensureConditionalFieldValues from 'helpers/ensureConditionalFieldsValues'
import ServerErrorOverlay from './components/ServerErrorOverlay'
import Summary from 'components/Summary'
import * as S from './Summary.styles'
import { useFormik } from 'formik'
import useSaveOnBack from 'hooks/useSaveOnBack'
import scrollToErrorMessage from 'helpers/scrollToErrorMessage'
import { createLayout } from 'config/summary'
import sentryService from 'services/sentry'
import * as GenericError from 'entities/GenericError'
import ConnectionErrorDialog from 'components/ConnectionErrorDialog'
import {
  getSignerName,
  getValidationSchemaForClinic,
  Button,
  addressSchema,
  insuranceSchema,
  lifestyleSchema,
  personalInformationSchema,
  getFormInitialErrors,
} from '@dentalux/ui-library-core'
import { Clinic, InsuranceCompany, Form } from '@dentalux/ui-library-core'
import { usePatient } from 'providers/PatientProvider'
import { useAnswers } from 'providers/AnswersProvider'

type AxiosErrorSummary<T extends unknown> = {
  message?: string
  response?: { data: T }
}

type Props = PageProps & {
  clinic?: Clinic
  error?: AxiosErrorSummary<ServerError>
  isSaving?: boolean
  insuranceCompanies: InsuranceCompany[]
}

const createFormSchema = (clinic: Clinic | undefined) => {
  return Yup.object()
    .shape({})
    .concat(personalInformationSchema as Yup.ObjectSchema)
    .concat(clinic ? (getValidationSchemaForClinic(clinic) as Yup.ObjectSchema) : (addressSchema as Yup.ObjectSchema))
    .concat(lifestyleSchema as Yup.ObjectSchema)
    .concat(insuranceSchema as Yup.ObjectSchema)
}

const SummaryScreen = ({
  initialValues = {},
  insuranceCompanies,
  isSaving,
  clinic,
  error,
  onChange,
  onSubmit,
  onBack,
}: Props) => {
  const { t, i18n } = useTranslation()

  const [showSignatureModal, setShowSignatureModal] = useState<boolean>(false)
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
  const answers = useAnswers()

  const isNetworkError = error ? GenericError.isNetworkError(error as Error) : false
  const showServerError = showErrorModal && !isNetworkError && Boolean(error?.response?.data)
  const showNetworkError = showErrorModal && isNetworkError

  const patient = usePatient()

  const handleFormikSubmit = useCallback(
    (values: Partial<Form>) => {
      const newValues = ensureConditionalFieldValues(values)

      onChange(newValues)
    },
    [onChange]
  )

  const validationSchema = useMemo(() => createFormSchema(clinic), [clinic])

  const initialErrors = useMemo(
    // @ts-ignore
    () => getFormInitialErrors(validationSchema, initialValues),
    [validationSchema, initialValues]
  )

  const formik = useFormik({
    initialValues,
    onSubmit: handleFormikSubmit,
    validationSchema,
    initialErrors,
  })

  const layout = createLayout({
    t,
    i18n,
    privateInsuranceCompanies: insuranceCompanies,
    shouldSkipReferral: answers.shouldSkipReferral,
    canEdit: true,
    optins: patient?.data?.optins || {
      dataProtectionAgreementAccepted: false,
      patientInformationAccepted: false,
      marketingCommunicationConsentAccepted: false,
      confidentialityAgreementAccepted: false,
      tosPatientAccountAccepted: false,
    },
  })

  const handleBack = useCallback(() => {
    onBack?.(formik.values)
  }, [formik.values, onBack])

  useSaveOnBack({ onBack: handleBack, skipRef: true })

  const handleSubmit = useCallback(() => {
    if (formik.isValid) {
      setShowSignatureModal(true)
    } else {
      formik.handleSubmit()
      scrollToErrorMessage()
    }
  }, [setShowSignatureModal, formik])

  const handleCloseSignatureModal = useCallback(() => {
    setShowSignatureModal(false)
    onChange({ ...formik.values, signatureEncoded: '' })
  }, [formik.values, onChange, setShowSignatureModal])

  const handleSignatureChange = useCallback(
    (signature: string) => {
      onChange({ ...formik.values, signatureEncoded: signature })
    },
    [onChange, formik.values]
  )

  const handleSignatureSubmit = useCallback(
    (signatureEncoded: string) => {
      setShowSignatureModal(false)
      // There is an edge case where some fields (usually `email` or `phone`) are invalid
      // but the validation is not catching it. We will do a second check when the user
      // submits the signature to be sure that the values are correctly filled. If not,
      // we will validate the form again and scroll to the error message, otherwise,
      // the form is sent to the backend.
      if (!formik.isValid) {
        sentryService.captureInvalidSubmittedFormError(formik.errors)
        formik.validateForm()
        scrollToErrorMessage()

        return
      }

      onSubmit({ ...formik.values, signatureEncoded })
    },
    [formik, onSubmit]
  )

  const handleCloseError = () => setShowErrorModal(false)

  const handleSummaryChange = (values: Partial<Form>) => {
    formik.setValues(values, true)
  }

  const handleNetworkErrorRetry = () => {
    onSubmit(formik.values)
  }

  useEffect(() => {
    if (error) {
      setShowErrorModal(true)
    }
  }, [error, setShowErrorModal])

  return (
    <PageLayout title={t('category.finalize')} subtitle={t('subcategory.summary')}>
      {showServerError && error?.response?.data && (
        <ServerErrorOverlay error={error?.response?.data} open={showErrorModal} onClose={handleCloseError} />
      )}

      {showNetworkError && (
        <ConnectionErrorDialog
          isOpen={showErrorModal}
          isLoading={isSaving}
          onClose={handleCloseError}
          onRetry={handleNetworkErrorRetry}
        />
      )}

      {showSignatureModal && (
        <SignatureModal
          isOpen={showSignatureModal}
          name={getSignerName(initialValues)}
          signature={initialValues.signatureEncoded}
          onSignatureChange={handleSignatureChange}
          onClose={handleCloseSignatureModal}
          onSubmit={handleSignatureSubmit}
        />
      )}

      <S.Container>
        <Summary values={formik.values} onChange={handleSummaryChange} errors={formik.errors} layout={layout} />

        <S.ActionsContainer>
          <Button size="lg" onClick={handleSubmit} data-testid="submit-btn">
            {t('category.finalize')}
          </Button>
        </S.ActionsContainer>
      </S.Container>
    </PageLayout>
  )
}

export default SummaryScreen
