const scrollToErrorMessage = () => {
  const $errorMessage = document.querySelector('[data-summary-error]')

  if ($errorMessage) {
    const additionalScrollOffset = window.innerHeight / 2
    const scrollPosition = window.scrollY
    const errorMessageY = $errorMessage.getBoundingClientRect().top

    window.scrollTo({
      top: scrollPosition + errorMessageY - additionalScrollOffset,
      behavior: 'smooth',
    })
  }
}

export default scrollToErrorMessage
