import { useState, useRef, useEffect, useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import BinIcon from '@mui/icons-material/Delete'
import { Translate } from '@dentalux/ui-library-core'
import Signature from 'signature_pad'

import Button from '@mui/material/Button'
import helpers from './SignaturePad.helpers'
import dateHelpers, { SupportedLocale } from 'helpers/date'

import * as S from './SignaturePad.styles'

type Props = {
  name: string
  innerRef?: React.MutableRefObject<Signature | undefined>
  onChange: (dataURL: string, pixelsCount?: number) => void
  onCanvasReady?: (canvas: HTMLCanvasElement) => void
  initialValue?: string
}

const SignaturePad: React.FC<Props> = ({ name, onChange, onCanvasReady, innerRef, initialValue }) => {
  const { i18n, t } = useTranslation()

  const [signaturePad, setSignaturePad] = useState<Signature | undefined>()
  const [hasValidSignature, setHasValidSignature] = useState<boolean>(!!initialValue)

  // We are saving the `initialValue` as a ref because we don't want it to trigger
  // the `resizeCanvas` whenever the value changes, but only on mount.
  const initialValueRef = useRef<string | undefined>(initialValue)

  const canvasRef = useRef<HTMLCanvasElement>()

  const resizeCanvas = useCallback(() => {
    const ratio = Math.max(window.devicePixelRatio || 1, 1)

    if (canvasRef.current && signaturePad) {
      const canvas = canvasRef.current

      canvas.width = canvas.width * ratio
      canvas.height = canvas.height * ratio
      canvas.getContext('2d')?.scale(1, 1)

      canvas.width = canvas.offsetWidth * ratio
      canvas.height = canvas.offsetHeight * ratio
      canvas.getContext('2d')?.scale(ratio, ratio)

      signaturePad.clear()

      const pixelsCount = helpers.countPaintedPixels(canvas)
      // We divide by the device pixel ratio to have similar values for screens
      // with different DPIs.
      onChange('', pixelsCount)
      setHasValidSignature(pixelsCount > 0)

      if (initialValueRef.current) {
        signaturePad.fromDataURL(initialValueRef.current)

        onChange(initialValueRef.current, pixelsCount)
      }
    }
  }, [onChange, signaturePad])

  const handleChange = useCallback(
    function (this: Signature) {
      const base64 = this.toDataURL()

      if (canvasRef.current) {
        const pixelsCount = helpers.countPaintedPixels(canvasRef.current)

        onChange(base64, pixelsCount)
        setHasValidSignature(pixelsCount > 0)
      } else {
        onChange(base64)
      }
    },
    [onChange]
  )

  const handleCanvasRef = useCallback(
    (canvas: HTMLCanvasElement) => {
      if (canvas) {
        canvasRef.current = canvas

        if (!signaturePad) {
          const newSignaturePad = new Signature(canvas, {
            onEnd: handleChange,
          })

          if (innerRef) innerRef.current = newSignaturePad

          setSignaturePad(newSignaturePad)
        }

        onCanvasReady?.(canvas)
      }
    },
    [handleChange, onCanvasReady, innerRef, signaturePad]
  )
  const handleClear = () => {
    if (canvasRef.current && signaturePad) {
      signaturePad.clear()

      setHasValidSignature(false)
      onChange('', 0)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeCanvas)

    resizeCanvas()

    return () => window.removeEventListener('resize', resizeCanvas)
  }, [resizeCanvas, signaturePad])

  return (
    <S.Root>
      <S.Frame>
        <S.Canvas ref={handleCanvasRef} data-testid="signature-pad" />

        <S.Message>
          {hasValidSignature && <S.SuccessIcon />}

          {hasValidSignature ? (
            <Translate
              patientName={name}
              date={dateHelpers.toShortMonthFormat(new Date(), { language: i18n.language as SupportedLocale })}
              children="signature_signed"
            />
          ) : (
            t('signature_prompt')
          )}
        </S.Message>
      </S.Frame>

      <Button
        fullWidth
        variant="borderless"
        startIcon={<BinIcon />}
        disabled={!hasValidSignature}
        onClick={handleClear}
      >
        {t('signature_clear')}
      </Button>
    </S.Root>
  )
}

export default SignaturePad
