import { FormikErrors } from 'formik'
import { useMemo, useState } from 'react'
import * as S from './Summary.styles'
import SummaryLayoutItemComponent, { SummaryLayoutItem } from './components/SummaryLayoutItem'
import { Comments, KeyedComment } from '../../@types/Comments'
import DetailedDiff from '../../@types/DetailedDiff'
import * as DiffType from 'entities/DiffType'
import { Translate, createField } from '@dentalux/ui-library-core'
import { HelperText } from '@dentalux/ui-library-core/cjs/custom/components/ana/utils/shared/Modal'
import { Form } from '@dentalux/ui-library-core'

export type SummaryProps = {
  values?: Partial<Form>
  comments?: Partial<Comments>
  onChange?: (values: Partial<Form>) => void
  onCommentsChange?: (comments: Partial<Comments>) => void
  errors?: FormikErrors<Form>
  anamnesisStandaloneMode?: boolean
  diff?: DetailedDiff
  commentsDiff?: Record<string, boolean>
  layout: SummaryLayoutItem[]
  title?: string
  subtitle?: string
}

const getFieldError = (field: string, errors: FormikErrors<Form>) => {
  const error = field ? errors?.[field as keyof Form] : undefined

  if (typeof error === 'string') return error
  if (Array.isArray(error) && typeof error[0] === 'string') return error[0]

  return undefined
}

const Summary = (props: SummaryProps) => {
  const {
    errors,
    layout,
    anamnesisStandaloneMode,
    comments,
    diff = {},
    commentsDiff = {},
    title,
    subtitle,
    onCommentsChange,
  } = props

  const [expandedField, setExpandedField] = useState<string | null>(null)
  const [innerValues, setInnerValues] = useState<Partial<Form>>(props.values || {})

  const values = useMemo(() => {
    if (!props.values) return innerValues

    if (props.values && !props.onChange) {
      console.warn(
        'You have provided `values` but didnt provided the `onChange`. This will make the component use the internal state instead of the `values` prop.'
      )

      return innerValues
    }

    return props.values
  }, [innerValues, props.onChange, props.values])

  const onChange = useMemo(() => props.onChange ?? setInnerValues, [props.onChange])
  const handleToggleEdit = (field: string) => setExpandedField(field)
  const handleToggleComment = (field: string) => setExpandedField(field)
  const handleCancel = () => setExpandedField(null)

  const handleSaveComment = (field: string, value: KeyedComment[] | string) => {
    const newComments = { ...comments, [field as keyof Comments]: value }

    onCommentsChange?.(newComments)
    setExpandedField(null)
  }

  const handleSave = (values: Partial<Form>) => {
    setExpandedField(null)
    onChange(values)
  }

  const defaultSummaryItemProps = {
    onEdit: handleToggleEdit,
    onComment: handleToggleComment,
    onSave: handleSave,
    onCancel: handleCancel,
    renderField: createField,
    onSaveComment: handleSaveComment,
    formValues: values,
    comments,
  }

  const visibleFields = useMemo(() => {
    return layout.filter((item) => {
      if (item.type === 'field') {
        const visibleWhen = item.visibleWhen || item.field.visibleWhen

        if (!visibleWhen) return true

        return visibleWhen(values)
      }

      if (item.type === 'custom' && item.visibleWhen && !item.visibleWhen(values)) return false

      return true
    })
  }, [layout, values])

  return (
    <S.Container>
      {anamnesisStandaloneMode && (
        <S.Title>
          <Translate>{title}</Translate>
        </S.Title>
      )}

      {anamnesisStandaloneMode && subtitle && (
        <HelperText textAlign="center" mb={2}>
          <Translate>{subtitle}</Translate>
        </HelperText>
      )}

      {visibleFields.map((item) => {
        const fieldName = item.type === 'field' ? item.field.name : item.type === 'custom' ? item.key : item?.text
        const itemKey = item.type !== 'title' ? item.itemKey : undefined

        return (
          <SummaryLayoutItemComponent
            key={fieldName}
            item={item}
            {...defaultSummaryItemProps}
            itemKey={itemKey}
            error={getFieldError(fieldName || '', errors || {})}
            isExpanded={fieldName === expandedField}
            anamnesisStandaloneMode={anamnesisStandaloneMode}
            changed={DiffType.hasChanged(diff[itemKey ?? fieldName])}
            commentChanged={commentsDiff[itemKey ?? fieldName]}
          />
        )
      })}
    </S.Container>
  )
}

export default Summary
