import SummaryScreen from '../screens/Summary'
import usePage from '../hooks/usePage'
import { usePatient } from '../providers/PatientProvider'
import { PostAnamnesisResponse } from '../services/api/ana'
import FullPageLoading from '../components/FullPageLoading'
import { useFactoringAgreement } from '../providers/FactoringAgreementProvider'
import { useAnalytics } from '../providers/AnalyticsProvider'
import { formatToTimeZone } from 'date-fns-timezone'
import dateHelpers from '../helpers/date'
import sentryService from 'services/sentry'
import { useTranslation } from 'react-i18next'
import usePostAnamnesis from 'hooks/usePostAnamnesis'
import { useInsuranceCompanies } from 'hooks/useInsuranceCompanies'
import { toBoolean, filterRequired } from '@dentalux/ui-library-core'
import useResetFlow from '../hooks/useResetFlow'
import { usePostOptins } from 'hooks/usePostOptins'
import { Form, WishAndExpectation } from '@dentalux/ui-library-core'

const SummaryPage: React.FC = () => {
  const page = usePage()
  const patient = usePatient()
  const factoringAgreement = useFactoringAgreement()
  const analytics = useAnalytics()
  const { data: insuranceCompanies = [] } = useInsuranceCompanies()
  const { i18n } = useTranslation()

  const reset = useResetFlow({ resetAnswers: false })

  const { mutateAsync: createAnamnesis, ...createAnamnesisStatus } = usePostAnamnesis({
    onSuccess: (data: PostAnamnesisResponse) => {
      factoringAgreement.setPdfLink(data.factoringAgreementUrl)
      factoringAgreement.setReferenceId(data.referenceId)

      page.onSubmit(
        {
          ...page.initialValues,
          ...data.healthAssistantSuccessActionInfoResult,
        } || {}
      )
    },
    onError: (error, form) => {
      sentryService.captureAnamnesisError({
        error,
        clinic: patient?.data?.clinic,
        code: form.code,
      })

      reset()
    },
  })

  const { mutateAsync } = usePostOptins()

  const handleSubmit = (values: Partial<Form>) => {
    const birthday = dateHelpers.formatBirthday(values.birthday || '')
    const holderBirthday = dateHelpers.formatBirthday(values.holderBirthday || '')
    const contactPersonBirthday = dateHelpers.formatBirthday(values.contactPersonBirthday || '')

    const wishesAndExpectations = values.wishesAndExpectations

    const { confidentialityAgreementAccepted, marketingCommunicationConsentAccepted } = values

    mutateAsync(
      {
        code: values.code || '',
        ...(confidentialityAgreementAccepted && { confidentialityAgreementAccepted }),
        ...(marketingCommunicationConsentAccepted && { marketingCommunicationConsentAccepted }),
      },
      {
        onSuccess: () => {
          createAnamnesis({
            ...values,
            language: i18n.language.toUpperCase() ?? 'DE',
            isPregnant: toBoolean(values.isPregnant),
            pregnantMonths:
              values.pregnantMonths && values.pregnantMonths > 0 ? Number(values.pregnantMonths) : undefined,
            wishesAndExpectations: wishesAndExpectations
              ?.filter((wish) => filterRequired(wish, wishesAndExpectations))
              ?.map(
                (wish): Omit<WishAndExpectation, 'requireFalse' | 'requireTrue'> => ({
                  ...wish,
                })
              )
              ?.filter((wish) => wish.flag !== undefined || wish.score !== undefined),
            signatureEncoded: values.signatureEncoded?.replace('data:image/png;base64,', ''),
            patientReferenceId: patient?.data?.referenceId || '',
            birthday,
            holderBirthday,
            contactPersonBirthday,

            /* We need to remove the `company` and `dentalInsuranceCompany` fields as these fields holds the
             * name of the insurance company and is not accepted by the BE when the reference id is present.
             */
            insuranceCompany: values.insuranceCompanyId ? undefined : values.insuranceCompany,
            dentalInsuranceCompany: values.dentalInsuranceCompanyId ? undefined : values.dentalInsuranceCompany,
            hasFamilyInsurance: values.hasFamilyInsurance ?? false,
            /*
             * We have to invert the value here because the question is
             * "Do you and the family insurance holder have the same address?"
             * but the field is "livesOnDifferentAddress", which is the opposite.
             */
            livesOnDifferentAddress: values.livesOnDifferentAddress ?? false,
            startAt: analytics.startedAt
              ? formatToTimeZone(analytics.startedAt, 'YYYY-MM-DD HH:mm:ss', { timeZone: 'Europe/Berlin' })
              : undefined,
          })
        },
      }
    )
  }

  if (createAnamnesisStatus.isLoading) return <FullPageLoading />

  return (
    <SummaryScreen
      {...page}
      clinic={patient?.data?.clinic}
      error={createAnamnesisStatus?.error ?? undefined}
      isSaving={createAnamnesisStatus.isLoading}
      onSubmit={handleSubmit}
      insuranceCompanies={insuranceCompanies}
    />
  )
}

export default SummaryPage
