import { Components, Theme } from '@mui/material'

const MuiStepper: Components<Theme>['MuiStepper'] = {
  defaultProps: {},
  styleOverrides: {
    root: () => ({
      padding: 0,
      background: 'transparent',
      position: 'relative',
      zIndex: 2,

      '& .MuiStepConnector-line.MuiStepConnector-lineHorizontal': {
        borderTopWidth: 0,
      },
    }),
  },
}

export default MuiStepper
