import { useState, useMemo, useContext, createContext } from 'react'

type FactoringAgreementContextType = {
  pdfLink?: string
  setPdfLink: React.Dispatch<string | undefined>
  referenceId?: string
  setReferenceId: React.Dispatch<string | undefined>
}

export const FactoringAgreementContext = createContext<FactoringAgreementContextType>({
  setPdfLink: () => {},
  setReferenceId: () => {},
})

const FactoringAgreementProvider: React.FC = ({ children }) => {
  const [pdfLink, setPdfLink] = useState<string | undefined>()
  const [referenceId, setReferenceId] = useState<string | undefined>()

  const value = useMemo(
    () => ({
      pdfLink,
      setPdfLink,
      referenceId,
      setReferenceId,
    }),
    [pdfLink, setPdfLink, referenceId, setReferenceId]
  )

  return <FactoringAgreementContext.Provider value={value}>{children}</FactoringAgreementContext.Provider>
}

export const useFactoringAgreement = (): FactoringAgreementContextType => {
  const context = useContext(FactoringAgreementContext)

  if (context === undefined) throw new Error('useFactoringAgreement must be used within FactoringAgreementProvider')

  return context
}

export default FactoringAgreementProvider
