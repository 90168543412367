import { CSSObject, styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { SubmitButton } from '@dentalux/ui-library-core'
import { typography } from '@dentalux/ui-library-core'

export const Container = styled('section')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  margin: theme.spacing(0, 'auto'),
  width: '100%',
  overflow: 'hidden',

  [theme.breakpoints.up('sm')]: {
    maxWidth: 480,
  },
}))

export const ImageContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '12px 42px',
  width: '100%',
}))

export const Image = styled('img')(() => ({
  width: '206px',
}))

export const Layout = styled('div')(() => ({
  margin: '12px',
}))

export const Button = styled(SubmitButton)(() => ({
  marginTop: 45,
  marginBottom: 40,
}))

export const TextContainer = styled('div')(({ theme }) => ({
  '& ul': {
    paddingLeft: theme.spacing(2),
  },
  '& li>p': {
    margin: 0,
  },
}))

export const Text = styled(Box)(({ theme }) => ({
  ...typography['Sarabun Body 2'],

  a: {
    ...(theme.typography['Sarabun Link 2'] as CSSObject),
  },
}))

export const LanguageSwitchContainer = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0px 19px',
    marginTop: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  }),
  { name: 'IntroLanguageSwitchContainer' }
)

export const DataProtectionNotice = styled(Typography)(() => ({
  paddingLeft: 16,
}))
