import { Modal as ModalStyle } from '@dentalux/ui-library-core'
import * as S from './CloseModal.styles'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@mui/material'

export type Props = {
  title: string
  body: string
  buttons: {
    cancel: {
      text: string
      onClick: () => void
    }
    save: { text: string; onClick: () => void }
    noSave?: { text: string; onClick: () => void }
  }
  isOpen: boolean
  isSaving?: boolean
  onClose: () => void
}

const CloseModal: React.FC<Props> = ({ title, body, buttons, isOpen, isSaving = false, onClose }) => {
  const { t } = useTranslation()

  return (
    <ModalStyle.ModalDialog open={isOpen} onClose={onClose}>
      <ModalStyle.DialogHeader>
        <ModalStyle.CloseButton onClick={onClose}>
          <CloseIcon />
        </ModalStyle.CloseButton>
      </ModalStyle.DialogHeader>

      <ModalStyle.DialogContent>
        <ModalStyle.Title>{t(title)}</ModalStyle.Title>

        <ModalStyle.HelperText>
          <S.Paragraph>{t(body)}</S.Paragraph>
        </ModalStyle.HelperText>
      </ModalStyle.DialogContent>

      <S.DialogActions>
        <S.Button size="sm" onClick={buttons.cancel.onClick} disabled={isSaving} variant="bordered">
          {t(`${buttons.cancel.text}`)}
        </S.Button>

        {buttons.noSave && (
          <S.Button size="sm" onClick={buttons.noSave.onClick} disabled={isSaving} variant="bordered">
            {t(`${buttons.noSave.text}`)}
          </S.Button>
        )}

        <S.Button
          size="sm"
          onClick={buttons.save.onClick}
          variant="bordered"
          disabled={isSaving}
          endIcon={isSaving ? <CircularProgress color="inherit" size={14} thickness={5} /> : undefined}
        >
          {t(`${buttons.save.text}`)}
        </S.Button>
      </S.DialogActions>
    </ModalStyle.ModalDialog>
  )
}

export default CloseModal
