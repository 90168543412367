import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { useCallback, useMemo, useState } from 'react'

import AnamnesisScreen from 'screens/Anamnesis'
import usePage from 'hooks/usePage'
import { usePatient } from 'providers/PatientProvider'
import * as api from 'services/api/ana'
import FullPageLoading from 'components/FullPageLoading'
import { AxiosError } from 'axios'
import AnamnesisEntity from 'entities/Anamnesis'

import ServerError from '../@types/ServerError'
import useVersions from 'hooks/useVersions'
import useVersion from 'hooks/useVersion'
import { toPost } from '@dentalux/ui-library-core'
import { InsuranceCompany, Form } from '@dentalux/ui-library-core'

import { Comments } from '../@types/Comments'
import { useSource } from '../providers/SourceProvider'
import GenericError from 'components/GenericError'
import CancelIcon from '@mui/icons-material/Cancel'
import SearchIcon from '@mui/icons-material/Search'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useInsuranceCompanies } from 'hooks/useInsuranceCompanies'
import { Anamnesis } from '../@types/Anamnesis'

export type AnaPageUrlParams = {
  anamnesisReferenceId: string
}

// default redirect in case source is missing
const getCalmasterUrl = () => {
  const stagingBaseUrl = 'https://staging-calmaster.dental21.de/'
  const productionBaseUrl = 'https://calmaster.dental21.de/'

  return process.env.REACT_APP_ENV === 'production' ? productionBaseUrl : stagingBaseUrl
}

const isCompany = (referenceId: string) => (company: Pick<InsuranceCompany, 'referenceId'>) =>
  company.referenceId === referenceId

const getInsurerName = (
  companies: Pick<InsuranceCompany, 'insurerName' | 'referenceId'>[],
  referenceId: string
): string | undefined => companies.find(isCompany(referenceId))?.insurerName

const AnamnesisPage: React.FC = () => {
  const page = usePage()
  const patient = usePatient()
  const { t } = useTranslation()

  const { data: insuranceCompanies = [] } = useInsuranceCompanies()
  const { enqueueSnackbar } = useSnackbar()

  const [version, setVersion] = useState<string | number | null>(null)
  const { anamnesisReferenceId } = useParams<AnaPageUrlParams>()

  const source = useSource()

  const [versions, versionsQuery] = useVersions(anamnesisReferenceId, {
    onSuccess: (data) => {
      setVersion(data[0]?.version || null)
    },
  })

  const versionIndex = useMemo(
    () => versions.findIndex((item) => item.version === Number(version)),
    [versions, version]
  )

  const originalVersion = useMemo(() => {
    if (versions.length === 1) return versions[0]?.version || null

    return versions[versionIndex + 1]?.version || null
  }, [versions, versionIndex])

  const [currentAnamnesis, currentAnamnesisQuery] = useVersion({
    anamnesisReferenceId,
    version,
  })

  const [originalAnamnesis, originalAnamnesisQuery] = useVersion({
    anamnesisReferenceId,
    version: originalVersion,
  })

  const handleVersionChange = useCallback((version: string | number) => {
    setVersion(version)
  }, [])

  const initialValues: Partial<Form> = useMemo(() => {
    const form = currentAnamnesis ? AnamnesisEntity.toForm(currentAnamnesis) : {}
    const companies = insuranceCompanies || []

    return {
      ...form,
      insuranceCompany: form.insuranceCompany ?? getInsurerName(companies, form.insuranceCompanyId || ''),

      dentalInsuranceCompany:
        form?.dentalInsuranceCompany ?? getInsurerName(companies, form.dentalInsuranceCompanyId || ''),
    }
  }, [currentAnamnesis, insuranceCompanies])

  const { mutateAsync: updateAnamnesis, ...updateAnamnesisStatus } = useMutation<
    Partial<Anamnesis>,
    AxiosError<ServerError>,
    api.UpdateAnamnesisInput
  >(api.updateAnamnesis, {
    onSuccess: () => {
      versionsQuery.refetch()
      enqueueSnackbar(t('update_success_message'), {
        variant: 'success',
      })
    },
    onError: (error) => {
      enqueueSnackbar(
        t('update_error_message', {
          serverError: error.response?.data?.message ?? 'Unknown',
        }),
        {
          variant: 'error',
        }
      )
    },
  })

  const handleSubmit = (values: Partial<Form>, comments: Partial<Comments>) => {
    return updateAnamnesis({
      anamnesisReferenceId,
      form: {
        anamnesisData: toPost(values),
        comments,
      },
    })
  }

  // On closing the anamnesis should be redirected to source or calmaster
  const handleRedirectToCalmaster = useCallback(() => {
    window.location.href = source || getCalmasterUrl()
  }, [source])

  if (versionsQuery.isLoading || originalAnamnesisQuery.isLoading) return <FullPageLoading />

  if (versionsQuery.error || currentAnamnesisQuery?.error || originalAnamnesisQuery?.error) {
    const statusCode =
      versionsQuery.error?.response?.status ||
      currentAnamnesisQuery.error?.response?.status ||
      originalAnamnesisQuery.error?.response?.status

    const errorMessage =
      versionsQuery.error?.response?.data?.message ||
      currentAnamnesisQuery.error?.response?.data?.message ||
      originalAnamnesisQuery.error?.response?.data?.message

    if (statusCode === 403 || statusCode === 401) {
      return <GenericError title="unauthorized_title" description="unauthorized_description" icon={<CancelIcon />} />
    }

    if (statusCode === 404) {
      return (
        <GenericError
          title="anamnesis_not_found_title"
          description="anamnesis_not_found_description"
          icon={<SearchIcon />}
        />
      )
    }

    return <GenericError title="generic_error_title" description={errorMessage} />
  }

  return (
    <AnamnesisScreen
      {...page}
      anamnesisReferenceId={anamnesisReferenceId}
      initialComments={currentAnamnesis?.comments || {}}
      clinic={patient?.data?.clinic}
      error={updateAnamnesisStatus?.error?.response?.data}
      onSubmit={handleSubmit}
      insuranceCompanies={insuranceCompanies}
      initialValues={initialValues}
      versions={versions}
      version={version}
      onVersionChange={handleVersionChange}
      originalAnamnesis={originalAnamnesis || null}
      isSaving={updateAnamnesisStatus.isLoading}
      onClose={handleRedirectToCalmaster}
    />
  )
}

export default AnamnesisPage
