import { colors } from '@dentalux/ui-library-core'
import { typography } from '@dentalux/ui-library-core'
import { Components, Theme } from '@mui/material'

const MuiCssBaseline: Components<Theme>['MuiCssBaseline'] = {
  styleOverrides: {
    html: {
      backgroundColor: colors.grey[100],
    },
    body: {
      ...typography['Sarabun Body 1'],
      color: colors.grey[800],
    },
    a: {
      color: colors.aqua[800],
    },
  },
}

export default MuiCssBaseline
