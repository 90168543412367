import { useCallback } from 'react'
import { useSetAnswers, useAnswers, Answers } from '../providers/AnswersProvider'
import useNavigation from './useNavigation'
import { AnamnesisStep } from '../@types/Patient'
import { usePatient } from '../providers/PatientProvider'
import ensureConditionalFieldValues from '../helpers/ensureConditionalFieldsValues'

type Values = Answers

export type PageProps = {
  initialValues?: Values
  stepsCompleted?: AnamnesisStep[]
  onSubmit: (values: Values, queryString?: string) => void
  onChange: (values: Values) => void
  onBack?: (values?: Values) => void
}

const usePage = (): PageProps => {
  const { goToNextStep, goToPreviousStep } = useNavigation()

  const patient = usePatient()
  const answers = useAnswers()
  const setAnswers = useSetAnswers()

  const onSubmit = useCallback(
    (values: Values) => {
      setAnswers((previous: Values) => ({ ...previous, ...values }))
      goToNextStep()
    },
    [goToNextStep, setAnswers]
  )

  const onChange = useCallback(
    (values: Values) => {
      setAnswers((previous: Values) => ensureConditionalFieldValues({ ...previous, ...values }))
    },
    [setAnswers]
  )

  const onBack = useCallback(
    (values?: Values) => {
      const isSyntheticEvent = values?.hasOwnProperty('_reactName') ?? false

      if (values && !isSyntheticEvent) {
        setAnswers((previous: Values) => ensureConditionalFieldValues({ ...previous, ...values }))
      }

      goToPreviousStep()
    },
    [goToPreviousStep, setAnswers]
  )

  return {
    initialValues: answers,
    stepsCompleted: patient?.data?.anamnesisState || [],
    onSubmit,
    onChange,
    onBack,
  }
}

export default usePage
