import { useHistory, useLocation } from 'react-router-dom'
import { useCallback } from 'react'
import { routes } from '../config/routes'
import { usePatient } from '../providers/PatientProvider'
import { useAnswers } from 'providers/AnswersProvider'

const routesValues = Object.values(routes)

const useNavigation = (): {
  goToNextStep: (queryString?: string) => void
  goToPreviousStep: () => void
  getCurrentRoute: () => { path: string } | undefined
} => {
  const location = useLocation()
  const history = useHistory()
  const patient = usePatient()
  const answers = useAnswers()

  const getCurrentRoute = useCallback(
    () => routesValues.find(({ path }) => path === location.pathname),
    [location?.pathname]
  )

  const goToNextStep = useCallback(
    (queryString?: string) => {
      const current = getCurrentRoute()

      const next = current?.getNextRoute?.(patient?.data?.anamnesisState || [], answers) || current?.next

      if (next) {
        history.push({
          pathname: next,
          search: queryString,
        })
      }
    },
    [history, getCurrentRoute, patient, answers]
  )

  const goToPreviousStep = useCallback(() => {
    const current = getCurrentRoute()

    const previous = current?.getPreviousRoute?.(patient?.data?.anamnesisState || [], answers) || current?.previous

    if (previous) {
      return history.push({
        pathname: previous,
      })
    }
  }, [history, getCurrentRoute, patient, answers])

  return {
    goToNextStep,
    goToPreviousStep,
    getCurrentRoute,
  }
}

export default useNavigation
