const getContainerWidth = (location: Location = window.location) => {
  if (
    location.hash === '#/summary' ||
    location.hash === '#/factoring-agreement' ||
    location.hash.startsWith('#/anamnesis')
  )
    return 641

  return 480
}

export const setContainerWidth = () => {
  const root = document.documentElement

  root.style.setProperty('--container-w-sm-up', `${getContainerWidth()}px`)
}
