const countPaintedPixels = (canvas: HTMLCanvasElement): number => {
  const context = canvas.getContext('2d')

  if (!context) return 0

  // Get the pixel data from the canvas. The `imageData.data` is a `Uint8ClampedArray`
  // containing the data in RGBA order, with integer values from 0 to 255.
  const imageData = context.getImageData(0, 0, canvas.width, canvas.height)

  let paintedPixels = 0

  // As the `data` contains the pixel information in 4 one-byte values (red, green, blue, alpha)
  // we iterate over each 4 indexes, because we are only interested in the alpha value.
  for (let i = 0; i < imageData.data.length; i += 4) {
    // Alpha different than 0 means that the pixel is painted
    if (imageData.data[i + 3] !== 0) paintedPixels++
  }

  return paintedPixels
}

export default { countPaintedPixels }
