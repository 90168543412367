import { AxiosError } from 'axios'
import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query'
import * as api from 'services/api/ana'

import Revision from '../@types/Revision'
import ServerError from '../@types/ServerError'

type ErrorType = AxiosError<ServerError>

type UseVersionsReturn = [Revision[], Omit<QueryObserverResult<Revision[], ErrorType>, 'data'>]

const useVersions = (
  anamnesisReferenceId: string,
  config: Omit<UseQueryOptions<Revision[], ErrorType>, 'queryFn' | 'queryKey'> = {}
): UseVersionsReturn => {
  const { data = [], ...rest } = useQuery<Revision[], ErrorType>(
    ['versions', anamnesisReferenceId],
    () => api.getVersions(anamnesisReferenceId),
    {
      enabled: Boolean(anamnesisReferenceId),
      ...config,
    }
  )

  return [data, rest]
}

export default useVersions
