import { printableMediaQuery } from '../../helpers/mediaQuery'
import { styled, CSSObject, Box } from '@mui/material'

export const Root = styled(Box)(() => ({
  paddingBottom: 80,
  marginBottom: 40,

  [printableMediaQuery]: {
    '& .MuiDivider-root': {
      // the background color must be set to transparent
      // due to a bug caused in printing mode: https://stackoverflow.com/a/9140648
      backgroundColor: 'transparent',
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: '#F2F2F2',
    },
  },
}))

export const Content = styled(Box)({
  '& > section': {
    paddingTop: 0,
  },

  '& .MuiCard-content > .MuiGrid-container': {
    display: 'block',
  },

  '& .MuiGrid-item': {
    padding: 0,
  },

  '& .MuiCard-root': {
    padding: 20,
  },
})

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0, 2.5),
  marginBottom: 40,
}))

export const SignatureBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: 200,
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: theme.spacing(0, 2.5),
  pageBreakInside: 'avoid',
}))

export const DateAndPlaceWrapper = styled(Box)({
  flex: 1,
  paddingRight: 40,
})

export const SignatureWrapper = styled(Box)({
  flex: 1,
  paddingLeft: 40,
})

export const LogoWrapper = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
}))

export const SignatureLine = styled(Box)({
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderTopColor: '#000',
  marginBottom: 9,
})

export const InvisiblePlaceholder = styled(Box)(({ theme }) => ({
  color: 'transparent',

  [printableMediaQuery]: {
    color: theme.palette.custom.grey[0],
  },
}))

export const printableMediaStyles = (): CSSObject => ({
  [printableMediaQuery]: {
    body: {
      backgroundColor: 'white',
      padding: 0,
      margin: 0,
      // The following line will enable the background colors in the page
      // when it's in the printing mode. The background color is disabled
      // by default in printing mode to save the printer ink.
      '-webkit-print-color-adjust': 'exact',
    },
  },
})
