import Patient from '../@types/Patient'
import * as Yup from 'yup'
import { Form } from '@dentalux/ui-library-core'

export type MenuItemBase = {
  path: string
  label: string
  icon: React.ReactElement
  steps: string[]
  getSteps?: (patient: Patient | undefined) => string[]
  validation?: Yup.ObjectSchema
}

export type MenuItem = MenuItemBase & {
  getProgress: (answers: Partial<Form>, visitedSteps: string[]) => { percentage: number; completed: boolean }
  isSelected?: (pathname: string) => boolean
  isSomeStepVisited: (visitedSteps: string[]) => boolean
  isEachStepVisited: (visitedSteps: string[]) => boolean
  isEachStepValid: (answers: Partial<Form>) => boolean
  isCompleted: (answers: Partial<Form>, visitedSteps: string[]) => boolean
}

export const createMenuItem = (menuItem: MenuItemBase, patient: Patient | undefined = undefined): MenuItem => {
  const steps = (patient && menuItem.getSteps?.(patient)) ?? menuItem.steps

  return {
    ...menuItem,

    getProgress(answers: Partial<Form>, visitedSteps: string[]): { percentage: number; completed: boolean } {
      const isVisited = (step: string) => visitedSteps.includes(step)

      const visitedStepsCount = steps.filter(isVisited).length
      const percentage = (visitedStepsCount / steps.length) * 100

      return {
        percentage,
        completed: this.isCompleted(answers, visitedSteps),
      }
    },

    isSelected(pathname: string): boolean {
      return steps.includes(pathname)
    },

    isCompleted(answers: Partial<Form>, visitedSteps: string[]): boolean {
      const validation = this.isEachStepValid(answers)
      const visited = this.isEachStepVisited(visitedSteps)
      return menuItem.validation ? validation && visited : visited
    },

    isSomeStepVisited(visitedSteps: string[]): boolean {
      return steps.some((step) => visitedSteps.includes(step))
    },

    isEachStepVisited(visitedSteps: string[]): boolean {
      const isVisited = (step: string) => {
        const indexOfStep = visitedSteps.indexOf(step)

        const isLast = visitedSteps.length - 1 === indexOfStep

        return indexOfStep !== -1 && !isLast
      }

      return steps.filter(isVisited).length === steps.length
    },

    isEachStepValid(answers: Partial<Form>): boolean {
      return menuItem.validation?.isValidSync(answers) || false
    },
  }
}
