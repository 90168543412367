import { styled } from '@mui/material/styles'

export const Container = styled('div')(() => ({}))

export const Title = styled('h1')(({ theme }) => ({
  fontSize: '21px',
  letterSpacing: 0.15,
  fontWeight: 500,
  textAlign: 'center',
  marginBottom: theme.spacing(2),
}))
