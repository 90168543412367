import { useFormikContext } from 'formik'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

const Context = createContext<number>(0)

const SubmitCountProvider: React.FC<{ initialValue?: number }> = ({ children, initialValue }) => {
  const [count, setCount] = useState<number>(initialValue || 0)
  const { submitCount } = useFormikContext()

  useEffect(() => {
    setCount((prev) => Math.max(submitCount, prev))
  }, [submitCount])

  const value = useMemo(() => {
    return count
  }, [count])

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useSubmitCount = (): number | undefined => {
  const context = useContext(Context)

  return context
}

export default SubmitCountProvider
