import { styled } from '@mui/material/styles'
import { Box, IconButton } from '@mui/material'

import OriginalStepper from '../Stepper'

export const Root = styled('nav')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  height: theme.spacing(9.5),
  position: 'sticky',
  top: 0,
  zIndex: 100,
  boxSizing: 'border-box',
}))

export const Container = styled('div')(
  ({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    margin: '0 auto',
    padding: theme.spacing(0, 2),

    [theme.breakpoints.up('sm')]: {
      padding: 0,

      maxWidth: 628,
    },

    [theme.breakpoints.up('md')]: {
      margin: 0,
      paddingLeft: theme.spacing(3),
      maxWidth: 'calc(var(--container-w-sm-up) + 188px)',
    },
  }),
  {
    name: 'NavbarContainer',
  }
)

export const MenuItemsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
  margin: theme.spacing(2.5, 0, 2, 0),
}))

export const MenuItem = styled('div')(
  ({ theme }) => ({
    display: 'flex',

    '&:nth-of-type(3)': {
      [theme.breakpoints.up('md')]: {
        visibility: 'hidden',
      },
    },
  }),
  {
    name: 'NavbarMenuItem',
  }
)

export const Button = styled(IconButton)(
  ({ theme }) => ({
    color: theme.palette.custom.aqua[800],

    '& .MuiSvgIcon-root': {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },

    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
  {
    name: 'NavbarButton',
  }
)

export const Stepper = styled(OriginalStepper)(({ theme }) => ({
  width: '100%',
  bottom: '9px',

  [theme.breakpoints.up('sm')]: {
    maxWidth: 'var(--container-w-sm-up)',
  },
}))

Stepper.displayName = 'StyledStepper'
