import { styled } from '@mui/material/styles'

import { Box, Typography } from '@mui/material'

import CheckCircle from '@mui/icons-material/CheckCircle'
import { colors } from '@dentalux/ui-library-core'

export const Root = styled(Box)(({ theme }) => ({}))

export const Canvas = styled('canvas')(({ theme }) => ({
  width: '100%',
  height: theme.spacing(18.75),
  userSelect: 'none',
}))

export const Frame = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.custom.aqua[25],
  marginBottom: theme.spacing(0.5),
  padding: theme.spacing(2, 1),
}))

export const Message = styled(Typography)(({ theme }) => ({
  display: 'flex',
  borderTop: `1px solid ${theme.palette.grey[800]}`,
  padding: theme.spacing(0.5, 2, 0, 2),
  color: theme.palette.grey[700],
  width: '100%',
}))

Message.defaultProps = { variant: 'Sarabun Caption' }

export const SuccessIcon = styled(CheckCircle)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  color: colors.success[600],
  marginRight: theme.spacing(0.5),
}))
