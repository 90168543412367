enum DiseaseType {
  INFECTIOUS = 'INFECTIOUS',
  HEART = 'HEART',
  CIRCULATORY_VASCULAR = 'CIRCULATORY_VASCULAR',
  LUNG = 'LUNG',
  BONE_MUSCULAR_COGNITIVE_TISSUE = 'BONE_MUSCULAR_COGNITIVE_TISSUE',
  CANCER = 'CANCER',
  METABOLIC = 'METABOLIC',
  GASTRIC_INTESTINAL = 'GASTRIC_INTESTINAL',
  LIVER_BILIARY = 'LIVER_BILIARY',
  KIDNEY_URINARY_TRACT = 'KIDNEY_URINARY_TRACT',
  PSYCHOLOGICAL = 'PSYCHOLOGICAL',
  MEDICATION_ALLERGIES = 'MEDICATION_ALLERGIES',
  OTHER_ALLERGIES = 'OTHER_ALLERGIES',
  SURGERY = 'SURGERY',
  OTHER = 'OTHER',
}

export default DiseaseType
