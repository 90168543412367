import { useCallback } from 'react'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import DiseaseType from '../../../../@types/DiseaseType'
import Group from '../Group'
import PreviewField from '../PreviewField'
import { PreviewFieldProps } from '../PreviewField/PreviewField'
import diseaseTypeHelpers from '../../../../helpers/diseaseType'
import { combinedFields } from '@dentalux/ui-library-core'
import { Form, FieldSettings, DiseaseGroupFieldProps } from '@dentalux/ui-library-core'

type Props = {
  values: Partial<Form>
  getEditableFieldProps: (settings: FieldSettings) => Omit<PreviewFieldProps, 'transform'>
}

const SummaryAllergies = ({ values, getEditableFieldProps }: Props) => {
  const { t } = useTranslation()

  const getPreConditionEditableFieldProps = useCallback(
    (settings: { diseaseType: DiseaseType }) => {
      const selectedDiseases = values?.diseases || []

      const diseasesForCurrentType = selectedDiseases.filter((disease) => disease.type === settings.diseaseType)
      const hasDiseaseForThisType = diseasesForCurrentType.length > 0

      const valueParts = diseasesForCurrentType.reduce(
        (parts, currentDisease) => {
          const medications = currentDisease.medications?.join(', ') || ''

          return [
            ...parts,
            `${t(`option.${currentDisease.name}`, { defaultValue: currentDisease.name })}${
              medications.length > 0 ? ` (${medications})` : ''
            }`,
          ]
        },
        [t('yes')]
      )

      const value = hasDiseaseForThisType ? valueParts.join(', ') : t('no')

      return {
        label: t(diseaseTypeHelpers.getQuestionTranslationKey(settings.diseaseType).replace('field.', 'summary.')),
        value,
      }
    },
    [t, values]
  )

  const renderPreConditionSummary = (options: Pick<DiseaseGroupFieldProps, 'hideMedications' | 'type'>) => {
    return <PreviewField {...getPreConditionEditableFieldProps({ diseaseType: options.type })} />
  }

  return (
    <Group>
      <Grid item xs={12} md={6}>
        {renderPreConditionSummary({ type: DiseaseType.SURGERY, hideMedications: true })}
      </Grid>

      <Grid item xs={12} md={6}>
        {renderPreConditionSummary({ type: DiseaseType.MEDICATION_ALLERGIES, hideMedications: true })}
      </Grid>

      <Grid item xs={12} md={6}>
        {renderPreConditionSummary({ type: DiseaseType.OTHER_ALLERGIES, hideMedications: true })}
      </Grid>

      <Grid item xs={12} md={6}>
        <PreviewField {...getEditableFieldProps(combinedFields.allergyPassField)} />
      </Grid>
    </Group>
  )
}

export default SummaryAllergies
