import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Group from '../Group'
import PreviewField from '../PreviewField'
import { Form } from '@dentalux/ui-library-core'

type Props = {
  values: Partial<Form>
}

const SummaryMedication = ({ values }: Props) => {
  const { t } = useTranslation()

  const selectedMedications = values.medications || []
  const hasMedication = selectedMedications.length > 0

  const medicationsParts = selectedMedications.reduce(
    (parts, medication) => {
      return [...parts, t(`option.${medication}`, { defaultValue: medication })]
    },
    [t('yes')]
  )

  return (
    <Group>
      <Grid item xs={12}>
        <PreviewField
          label={t('summary.do_you_regularly_take_any_other_medication')}
          value={hasMedication ? medicationsParts.join(', ') : t('no')}
        />
      </Grid>
    </Group>
  )
}

export default SummaryMedication
