import { SHORT_CODE_LENGTH, usePatient } from 'providers/PatientProvider'
import { useHistory } from 'react-router-dom'
import menuItems, { MenuItem } from '../../config/sideMenu'
import { createMenuItem } from '../../factory/menuItem'
import useResetFlow from '../../hooks/useResetFlow'
import { useAnswers } from '../../providers/AnswersProvider'
import { useVisitedSteps } from '../../providers/VisitedStepsProvider'

const useSideNav = () => {
  const answers = useAnswers()
  const visitedSteps = useVisitedSteps()
  const history = useHistory()
  const patient = usePatient()

  const handleReset = useResetFlow()

  // We consider that the anamnesis is used in the clinic when the code
  // has the short form (4 digits)
  const isInClinic = answers?.code?.length === SHORT_CODE_LENGTH

  const progressesTuples = menuItems.map((item) => {
    const menuItemHelpers = createMenuItem(item, patient?.data)

    return [item.label, menuItemHelpers.getProgress(answers, visitedSteps)]
  })

  const progresses = Object.fromEntries(progressesTuples)

  const handleStepClick = (menuItem: MenuItem) => {
    history.push(menuItem.path)
  }

  return {
    progresses,
    isInClinic,
    visitedSteps,
    onStepClick: handleStepClick,
    onReset: handleReset,
  }
}

export default useSideNav
