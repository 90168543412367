import { Components, CSSObject, Theme } from '@mui/material'

const MuiInputBase: Components<Theme>['MuiInputBase'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...(theme.typography['Sarabun Body 1'] as CSSObject),
      color: theme.palette.custom.grey[800],
    }),
  },
}

export default MuiInputBase
