/* eslint-disable @typescript-eslint/ban-types */
import React from 'react'

type Hooks<ContainerProps, PresenterProps> = Record<string, (args: ContainerProps) => Partial<PresenterProps>>

type UnknownRecord = Record<string, unknown>

const composeHooks =
  <ContainerProps extends {} = {}, PresenterProps extends {} = {}>(hooks: Hooks<ContainerProps, PresenterProps>) =>
  (Component: React.FC<PresenterProps>): React.FC<ContainerProps & Partial<PresenterProps>> => {
    const ComponentWithHooks: React.FC<ContainerProps & Partial<PresenterProps>> = (
      props: ContainerProps & Partial<PresenterProps>
    ) => {
      const hooksProps = Object.entries(hooks).reduce<UnknownRecord>((acc, [_, hook]) => {
        const hookValue = hook(props)

        Object.entries(hookValue).forEach(([key, value]) => {
          const duplicate = acc[key] ? value : props[key as keyof ContainerProps]

          if (typeof duplicate !== 'undefined') {
            console.warn(`prop '${key}' exists, overriding with value: '${duplicate}'`)
          }

          acc[key] = value
        })

        return acc
      }, {})

      return React.createElement(Component, { ...(hooksProps as PresenterProps), ...props })
    }

    ComponentWithHooks.displayName = `${Component.name || Component.displayName}Container`

    return ComponentWithHooks
  }

export default composeHooks
