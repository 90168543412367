import DiseaseType from '../@types/DiseaseType'
import { SummaryLayoutItem } from 'components/Summary/components/SummaryLayoutItem'
import {
  AllergiesField,
  createField,
  combinedFields as fields,
  InsuranceField,
  diseasesByType,
  Translate,
  wishesSettings,
  wishesAndExpectations,
  WishesRatingField,
  WishesToggleField,
} from '@dentalux/ui-library-core'
import diseaseTypeHelpers from 'helpers/diseaseType'
import { i18n, TFunction } from 'i18next'
import DiseasesSummaryItem from 'components/Summary/components/DiseaseItem'
import DiseaseItemValue from 'components/Summary/components/DiseaseItemValue'
import MedicationsItem from 'components/Summary/components/MedicationsItem'

import { Grid } from '@mui/material'
import referralSources from './referralSources'
import ReferralItem from 'components/ReferralItem'
import { getIsRequired } from '../helpers/getIsRequired'
import * as Disease from 'entities/Disease'
import DetailedDiff from '../@types/DetailedDiff'
import DiffArrayValue from 'components/DiffArrayValue'

import { WishSettings, WishAndExpectation, InsuranceFieldProps, Optins } from '@dentalux/ui-library-core'

export type CreateLayoutParams = {
  privateInsuranceCompanies?: InsuranceFieldProps['companiesOptions']
  i18n: i18n
  t: TFunction
  shouldSkipReferral?: boolean
  diseasesDiff?: DetailedDiff
  medicationsDiff?: DetailedDiff
  wishesDiff?: DetailedDiff
  optins?: Optins
  reviewOptins?: boolean
  canEdit?: boolean
}

type CreateDiseaseItemParams = {
  diseaseType: DiseaseType
  diseasesDiff?: DetailedDiff
  t: TFunction
}

type CreateRenderDiseaseValueParams = {
  targetDiseaseType: DiseaseType
  t: TFunction
  showMedications?: boolean
  diseasesDiff?: DetailedDiff
}

// The fields before are not able to be edited on the summary page.
// Using an object instead of array to have a faster lookup.
export const readOnlyFields: Record<string, boolean> = {
  confidentialityAgreementAccepted: true,
  marketingCommunicationConsentAccepted: true,
  cancellationFeeAccepted: true,
}

export const createRenderDiseaseValue =
  ({ targetDiseaseType, showMedications, diseasesDiff }: CreateRenderDiseaseValueParams) =>
  (diseases: Disease.Type[] | unknown) => {
    const diseasesForType = ((diseases || []) as Disease.Type[]).filter((disease) => disease.type === targetDiseaseType)

    return <DiseaseItemValue diseases={diseasesForType} diff={diseasesDiff} showMedications={showMedications} />
  }

export const createDiseaseItem = ({ diseaseType, t, diseasesDiff }: CreateDiseaseItemParams): SummaryLayoutItem => ({
  type: 'custom',
  key: diseaseType,
  label: diseaseTypeHelpers.getQuestionTranslationKey(diseaseType),
  name: 'diseases',
  itemKey: diseaseType,
  renderField: (values) => {
    return (
      <DiseasesSummaryItem
        diseaseType={diseaseType}
        diseases={diseasesByType[diseaseType]}
        initialValues={values || {}}
      />
    )
  },
  renderValue: createRenderDiseaseValue({
    targetDiseaseType: diseaseType,
    showMedications: true,
    t,
    diseasesDiff,
  }),
})

export const createWishItem = (wish: WishAndExpectation, t: TFunction): SummaryLayoutItem => {
  const wishSettings: WishSettings = wishesSettings[wish.key]

  return {
    type: 'custom',
    key: wish.key,
    label: `field.${wish.key}`,
    name: 'wishesAndExpectations',
    itemKey: wish.key,
    visibleWhen: ({ wishesAndExpectations = [] }) => {
      if (!wishSettings?.visibleWhen) return true

      return wishSettings.visibleWhen(wishesAndExpectations)
    },
    renderValue: (wishes) => {
      if (!Array.isArray(wishes)) return '–'

      const maybeWish: WishAndExpectation | undefined = (wishes as WishAndExpectation[]).find(
        (option) => option.key === wish.key
      )

      if (!maybeWish) return '–'

      const wishSettings: WishSettings = wishesSettings[maybeWish.key]

      if (typeof maybeWish.flag === 'boolean') return maybeWish.flag ? t('yes') : t('no')
      if (maybeWish.score) {
        const maybeOption = wishSettings.ratingOptions?.find((option) => option.value === String(maybeWish.score))

        if (maybeOption) return t(maybeOption.label, { postProcess: [] })
      }

      return '–'
    },
    renderField: () => {
      if (wish.flag !== undefined) {
        return <WishesToggleField wishKey={wish.key} label={`field.${wish.key}`} />
      }

      if (wish.score !== undefined || wish.hasOwnProperty('flag')) {
        return <WishesRatingField wishKey={wish.key} label={`field.${wish.key}`} />
      }

      return null
    },
  }
}

const createPersonalInformationItems = (_params: CreateLayoutParams): SummaryLayoutItem[] => [
  { type: 'title', text: 'subcategory.personal_data' },
  {
    type: 'field',
    field: fields.gender,
    renderValue: (value) => {
      if (typeof value !== 'string') return

      return <Translate fallback="">{`option.${value.toLowerCase()}`}</Translate>
    },
  },
  { type: 'field', field: fields.firstName },
  { type: 'field', field: fields.lastName },
  { type: 'field', field: fields.birthday },
  { type: 'field', field: fields.placeOfBirth },
]

const createContactDetailsItems = (_params: CreateLayoutParams): SummaryLayoutItem[] => [
  { type: 'title', text: 'subcategory.contact_details' },
  { type: 'field', field: fields.street },
  { type: 'field', field: fields.zipCode },
  { type: 'field', field: fields.city },
  { type: 'field', field: fields.email },
  { type: 'field', field: fields.phone },
]

const createInsuranceItems = ({ t, privateInsuranceCompanies, canEdit }: CreateLayoutParams): SummaryLayoutItem[] => [
  { type: 'title', text: 'subcategory.insurance_details' },
  {
    type: 'field',
    field: fields.hasCareLevel,
    visibleWhen: (values) => {
      return ['string', 'boolean', ...(canEdit ? ['undefined'] : [])].includes(typeof values.hasCareLevel)
    },
    renderValue: (value) => {
      if (typeof value !== 'string' && typeof value !== 'boolean') return '–'

      const stringValue = value.toString()

      const translationKey = fields.hasCareLevel.options.find((option) => option.value === stringValue)?.label

      return t(translationKey || stringValue, { defaultValue: value })
    },
  },
  {
    type: 'field',
    field: fields.careLevelLimitedInTime,
    visibleWhen: ({ hasCareLevel }) => (hasCareLevel ? [true, 'true'].includes(hasCareLevel) : false),
    renderValue: (value) => {
      if (typeof value !== 'string' && typeof value !== 'boolean') return '–'

      const stringValue = value.toString()

      const translationKey = fields.careLevelLimitedInTime.options.find((option) => option.value === stringValue)?.label

      return t(translationKey || stringValue, { defaultValue: value })
    },
  },
  {
    type: 'field',
    field: fields.entitledToIntegrationAssistance,
    visibleWhen: (values) => {
      return ['string', 'boolean', ...(canEdit ? ['undefined'] : [])].includes(
        typeof values.entitledToIntegrationAssistance
      )
    },
    renderValue: (value) => {
      if (typeof value !== 'string' && typeof value !== 'boolean') return '–'

      const stringValue = value.toString()

      const translationKey = fields.entitledToIntegrationAssistance.options.find(
        (option) => option.value === stringValue
      )?.label

      return t(translationKey || stringValue, { defaultValue: value })
    },
  },
  {
    type: 'field',
    field: fields.integrationAssistanceBasedOnType,
    visibleWhen: ({ entitledToIntegrationAssistance }) =>
      entitledToIntegrationAssistance ? [true, 'true'].includes(entitledToIntegrationAssistance) : false,
    renderValue: (value) => {
      if (typeof value !== 'string' && typeof value !== 'boolean') return '–'

      const stringValue = value.toString()

      const translationKey = fields.integrationAssistanceBasedOnType.options.find(
        (option) => option.value === stringValue
      )?.label

      return t(translationKey || stringValue, { defaultValue: value })
    },
  },
  {
    type: 'field',
    field: fields.type,
    renderValue: (value) => {
      if (typeof value !== 'string') return ''

      return <Translate fallback="">{`option.${value.toLowerCase()}`}</Translate>
    },
  },
  {
    type: 'field',
    field: fields.insuranceCompany,
    renderField: (values, field) => {
      if (!field) return

      return values.type === 'PRIVATE_INSURANCE' ? (
        <InsuranceField
          {...field}
          isRequired={getIsRequired(field.isRequired)}
          companiesOptions={privateInsuranceCompanies || []}
        />
      ) : (
        createField({ ...field, __type: 'text' })
      )
    },
  },
  { type: 'field', field: fields.hasFamilyInsurance },
  { type: 'field', field: fields.holderFirstName },
  { type: 'field', field: fields.holderLastName },
  { type: 'field', field: fields.holderBirthday },
  { type: 'field', field: fields.livesOnDifferentAddress },
  { type: 'field', field: fields.holderStreet },
  { type: 'field', field: fields.holderCity },
  { type: 'field', field: fields.holderZipcode },
  { type: 'field', field: fields.hasDentalInsurance },
  {
    type: 'field',
    field: fields.dentalInsuranceCompany,
    renderField: (_, field) => {
      if (!field) return

      return (
        <InsuranceField
          {...field}
          isRequired={getIsRequired(field.isRequired)}
          companiesOptions={privateInsuranceCompanies || []}
        />
      )
    },
  },
  { type: 'field', field: fields.hasCivilServiceAllowance },
]

const createMiscellaneousItems = (_params: CreateLayoutParams): SummaryLayoutItem[] => [
  { type: 'title', text: 'subcategory.miscellaneous' },
  { type: 'field', field: fields.occupation },
  { type: 'field', field: fields.isPregnant },
  { type: 'field', field: fields.pregnantMonths },
  { type: 'field', field: fields.isSmoker },
  { type: 'field', field: fields.cigarretesPerDay },
  { type: 'field', field: fields.consumesAlcohol },
  { type: 'field', field: fields.consumesDrugs },
  { type: 'field', field: fields.weightOver120Kg },
  { type: 'field', field: fields.heightOver2M },
]

export const createPreConditionsItems = ({ t, diseasesDiff }: CreateLayoutParams): SummaryLayoutItem[] => [
  { type: 'title', text: 'subcategory.pre_existing_conditions' },
  ...[
    DiseaseType.SURGERY,
    DiseaseType.PSYCHOLOGICAL,
    DiseaseType.LUNG,
    DiseaseType.METABOLIC,
    DiseaseType.BONE_MUSCULAR_COGNITIVE_TISSUE,
    DiseaseType.CIRCULATORY_VASCULAR,
    DiseaseType.GASTRIC_INTESTINAL,
    DiseaseType.HEART,
    DiseaseType.CANCER,
    DiseaseType.KIDNEY_URINARY_TRACT,
    DiseaseType.LIVER_BILIARY,
    DiseaseType.INFECTIOUS,
    DiseaseType.OTHER,
  ].map((diseaseType) => createDiseaseItem({ t, diseaseType, diseasesDiff })),
]

const createAllergiesAndMedicationsItems = ({
  t,
  diseasesDiff,
  medicationsDiff,
}: CreateLayoutParams): SummaryLayoutItem[] => [
  { type: 'title', text: 'subcategory.allergies_and_medications' },
  {
    type: 'field',
    field: fields.hasAllergies,
    renderValue: (value) => {
      if (typeof value !== 'string') return '–'

      const translationKey = fields.hasAllergies.options.find((option) => option.value === value)?.label

      return t(translationKey || value, { defaultValue: value })
    },
  },
  {
    type: 'custom',
    key: DiseaseType.MEDICATION_ALLERGIES,
    name: 'diseases',
    label: 'allergies_towards_medication',
    itemKey: DiseaseType.MEDICATION_ALLERGIES,
    visibleWhen: (values) => values.hasAllergies === 'YES',
    renderValue: createRenderDiseaseValue({ targetDiseaseType: DiseaseType.MEDICATION_ALLERGIES, t, diseasesDiff }),
    renderField: (values) => {
      if (values.hasAllergies !== 'YES') return null

      const hasAllergySelected =
        values?.diseases?.some(
          (disease) => disease.type === DiseaseType.MEDICATION_ALLERGIES || disease.type === DiseaseType.OTHER_ALLERGIES
        ) || false

      return (
        <AllergiesField
          label="allergies_towards_medication"
          diseaseType={DiseaseType.MEDICATION_ALLERGIES}
          options={diseasesByType.MEDICATION_ALLERGIES}
          error={!hasAllergySelected ? 'allergy_required' : undefined}
        />
      )
    },
  },
  {
    type: 'custom',
    key: DiseaseType.OTHER_ALLERGIES,
    name: 'diseases',
    label: 'other_allergies',
    itemKey: DiseaseType.OTHER_ALLERGIES,
    visibleWhen: (values) => values.hasAllergies === 'YES',
    renderValue: createRenderDiseaseValue({ targetDiseaseType: DiseaseType.OTHER_ALLERGIES, t, diseasesDiff }),
    renderField: (values) => {
      const hasAllergySelected =
        values?.diseases?.some(
          (disease) => disease.type === DiseaseType.MEDICATION_ALLERGIES || disease.type === DiseaseType.OTHER_ALLERGIES
        ) || false

      return (
        <AllergiesField
          label="other_allergies"
          diseaseType={DiseaseType.OTHER_ALLERGIES}
          options={diseasesByType.OTHER_ALLERGIES}
          error={!hasAllergySelected ? 'allergy_required' : undefined}
        />
      )
    },
  },
  { type: 'field', field: fields.allergyPass },
  {
    type: 'custom',
    key: 'medications',
    name: 'medications',
    label: 'field.do_you_regularly_take_any_other_medication',
    renderValue: (value) => {
      if (!Array.isArray(value) || !value.length) return t('no')

      const values = value.map((item) => ({ key: item, label: t(`option.${item}`, { defaultValue: item }) }))

      return <DiffArrayValue prefix="yes" values={values} diff={medicationsDiff || {}} />
    },
    renderField: () => {
      return <MedicationsItem />
    },
  },
]

const createAdditionalDetailsItems = ({ t, shouldSkipReferral }: CreateLayoutParams): SummaryLayoutItem[] => {
  return [
    { type: 'title', text: 'category.additional_details' },
    ...wishesAndExpectations.map((wish) => createWishItem(wish, t)),
    {
      type: 'custom',
      key: 'referral',
      label: 'summary.how_did_you_hear_from_us',
      visibleWhen: () => !!shouldSkipReferral,
      name: 'referral',
      renderValue: (value) => {
        if (typeof value !== 'string') return '-'

        return <Translate fallback={value} children={`option.${value}`} />
      },
      renderField: () => {
        return (
          <Grid container spacing={1}>
            {referralSources.map((source) => (
              <Grid item xs={6}>
                <ReferralItem referralKey={source.label} label={`option.${source.label}`} Icon={source.icon} />
              </Grid>
            ))}
          </Grid>
        )
      },
    },
  ]
}

export const createOptinsItems = ({ t, optins, reviewOptins }: CreateLayoutParams): SummaryLayoutItem[] => {
  return [
    (optins
      ? {
          type: 'field',
          field: fields.confidentialityAgreementAccepted,
          visibleWhen: reviewOptins ? () => true : undefined,
          renderValue: (value) => {
            if (optins?.confidentialityAgreementAccepted || value) return t('yes')

            return t('no')
          },
        }
      : {}) as SummaryLayoutItem,
    (optins
      ? {
          type: 'field',
          field: fields.marketingCommunicationConsentAccepted,
          visibleWhen: reviewOptins ? () => true : () => !optins?.marketingCommunicationConsentAccepted,
          renderValue: (value) => {
            if (optins?.marketingCommunicationConsentAccepted || value) return t('yes')

            return t('no')
          },
        }
      : {}) as SummaryLayoutItem,
    {
      type: 'custom',
      label: 'cancellation_notice',
      name: 'cancellationFeeAccepted',
      key: 'cancellationFeeAccepted',
      renderValue: () => t('yes'),
    },
  ]
}

export const createLayout = (params: CreateLayoutParams): SummaryLayoutItem[] => [
  ...createPersonalInformationItems(params),
  ...createContactDetailsItems(params),
  ...createInsuranceItems(params),
  ...createMiscellaneousItems(params),
  ...createPreConditionsItems(params),
  ...createAllergiesAndMedicationsItems(params),
  ...createAdditionalDetailsItems(params),
  ...createOptinsItems(params),
]
