import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Frame = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4.5),
  textAlign: 'center',
}))

export const Image = styled('img')(() => ({
  width: '176px',
}))

export const Text = styled(Typography)({
  textAlign: 'center',
})
