import i18n, { PostProcessorModule, TFunction } from 'i18next'
import qs from 'querystring'
import { initReactI18next } from 'react-i18next'
import { Remarkable } from 'remarkable'
import RemarkableReactRenderer from 'remarkable-react'
import { GetTranslationsReturn } from './contentful'
import removeMd from 'remove-markdown'

import enTranslations from '../i18n/en.json'
import deTranslations from '../i18n/de.json'

const md = new Remarkable({
  linkTarget: '_blank',
})

md.renderer = new RemarkableReactRenderer()

const markdownRenderer = (str: string) => {
  return md.renderInline(str)
}

const markdownModule: PostProcessorModule = {
  type: 'postProcessor',
  name: 'markdown',
  process: function (value: string) {
    return markdownRenderer(value)
  },
}

const markdownBlockModule: PostProcessorModule = {
  type: 'postProcessor',
  name: 'markdownBlock',
  process: function (value: string) {
    return md.render(value)
  },
}

const removeMarkdownModule: PostProcessorModule = {
  type: 'postProcessor',
  name: 'removeMarkdown',
  process: (value: string) => removeMd(value),
}

const supportedLanguages = ['en', 'de', 'cimode']

const isLanguageValid = (language: string) => supportedLanguages.includes(language.toLowerCase())

const getLanguageFromLocalStorage = (): string | undefined => {
  try {
    return localStorage.getItem('language') || undefined
  } catch (e) {}

  return
}

const getLanguageFromNavigator = (): string | undefined => {
  try {
    return window.navigator.language.substring(0, 2) || undefined
  } catch (e) {}

  return
}

const getLanguageFromUrl = (): string | undefined => {
  const searchParams = qs.parse(window.location.search.replace('?', ''))
  const hashQueryParams = qs.parse(window.location.hash.split('?')[1])

  if (typeof searchParams.lang !== 'string' && typeof hashQueryParams.lang !== 'string') return

  return hashQueryParams.lang?.toString() || searchParams.lang?.toString()
}

export const getCurrentLanguage = (): string => {
  const language = getLanguageFromUrl() || getLanguageFromLocalStorage() || getLanguageFromNavigator()

  if (language && isLanguageValid(language)) return language.toLowerCase()

  return 'de'
}

export const init = (resources: GetTranslationsReturn): Promise<TFunction> => {
  return i18n
    .use(markdownModule)
    .use(markdownBlockModule)
    .use(initReactI18next)
    .init({
      resources: {
        en: { translation: resources.en },
        de: { translation: resources.de },
      },
      lng: getCurrentLanguage(),
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
      postProcess: [markdownModule.name],
    })
}

export const initI18nForTests = () => {
  const i18nForTests = i18n.createInstance()

  return i18nForTests.use(removeMarkdownModule).init({
    resources: {
      en: { translation: enTranslations },
      de: { translation: deTranslations },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    postProcess: [removeMarkdownModule.name],
  })
}

export default i18n
