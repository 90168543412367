import { useEffect, EffectCallback } from 'react'

function useOnMount(callback: EffectCallback): void {
  useEffect(() => {
    if (typeof callback === 'function') {
      callback()
    }
    // Ignoring the dependencies to make it run only when the component mounts.
  }, []) //eslint-disable-line
}

export default useOnMount
