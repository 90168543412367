import { RouteChildrenProps } from 'react-router-dom'

import usePage from '../hooks/usePage'
import { PersonalInformationForm } from '@dentalux/ui-library-core'
import PageLayout from 'components/PageLayout'
import useSaveOnBack from 'hooks/useSaveOnBack'
import { useTranslation } from 'react-i18next'

const PersonalInformationPage: React.FC<RouteChildrenProps> = () => {
  const { onSubmit, onBack, initialValues = {} } = usePage()

  const formikRef = useSaveOnBack({ onBack })

  const { t } = useTranslation()

  return (
    <PageLayout title={t('category.general_information')} subtitle={t('subcategory.personal_data')}>
      <PersonalInformationForm
        canBeFilledForDifferentPerson
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={formikRef}
        loading={false}
      />
    </PageLayout>
  )
}

export default PersonalInformationPage
