import usePage from '../hooks/usePage'
import { usePatient } from '../providers/PatientProvider'
import PageLayout from 'components/PageLayout'
import { useTranslation } from 'react-i18next'
import { ConsentsForm } from '@dentalux/ui-library-core'
import { useAnswers } from 'providers/AnswersProvider'
import { Form } from '@dentalux/ui-library-core'

const Consents: React.FC = () => {
  const page = usePage()
  const patient = usePatient()
  const { t } = useTranslation()
  const answers = useAnswers()

  const handleSubmit = (values: Partial<Form>) => {
    return page.onSubmit(values)
  }

  const subtitle = (() => {
    if (
      patient?.data?.optins?.confidentialityAgreementAccepted &&
      patient?.data?.optins?.marketingCommunicationConsentAccepted
    ) {
      return 'subcategory.only_cancelation_policy'
    }

    if (
      !patient?.data?.optins?.confidentialityAgreementAccepted &&
      patient?.data?.optins?.marketingCommunicationConsentAccepted
    ) {
      return 'subcategory.confidentiality_release_and_cancelation_policy'
    }

    if (
      patient?.data?.optins?.confidentialityAgreementAccepted &&
      !patient?.data?.optins?.marketingCommunicationConsentAccepted
    ) {
      return 'subcategory.marketing_consent_and_cancelation_policy'
    }

    return 'subcategory.all_consents_and_cancelation_policy'
  })()

  return (
    <PageLayout title={t('category.finalize')} subtitle={t(subtitle)}>
      <ConsentsForm
        {...page}
        clientConfig={{
          space: process.env.REACT_APP_CONTENTFUL_SPACE_ID || '',
          accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY || '',
        }}
        initialValues={{ ...answers }}
        optins={patient?.data?.optins}
        loading={false}
        onSubmit={handleSubmit}
        clinicReferenceId={patient?.data?.clinic?.referenceId}
      />
    </PageLayout>
  )
}

export default Consents
