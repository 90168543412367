import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import * as S from './PreviewField.styles'

export type PreviewFieldProps = {
  label: React.ReactNode
  value?: React.ReactNode | string | number | boolean
  isRequired?: boolean
  transform?: (value: string | number | boolean) => React.ReactNode
}

const PreviewField = ({ value, label, isRequired, transform }: PreviewFieldProps) => {
  const { t } = useTranslation()

  const valueElement = useMemo(() => {
    if (typeof value === 'boolean') return value ? t('yes') : t('no')

    return value
  }, [value, t])

  const transformedValue = useMemo(() => {
    if (transform) {
      const typeofValue = typeof value
      const isDate = value instanceof Date
      const isTransformableType = ['string', 'number', 'boolean'].includes(typeofValue) || isDate

      if (isTransformableType) {
        /* Here we can safely cast the value because we made sure that the type is string, number or boolean */
        return transform(value as string | number | boolean)
      }
    }

    return valueElement
  }, [transform, value, valueElement])

  return (
    <S.Root>
      <S.Label>
        {isRequired ? <S.Asterisk>*</S.Asterisk> : null} {typeof label === 'string' ? t(label) : label}
      </S.Label>
      <S.Value>{transformedValue}</S.Value>
    </S.Root>
  )
}

export default PreviewField
