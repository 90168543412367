import ServerError from '../@types/ServerError'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { postAnamnesis, PostAnamnesisResponse } from 'services/api/ana'
import { Form } from '@dentalux/ui-library-core'

type UsePostAnamnesisProps = {
  onSuccess: (data: PostAnamnesisResponse) => void
  onError: (e: AxiosError<ServerError>, payload: Partial<Form>) => void
}

/*
 * This hook is a poor man react-query implementation. This is needed because react-query don't execute
 * a query/mutation when the browser is offline (see: https://github.com/tannerlinsley/react-query/issues/2179).
 * So to get around this limitation, I had to implement a similar hook to be able to catch network errors.
 */
const usePostAnamnesis = ({ onSuccess, onError }: UsePostAnamnesisProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<AxiosError | null>(null)

  const mutateAsync = async (form: Partial<Form>) => {
    setIsLoading(true)
    setError(null)

    try {
      const data = await postAnamnesis(form)

      setError(null)
      onSuccess(data)
    } catch (e) {
      // @ts-ignore
      setError(e)
      // @ts-ignore
      onError(e, form)
    }

    setIsLoading(false)
  }

  return { mutateAsync, isLoading, error }
}

export default usePostAnamnesis
