import { useCallback, useEffect, useState } from 'react'

import AxiosErrorSummary from '../../@types/AxiosErrorSummary'
import ServerError from '../../@types/ServerError'
import CodeInput from 'components/CodeInput'
import getAssetUrl from 'helpers/getAssetUrl'
import * as S from './Code.styles'
import { useTranslation } from 'react-i18next'
import { SHORT_CODE_LENGTH } from 'providers/PatientProvider'

export type CodeProps = {
  error?: AxiosErrorSummary<ServerError>
  onSubmit: (code: string) => void
}

const TO_MANY_REQUESTS_ERROR_CODE = '429'
const WAIT_TIME = 60 * 1000

const Code: React.FC<CodeProps> = ({ error, onSubmit }) => {
  const { t } = useTranslation()
  const [showToManyRequests, setShowToManyRequests] = useState(false)

  useEffect(() => {
    if (error?.code === TO_MANY_REQUESTS_ERROR_CODE) {
      setShowToManyRequests(true)
      setTimeout(() => {
        setShowToManyRequests(false)
      }, WAIT_TIME)
    }
  }, [error])

  const onSubmitCallback = useCallback(
    (value: string) => {
      onSubmit(value)
    },
    [onSubmit]
  )

  const invalidShortCode = error && error.response?.config?.params?.anamnesisCode?.length === SHORT_CODE_LENGTH

  return (
    <S.Container>
      <S.Image
        src={getAssetUrl('/images/code-illustration.png')}
        alt="Woman pointing to a browser window showing a password input."
      />

      {(!error || invalidShortCode) && (
        <>
          <S.Title>{t('category.code')}</S.Title>
          <CodeInput disabled={showToManyRequests} onComplete={onSubmitCallback} />
        </>
      )}

      {error && !showToManyRequests && (
        <S.Alert severity="error">
          {invalidShortCode
            ? t(`backend.error.patient_code_${error.response?.data?.status}`, error.response?.data?.message)
            : t('backend.error.invalid_or_expired_long_code')}
        </S.Alert>
      )}
      {showToManyRequests && <S.Alert severity="error">{t('error.too_many_requests')}</S.Alert>}
    </S.Container>
  )
}

export default Code
