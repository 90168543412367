import ImportContactsIcon from '@mui/icons-material/ImportContacts'
import PublicIcon from '@mui/icons-material/Public'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import CloudCircleIcon from '@mui/icons-material/CloudCircle'
import SubtitlesIcon from '@mui/icons-material/Subtitles'
import FilterFramesIcon from '@mui/icons-material/FilterFrames'
import EmailIcon from '@mui/icons-material/Email'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import MaximizeIcon from '@mui/icons-material/Maximize'
import BubbleChartIcon from '@mui/icons-material/BubbleChart'

const referralSources = [
  {
    label: 'search_engine',
    icon: PublicIcon,
  },
  {
    label: 'recommendation',
    icon: ThumbUpIcon,
  },
  {
    label: 'business_directory',
    icon: CloudCircleIcon,
  },
  {
    label: 'social_media',
    icon: BubbleChartIcon,
  },
  {
    label: 'transfer',
    icon: SubtitlesIcon,
  },
  {
    label: 'direct_mail',
    icon: EmailIcon,
  },
  {
    label: 'magazine',
    icon: ImportContactsIcon,
  },
  {
    label: 'poster',
    icon: FilterFramesIcon,
  },
  {
    label: 'passerby',
    icon: DirectionsWalkIcon,
  },
  {
    label: 'other',
    icon: MaximizeIcon,
  },
]

export default referralSources
