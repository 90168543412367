import { styled } from '@mui/material/styles'

export const Root = styled('section')({})

export const Container = styled('main')(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('md')]: {
    marginLeft: 305,
    width: 'calc(100% - 305px)',
  },
}))

export const Content = styled('main')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: '100%',
    maxWidth: 'var(--container-w-sm-up)',
    margin: '0 auto',
  },

  [theme.breakpoints.up('md')]: {
    marginLeft: 94,
  },
}))
