import { styled } from '@mui/material/styles'

export const Container = styled('div')(({ theme }) => ({
  maxWidth: 641,
  padding: theme.spacing(3, 4),
  margin: '0 auto',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(5),
  marginTop: theme.spacing(3),

  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

export const ActionsContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(6),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',

  '& button:first-of-type': {
    marginRight: theme.spacing(0.5),
  },
}))
