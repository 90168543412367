import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(4),
}))

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '36px',
  lineHeight: 1.3,
  marginTop: theme.spacing(1),
}))

export const Description = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
}))

export const IconWrapper = styled('div')(() => ({
  '& .MuiSvgIcon-root': {
    fontSize: '50px',
  },
}))
