import { personalInformationSchema } from '@dentalux/ui-library-core'

import Patient, { AnamnesisStep } from '../@types/Patient'
import { canSkipFactoring, canSkipAnamnesis } from '../helpers/steps'
import { Answers } from 'providers/AnswersProvider'
import { PatientProviderContextType } from 'providers/PatientProvider'

type Guard = (
  answers: Partial<Answers>,
  patient: PatientProviderContextType['patient'],
  previousSteps: string[]
) => {
  isValid: boolean
  path?: string
  /* The path to be redirect to when the validation doesn't pass */
  redirectTo?: string
}

type RouteSettings = {
  path: string
  next?: string
  previous?: string
  guard: Guard
  getNextRoute?: (stepsCompleted: AnamnesisStep[], answers: Answers) => string
  getPreviousRoute?: (stepsCompleted: AnamnesisStep[], answers: Answers) => string
}

export type RouteKey =
  | 'code'
  | 'intro'
  | 'personalInformation'
  | 'address'
  | 'extraInformation'
  | 'insurance'
  | 'preConditions1'
  | 'preConditions2'
  | 'preConditions3'
  | 'allergies'
  | 'wishes'
  | 'wishes2'
  | 'wishes3'
  | 'wishes4'
  | 'referral'
  | 'summary'
  | 'factoringAgreement'
  | 'consents'
  | 'success'

export const paths: Record<RouteKey, string> = {
  code: '/',
  intro: '/intro',
  personalInformation: '/personal-information',
  address: '/address',
  extraInformation: '/extra-information',
  insurance: '/insurance',
  preConditions1: '/pre-conditions-1',
  preConditions2: '/pre-conditions-2',
  preConditions3: '/pre-conditions-3',
  allergies: '/allergies',
  wishes: '/wishes',
  wishes2: '/wishes-2',
  wishes3: '/wishes-3',
  wishes4: '/wishes-4',
  referral: '/referral',
  consents: '/consents',
  summary: '/summary',
  factoringAgreement: '/factoring-agreement',
  success: '/success',
}

/* This value will be used to skip the back button guards when the application
 * is in production. We need this cause it's an requirement of D21-2572 as
 * the back functionality can be handy for debugging purposes.
 */
export const canSkipBackGuard = process.env.REACT_APP_ENV !== 'production'

/* This value will be used to skip the guards in case the `REACT_APP_SKIP_GUARD` is set,
 * so the developer can freely use the browser refresh and history buttons without being
 * auto redirected to the initial page.
 *
 * ATENTION: this feature should only be used in development.
 */
export const canSkipGuard = process.env.REACT_APP_ENV !== 'production' && process.env.REACT_APP_SKIP_GUARD !== undefined

const getStepsCompleted = (patient: Patient | undefined): AnamnesisStep[] => patient?.anamnesisState || []

const checkMultipleGuards =
  (...guards: Guard[]): Guard =>
  (answers, patient, visitedSteps) => {
    const guardResult = guards.map((guard) => guard(answers, patient, visitedSteps)).find(({ isValid }) => !isValid)

    return guardResult || { isValid: true }
  }

const isVisited = (visitedSteps: string[], path: string) => visitedSteps.includes(path)

const isFactoringVisited = (visitedSteps: string[]) => isVisited(visitedSteps, paths.factoringAgreement)

const isSuccessVisited = (visitedSteps: string[]) => isVisited(visitedSteps, paths.success)

export const preventGoBackAfterSuccessGuard =
  (currentPath: string): Guard =>
  (_, _patient, visitedSteps) => ({
    isValid: !isSuccessVisited(visitedSteps),
    redirectTo: paths.success,
    path: currentPath,
  })

export const preventGoBackAfterFactoringGuard =
  (currentPath: string): Guard =>
  (_, _patient, visitedSteps) => ({
    isValid: !isFactoringVisited(visitedSteps),
    redirectTo: paths.factoringAgreement,
    path: currentPath,
  })

const codeGuard: Guard = () => ({
  isValid: true,
  redirectTo: paths.code,
  path: paths.code,
})

const introGuard: Guard = (answers, patient) => ({
  isValid: Boolean(answers.code) && Boolean(patient?.isSuccess),
  redirectTo: paths.code,
  path: paths.intro,
})

const personalInformationGuard: Guard = (_, _patient) => {
  return {
    isValid: true,
    redirectTo: routes.personalInformation.previous,
    path: routes.personalInformation.path,
  }
}

const addressGuard: Guard = (answers, patient, visitedSteps) => {
  return {
    isValid:
      canSkipAnamnesis(getStepsCompleted(patient?.data)) ||
      personalInformationSchema.isValidSync(answers) ||
      isVisited(visitedSteps, routes.address.path),
    redirectTo: routes.address.previous,
    path: routes.address.path,
  }
}

const extraInformationGuard: Guard = (_, patient, visitedSteps) => ({
  isValid: canSkipAnamnesis(getStepsCompleted(patient?.data)) || isVisited(visitedSteps, routes.insurance.path),
  redirectTo: paths.insurance,
  path: paths.extraInformation,
})

const insuranceGuard: Guard = (_, _patient, visitedSteps) => ({
  isValid: canSkipAnamnesis(getStepsCompleted(_patient?.data)) || isVisited(visitedSteps, routes.address.path),
  redirectTo: paths.address,
  path: paths.insurance,
})

const preConditions1Guard: Guard = (_, _patient, visitedSteps) => ({
  isValid: canSkipAnamnesis(getStepsCompleted(_patient?.data)) || isVisited(visitedSteps, routes.insurance.path),
  redirectTo: paths.extraInformation,
  path: paths.preConditions1,
})

const preConditions2Guard: Guard = (_, _patient, visitedSteps) => ({
  isValid: canSkipAnamnesis(getStepsCompleted(_patient?.data)) || isVisited(visitedSteps, routes.preConditions1.path),
  redirectTo: paths.preConditions1,
  path: paths.preConditions2,
})

const preConditions3Guard: Guard = (_, _patient, visitedSteps) => ({
  isValid: canSkipAnamnesis(getStepsCompleted(_patient?.data)) || isVisited(visitedSteps, routes.preConditions2.path),
  redirectTo: paths.preConditions2,
  path: paths.preConditions3,
})

const allergiesGuard: Guard = (_, _patient, visitedSteps) => ({
  isValid: canSkipAnamnesis(getStepsCompleted(_patient?.data)) || isVisited(visitedSteps, routes.preConditions2.path),
  redirectTo: routes.preConditions3.previous,
  path: routes.allergies.path,
})

const wishesGuard: Guard = (_, _patient, visitedSteps) => ({
  isValid: canSkipAnamnesis(getStepsCompleted(_patient?.data)) || isVisited(visitedSteps, routes.allergies.path),
  redirectTo: routes.allergies.path,
  path: routes.wishes.path,
})

const wishes2Guard: Guard = (_, _patient, visitedSteps) => ({
  isValid: canSkipAnamnesis(getStepsCompleted(_patient?.data)) || isVisited(visitedSteps, routes.allergies.path),
  redirectTo: routes.wishes.previous,
  path: routes.wishes2.path,
})

const wishes3Guard: Guard = (_, _patient, visitedSteps) => ({
  isValid: canSkipAnamnesis(getStepsCompleted(_patient?.data)) || isVisited(visitedSteps, routes.allergies.path),
  redirectTo: routes.wishes.previous,
  path: routes.wishes3.path,
})

const wishes4Guard: Guard = (_, _patient, visitedSteps) => ({
  isValid: canSkipAnamnesis(getStepsCompleted(_patient?.data)) || isVisited(visitedSteps, routes.allergies.path),
  redirectTo: routes.wishes.previous,
  path: routes.wishes4.path,
})

const referralGuard: Guard = (_, _patient, visitedSteps) => ({
  isValid: canSkipAnamnesis(getStepsCompleted(_patient?.data)) || isVisited(visitedSteps, routes.wishes.path),
  redirectTo: routes.wishes.previous,
  path: routes.referral.path,
})

const confidentialityReleaseGuard: Guard = (_, _patient, visitedSteps) => ({
  isValid:
    canSkipAnamnesis(getStepsCompleted(_patient?.data)) ||
    isVisited(visitedSteps, routes.referral.path) ||
    !!_.shouldSkipReferral,
  redirectTo: routes.consents.previous,
  path: routes.consents.path,
})

const summaryGuard: Guard = (_, _patient, visitedSteps) => ({
  isValid: canSkipAnamnesis(getStepsCompleted(_patient?.data)) || isVisited(visitedSteps, routes.wishes.path),
  redirectTo: isFactoringVisited(visitedSteps) ? routes.factoringAgreement.path : routes.summary.previous,
  path: routes.summary.path,
})

const factoringAgreementGuard: Guard = (_, _patient, visitedSteps) => ({
  isValid: canSkipAnamnesis(getStepsCompleted(_patient?.data)) || isVisited(visitedSteps, routes.summary.path),
  redirectTo: isSuccessVisited(visitedSteps) ? routes.success.path : routes.factoringAgreement.previous,
  path: routes.factoringAgreement.path,
})

const successGuard: Guard = (_, patient, visitedSteps) => {
  const getIsValid = () => {
    const stepsCompleted = patient?.data?.anamnesisState
    const isSomeStepIncomplete = stepsCompleted ? stepsCompleted.some((state) => state.status !== 'COMPLETE') : false

    if (!stepsCompleted) return isVisited(visitedSteps, routes.factoringAgreement.path)

    if (!isSomeStepIncomplete) return isVisited(visitedSteps, routes.code.path)

    if (!canSkipAnamnesis(stepsCompleted || [])) return isVisited(visitedSteps, routes.summary.path)

    if (!canSkipFactoring(stepsCompleted || [])) return isVisited(visitedSteps, routes.factoringAgreement.path)

    return isVisited(visitedSteps, routes.intro.path)
  }

  return {
    isValid: getIsValid(),
    redirectTo: routes.summary.previous,
    path: routes.success.path,
  }
}

const codeGuards = [codeGuard]
const introGuards = [...codeGuards, introGuard]
const personalInformationGuards = [...introGuards, personalInformationGuard]
const addressGuards = [...personalInformationGuards, addressGuard]
const extraInformationGuards = [...addressGuards, extraInformationGuard]
const insuranceGuards = [...extraInformationGuards, insuranceGuard]
const preConditions1Guards = [...extraInformationGuards, preConditions1Guard]
const preConditions2Guards = [...preConditions1Guards, preConditions2Guard]
const preConditions3Guards = [...preConditions2Guards, preConditions3Guard]
const allergiesGuards = [...preConditions1Guards, allergiesGuard]
const medicamentsGuards = allergiesGuards
const wishesGuards = [...medicamentsGuards, wishesGuard]
const wishes2Guards = [...wishesGuards, wishes2Guard]
const wishes3Guards = [...wishes2Guards, wishes3Guard]
const wishes4Guards = [...wishes3Guards, wishes4Guard]
const referralGuards = [...wishes4Guards, referralGuard]
const confidentialityReleaseGuards = [...wishesGuards, confidentialityReleaseGuard]
const summaryGuards = [...confidentialityReleaseGuards, summaryGuard]
const factoringAgreementGuards = [...summaryGuards, factoringAgreementGuard]
const successGuards = [...factoringAgreementGuards, successGuard]

export const routes: Record<RouteKey, RouteSettings> = {
  code: {
    path: paths.code,
    next: paths.intro,
    guard: checkMultipleGuards(...codeGuards),
  },
  intro: {
    path: paths.intro,
    next: paths.personalInformation,
    guard: checkMultipleGuards(...introGuards),
    getNextRoute: (stepsCompleted: AnamnesisStep[]): string => {
      const isSomeStepIncomplete = stepsCompleted.some(
        (state) => state.status !== 'COMPLETE' && state.status !== 'NOT_REQUIRED'
      )

      if (!isSomeStepIncomplete) return paths.success

      // We will redirect to the personal information step (start of Anamnesis) if
      // the user hasn't completed the anamnesis before.
      if (!canSkipAnamnesis(stepsCompleted)) return paths.personalInformation

      if (!canSkipFactoring(stepsCompleted))
        // We will redirect to the factoring step if the user has completed
        // the Anamnesis step but haven't signed the factoring agreement.
        return paths.factoringAgreement

      return paths.success
    },
  },
  personalInformation: {
    path: paths.personalInformation,
    previous: paths.intro,
    next: paths.address,
    guard: checkMultipleGuards(...personalInformationGuards),
  },
  address: {
    path: paths.address,
    previous: paths.personalInformation,
    next: paths.insurance,
    guard: checkMultipleGuards(...addressGuards),
  },
  insurance: {
    path: paths.insurance,
    previous: paths.address,
    next: paths.extraInformation,
    guard: checkMultipleGuards(...insuranceGuards),
  },
  extraInformation: {
    path: paths.extraInformation,
    previous: paths.insurance,
    next: paths.preConditions1,
    guard: checkMultipleGuards(...extraInformationGuards),
  },
  preConditions1: {
    path: paths.preConditions1,
    previous: paths.extraInformation,
    next: paths.preConditions2,
    guard: checkMultipleGuards(...preConditions1Guards),
  },
  preConditions2: {
    path: paths.preConditions2,
    previous: paths.preConditions1,
    next: paths.preConditions3,
    guard: checkMultipleGuards(...preConditions2Guards),
  },
  preConditions3: {
    path: paths.preConditions3,
    previous: paths.preConditions2,
    next: paths.allergies,
    guard: checkMultipleGuards(...preConditions3Guards),
  },
  allergies: {
    path: paths.allergies,
    previous: paths.preConditions3,
    next: paths.wishes,
    guard: checkMultipleGuards(...allergiesGuards),
  },
  wishes: {
    path: paths.wishes,
    previous: paths.allergies,
    next: paths.wishes2,
    guard: checkMultipleGuards(...wishesGuards),
  },
  wishes2: {
    path: paths.wishes2,
    previous: paths.wishes,
    next: paths.wishes3,
    guard: checkMultipleGuards(...wishes2Guards),
  },
  wishes3: {
    path: paths.wishes3,
    previous: paths.wishes2,
    next: paths.wishes4,
    guard: checkMultipleGuards(...wishes3Guards),
  },
  wishes4: {
    path: paths.wishes4,
    previous: paths.wishes3,
    next: paths.referral,
    getNextRoute: (_, answers) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return answers.shouldSkipReferral ? routes.referral.next! : routes.referral.path
    },
    guard: checkMultipleGuards(...wishes4Guards),
  },
  referral: {
    path: paths.referral,
    previous: paths.wishes4,
    next: paths.consents,
    guard: checkMultipleGuards(...referralGuards),
  },
  consents: {
    path: paths.consents,
    previous: paths.referral,
    getPreviousRoute(_, answers) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return answers.shouldSkipReferral ? routes.referral.previous! : routes.referral.path
    },
    next: paths.summary,
    guard: checkMultipleGuards(...confidentialityReleaseGuards),
  },
  summary: {
    path: paths.summary,
    previous: paths.consents,
    guard: checkMultipleGuards(...summaryGuards),
    next: paths.factoringAgreement,
    getNextRoute: (stepsCompleted) => {
      if (!canSkipFactoring(stepsCompleted)) return paths.factoringAgreement

      return paths.success
    },
  },
  factoringAgreement: {
    path: paths.factoringAgreement,
    previous: paths.success,
    guard: checkMultipleGuards(...factoringAgreementGuards),
    next: paths.success,
  },
  success: {
    path: paths.success,
    previous: paths.summary,
    guard: checkMultipleGuards(...successGuards),
  },
}
