import qs from 'querystring'

const getSource = (search = '') => {
  const queryParams = qs.parse(search.substring(1))
  const sourceParam = queryParams.source // used to hold a redirect source in case we need to go back after anamnesis ends

  if (typeof sourceParam !== 'string') return ''

  return sourceParam
}

export default getSource
