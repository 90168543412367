import { MouseEvent, useState } from 'react'
import { Popover, Typography } from '@mui/material'
import * as StyledList from '../shared/ListItem'
import { useTranslation } from 'react-i18next'
import { LanguageWrapper, Flag } from './LanguageSwitch.styles'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { languageCodeToCountryCodeFallback } from 'helpers/languageCodeToCountryCodeFallback'

export type Props = {
  onChange: (language: LanguageCode) => void
}

export type LanguageCode = 'en' | 'de'

const LanguageItem: React.FC<Props & { displayValue: string; languageCode: LanguageCode }> = ({
  displayValue,
  languageCode,
  ...props
}) => {
  const { i18n } = useTranslation()
  const isSelected = i18n.language === languageCode

  const handleLanguageClick = (languageCode: LanguageCode) => {
    i18n.changeLanguage(languageCode)
    localStorage.setItem('language', languageCode)

    props.onChange(languageCode)
  }

  return (
    <StyledList.ListItem
      key={languageCode}
      selected={isSelected}
      onClick={() => handleLanguageClick(languageCode)}
      aria-current={isSelected}
    >
      <StyledList.ListItemText primary={<Typography variant="Sarabun Body 1">{displayValue}</Typography>} />
    </StyledList.ListItem>
  )
}

const LanguageSwitch: React.FC<Props> = ({ onChange }) => {
  const { i18n } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleLanguageIconClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (language: LanguageCode) => {
    onChange(language)
    setAnchorEl(null)
  }

  const countryCode = languageCodeToCountryCodeFallback.get(i18n.language as LanguageCode) || i18n.language

  return (
    <>
      <LanguageWrapper onClick={handleLanguageIconClick}>
        <Flag country={countryCode} />
        {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </LanguageWrapper>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <LanguageItem displayValue="Deutsch" languageCode="de" onChange={handleChange} />
        <LanguageItem displayValue="English" languageCode="en" onChange={handleChange} />
      </Popover>
    </>
  )
}

export default LanguageSwitch
