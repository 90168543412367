import { FC } from 'react'
import { RouteChildrenProps } from 'react-router-dom'

import usePage from 'hooks/usePage'
import { useTranslation } from 'react-i18next'
import useSaveOnBack from 'hooks/useSaveOnBack'
import { ContactDetailsForm } from '@dentalux/ui-library-core'
import PageLayout from 'components/PageLayout'

const ContactDetailsPage: FC<RouteChildrenProps> = () => {
  const { onBack, onSubmit, initialValues = {} } = usePage()
  const formikRef = useSaveOnBack({ onBack })
  const { t } = useTranslation()

  return (
    <PageLayout title={t('category.general_information')} subtitle={t('subcategory.contact_details')}>
      <ContactDetailsForm onSubmit={onSubmit} loading={false} innerRef={formikRef} initialValues={initialValues} />
    </PageLayout>
  )
}

export default ContactDetailsPage
