import { useSetAnswers, defaultAnswers, useAnswers } from '../providers/AnswersProvider'
import { useSetVisitedSteps } from '../providers/VisitedStepsProvider'
import { useAnalytics } from '../providers/AnalyticsProvider'
import { useFactoringAgreement } from '../providers/FactoringAgreementProvider'
import { useHistory } from 'react-router-dom'

const useResetFlow = ({ resetAnswers }: { resetAnswers: boolean } = { resetAnswers: true }): (() => void) => {
  const setAnswers = useSetAnswers()
  const answers = useAnswers()
  const setVisitedSteps = useSetVisitedSteps()
  const analytics = useAnalytics()
  const { setPdfLink, setReferenceId } = useFactoringAgreement()
  const history = useHistory()

  const handleReset = () => {
    setAnswers(resetAnswers ? { ...defaultAnswers, code: undefined } : { ...answers, signatureEncoded: undefined })
    setVisitedSteps([])
    setPdfLink(undefined)
    setReferenceId(undefined)
    analytics.setStartedAt(null)

    history.push('/')
  }

  return handleReset
}

export default useResetFlow
