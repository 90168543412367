import { styled } from '@mui/material/styles'
import { Button as UnstyledButton } from '@dentalux/ui-library-core'
import MuiDialogActions from '@mui/material/DialogActions'
import MuiTypography from '@mui/material/Typography'

export const Button = styled(UnstyledButton)(
  () => ({
    width: '100%',
    display: 'flex',

    '&:not(:first-of-type)': {
      marginLeft: 0,
      marginTop: 8,
    },
  }),
  { name: 'CloseModalButton' }
)

export const DialogActions = styled(MuiDialogActions)(
  ({ theme }) => ({
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  }),
  { name: 'CloseModalDialogAction' }
)

export const Paragraph = styled(MuiTypography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: '0px 56px',
  },
}))
