import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import MuiAlert from '@mui/material/Alert'

export const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 68,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '100vh',
}))

export const Image = styled('img')({
  width: '297px',
})

export const Title = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(4, 0, 3, 0),
}))

Title.defaultProps = { variant: 'Sarabun H5' }

export const Alert = styled(MuiAlert)(({ theme }) => ({
  marginTop: theme.spacing(3),
  fontFamily: theme.typography['Sarabun Caption'].fontFamily,
  maxWidth: 327,
}))
