export type RatingTopic = 'feeling' | 'frequency' | 'important'

export const wishesToRatingTopic: { [index: string]: RatingTopic } = {
  has_toothache: 'frequency',
  what_is_important: 'important',
  satisfied_tooth_color: 'feeling',
  satisfied_tooth_position: 'feeling',
}

export const labelsFeeling: { [index: number]: { dictionaryKey: string } } = {
  1: { dictionaryKey: 'option.rating.label.feeling.very_dissatisfied' },
  2: { dictionaryKey: 'option.rating.label.feeling.dissatisfied' },
  3: { dictionaryKey: 'option.rating.label.feeling.somewhat_dissatisfied' },
  4: { dictionaryKey: 'option.rating.label.feeling.satisfied' },
  5: { dictionaryKey: 'option.rating.label.feeling.very_satisfied' },
}

export const labelsFrequency: { [index: number]: { dictionaryKey: string } } = {
  1: { dictionaryKey: 'option.rating.label.frequency.never' },
  2: { dictionaryKey: 'option.rating.label.frequency.rarely' },
  3: { dictionaryKey: 'option.rating.label.frequency.sometimes' },
  4: { dictionaryKey: 'option.rating.label.frequency.often' },
  5: { dictionaryKey: 'option.rating.label.frequency.very_often' },
}

export const labelsImportant: { [index: number]: { dictionaryKey: string } } = {
  1: { dictionaryKey: 'option.rating.label.important.noAnswer' },
  2: { dictionaryKey: 'option.rating.label.important.functional' },
  3: { dictionaryKey: 'option.rating.label.important.aesthetic' },
  4: { dictionaryKey: 'option.rating.label.important.premium' },
}

export const labelsDirection: { [index: string]: { [index: number]: { dictionaryKey: string } } } = {
  feeling: labelsFeeling,
  frequency: labelsFrequency,
  important: labelsImportant,
}
