import { FunctionComponent } from 'react'
import { Translate } from '@dentalux/ui-library-core'
import * as S from './ReferralItem.styles'
import { useField } from 'formik'

export type ReferralItemProps = {
  label: string
  referralKey: string
  selected?: boolean
  Icon: FunctionComponent
}

const ReferralItem: React.FC<ReferralItemProps> = ({ label, referralKey, Icon }) => {
  const [field, , helpers] = useField<string>('referral')
  const selected = field.value === referralKey

  const handleChange = () => {
    helpers.setValue(referralKey)
  }

  return (
    <S.Button
      name={`referral-${referralKey}`}
      onClick={handleChange}
      selected={selected}
      aria-current={selected}
      type="button"
      role="button"
    >
      <S.IconWrapper>
        <Icon />
      </S.IconWrapper>

      <S.Text>
        <Translate>{label}</Translate>
      </S.Text>
    </S.Button>
  )
}

export default ReferralItem
