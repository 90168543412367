import React from 'react'
import { ReactNode } from 'react'
import { Translate, createField } from '@dentalux/ui-library-core'
import { Form, FieldSettings } from '@dentalux/ui-library-core'

import SummaryItem from 'components/SummaryItem'
import * as S from './SummaryLayoutItem.styles'
import { Comments, KeyedComment } from '../../../../@types/Comments'
import { readOnlyFields } from 'config/summary'
import { useCredentials } from '@dentalux/security'
import * as User from 'entities/User'

export type SummaryLayoutItem =
  | {
      type: 'field'
      field: FieldSettings
      itemKey?: string
      error?: string
      renderField?: (values: Partial<Form>, fieldSettings?: FieldSettings) => ReactNode
      renderValue?: (value: unknown, values: Partial<Form>) => ReactNode
      visibleWhen?: (values: Partial<Form>) => boolean
      renderLabel?: (field?: FieldSettings) => ReactNode
      changed?: boolean
      commentChanged?: boolean
    }
  | {
      type: 'custom'
      key: string
      name: string
      label: string
      error?: string
      renderField?: (values: Partial<Form>, fieldSettings?: FieldSettings) => ReactNode
      renderValue: (value: unknown, values: Partial<Form>) => ReactNode
      visibleWhen?: (values: Partial<Form>) => boolean
      renderLabel?: (field?: FieldSettings) => ReactNode
      itemKey?: string
      changed?: boolean
      commentChanged?: boolean
    }
  | { type: 'title'; text: string }

export type SummaryLayoutItemProps = {
  item: SummaryLayoutItem
  // Used as a identified for array fields (eg.: diseases and wishesAndExpectations)
  itemKey?: string
  onEdit?: (field: string) => void
  onComment?: (field: string) => void
  onSave?: (values: Partial<Form>) => void
  onSaveComment?: (field: string, value: KeyedComment[] | string) => void
  onCancel?: () => void
  error?: string
  renderField: (fieldSettings: FieldSettings) => ReactNode
  isExpanded: boolean
  formValues?: Partial<Form>
  comments?: Partial<Comments>
  anamnesisStandaloneMode?: boolean
  changed?: boolean
  commentChanged?: boolean
}

const SummaryLayoutItemComponent: React.FC<SummaryLayoutItemProps> = React.memo((props) => {
  const { authentication: user } = useCredentials()
  const { item, anamnesisStandaloneMode = false } = props

  const defaultRenderer = (_: Partial<Form>, field?: FieldSettings) => field && createField(field)
  const canEditAnamnesis = !user || User.canEditAnamnesis(user)

  if (item.type === 'title')
    return (
      <S.Title key={item.text}>
        <Translate>{item.text}</Translate>
      </S.Title>
    )

  if (item.type === 'field')
    return (
      <SummaryItem
        {...props}
        key={item.field.name}
        label={item.field.label}
        name={item.field.name}
        fieldSettings={item.field}
        onEdit={props.onEdit && (() => props.onEdit?.(item.field.name))}
        onComment={props.onComment && (() => props.onComment?.(item.field.name))}
        renderField={item.renderField || defaultRenderer}
        renderValue={item.renderValue}
        renderLabel={item.renderLabel}
        canComment={anamnesisStandaloneMode}
        readOnly={readOnlyFields[item.field.name] || !canEditAnamnesis}
      />
    )

  if (item.type === 'custom') {
    return (
      <SummaryItem
        {...props}
        key={item.key}
        onEdit={props.onEdit && (() => props.onEdit?.(item.key as keyof Form))}
        onComment={props.onComment && (() => props.onComment?.(item.key as keyof Form))}
        label={item.label}
        name={item.name}
        renderField={item.renderField || defaultRenderer}
        renderValue={item.renderValue}
        renderLabel={item.renderLabel}
        canComment={anamnesisStandaloneMode}
        readOnly={readOnlyFields[item.name] || !canEditAnamnesis}
      />
    )
  }

  return null
})

export default SummaryLayoutItemComponent
