import { SnackbarProvider } from 'notistack'

const NotificationMessageProvider: React.FC = ({ children }) => {
  return (
    <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
      {children}
    </SnackbarProvider>
  )
}

export default NotificationMessageProvider
