import { Language } from '../@types/Language'

export const languageCodeToCountryCodeFallback = new Map<keyof typeof Language, string>([
  ['ja', 'jp'],
  ['da', 'dk'],
  ['zh', 'cn'],
  ['cs', 'cz'],
  ['en', 'gb'],
  ['ku', 'iq'],
  ['fa', 'ir'],
  ['el', 'gr'],
  ['sq', 'al'],
  ['hi', 'in'],
  ['ti', 'er'],
  ['ar', 'ps'],
  ['sr', 'rs'],
  ['vi', 'vn'],
  ['bs', 'ba'],
  ['sw', 'ke'],
])
