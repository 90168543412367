import { CssBaseline, buttonClasses } from '@mui/material'
import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import GlobalStyles from '@mui/material/GlobalStyles'
import theme from '../theme'
import { CSSProperties, ReactNode } from 'react'

const forcedStyles = (styleObject: CSSProperties) =>
  Object.entries(styleObject).reduce(
    (importantStyle, [key, value]) => ({ ...importantStyle, [key]: `${value} !important` }),
    {} as CSSProperties
  )

const ThemeProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          [`.${buttonClasses.root}`]: {
            ...forcedStyles(theme.typography['Sarabun Button']),
          },
        }}
      />
      {children}
    </MuiThemeProvider>
  )
}

export default ThemeProvider
