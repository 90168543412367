import { styled } from '@mui/material/styles'

export const Container = styled('div')(({ theme }) => ({
  maxWidth: 641,
  padding: theme.spacing(3, 4),
  margin: '0 auto',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(5),
}))

export const ActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',

  '& button:first-of-type': {
    marginRight: theme.spacing(0.5),
  },
}))

export const ExitButtonContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
}))

export const SelectWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: theme.spacing(2),

  '& > *': {
    width: '100%',
  },

  [theme.breakpoints.up('sm')]: {
    width: 359,
    marginBottom: 0,
  },
}))

export const TopActions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  paddingTop: 0,

  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

export const StickyHeader = styled('header')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  position: 'sticky',
  top: 0,
  zIndex: 200,

  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
  },
}))
