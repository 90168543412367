import { useCallback } from 'react'
import { Switch, Route } from 'react-router-dom'

import { LoginPage, ProtectedRoute } from '@dentalux/security'

import GuardedRoute from './components/GuardedRoute'

import CodePage from './pages/Code'
import IntroPage from './pages/Intro'
import PersonalInformationPage from './pages/PersonalInformation'
import ContactDetailsPage from './pages/ContactDetails'
import OtherPersonalInformationPage from './pages/OtherPersonalInformation'
import PreConditions1Page from './pages/PreConditions'
import PreConditions2Page from './pages/PreConditions2'
import AllergiesPage from './pages/Allergies'
import Wishes1Page from './pages/Wishes'
import Wishes2Page from './pages/Wishes2'
import Wishes3Page from './pages/Wishes3'
import Wishes4Page from './pages/Wishes4'
import SummaryPage from './pages/Summary'
import SuccessPage from './pages/Success'
import InsurancePage from './pages/Insurance'
import FullPageLoading from './components/FullPageLoading'
import PreConditions3Page from './pages/PreConditions3'
import ReferralPage from './pages/Referral'
import FactoringAgreementPage from './pages/FactoringAgreement'
import ConsentsPage from './pages/Consents'
import PrintableSummaryPage from './pages/PrintableSummary'

import AppShell from './components/AppShell'

import { routes, preventGoBackAfterFactoringGuard, preventGoBackAfterSuccessGuard } from './config/routes'
import { useAnswers } from './providers/AnswersProvider'
import { useVisitedSteps } from './providers/VisitedStepsProvider'
import { usePatient } from './providers/PatientProvider'
import AnamnesisPage from 'pages/Anamnesis'

const Routes = () => {
  const answers = useAnswers()
  const visitedSteps = useVisitedSteps()
  const patient = usePatient()

  const withGuardParams = useCallback(
    (guard) => () => guard(answers, patient, visitedSteps),
    [answers, patient, visitedSteps]
  )

  if (patient?.status === 'loading') return <FullPageLoading />

  return (
    <Switch>
      <Route exact path="/login">
        <LoginPage title="Ana" />
      </Route>

      <ProtectedRoute path="/anamnesis/:anamnesisReferenceId">
        <AnamnesisPage />
      </ProtectedRoute>
      <Route component={PrintableSummaryPage} path="/summary/print" exact />

      <GuardedRoute guard={withGuardParams(routes.code.guard)} component={CodePage} path={routes.code.path} exact />

      <GuardedRoute guard={withGuardParams(routes.intro.guard)} component={IntroPage} path={routes.intro.path} exact />

      <Route path="/">
        <AppShell>
          <Switch>
            <GuardedRoute
              guard={withGuardParams(routes.personalInformation.guard)}
              path={routes.personalInformation.path}
              component={PersonalInformationPage}
              exact
            />

            <GuardedRoute
              guard={withGuardParams(routes.address.guard)}
              path={routes.address.path}
              component={ContactDetailsPage}
              exact
            />

            <GuardedRoute
              guard={withGuardParams(routes.extraInformation.guard)}
              path={routes.extraInformation.path}
              component={OtherPersonalInformationPage}
              exact
            />

            <GuardedRoute
              guard={withGuardParams(routes.insurance.guard)}
              path={routes.insurance.path}
              component={InsurancePage}
              exact
            />

            <GuardedRoute
              guard={withGuardParams(routes.preConditions1.guard)}
              path={routes.preConditions1.path}
              component={PreConditions1Page}
              exact
            />

            <GuardedRoute
              guard={withGuardParams(routes.preConditions2.guard)}
              path={routes.preConditions2.path}
              component={PreConditions2Page}
              exact
            />

            <GuardedRoute
              guard={withGuardParams(routes.preConditions3.guard)}
              path={routes.preConditions3.path}
              component={PreConditions3Page}
              exact
            />

            <GuardedRoute
              guard={withGuardParams(routes.allergies.guard)}
              path={routes.allergies.path}
              component={AllergiesPage}
              exact
            />

            <GuardedRoute
              guard={withGuardParams(routes.wishes.guard)}
              path={routes.wishes.path}
              component={Wishes1Page}
              exact
            />

            <GuardedRoute
              guard={withGuardParams(routes.wishes2.guard)}
              path={routes.wishes2.path}
              component={Wishes2Page}
              exact
            />

            <GuardedRoute
              guard={withGuardParams(routes.wishes3.guard)}
              path={routes.wishes3.path}
              component={Wishes3Page}
              exact
            />

            <GuardedRoute
              guard={withGuardParams(routes.wishes4.guard)}
              path={routes.wishes4.path}
              component={Wishes4Page}
              exact
            />

            <GuardedRoute
              guard={withGuardParams(routes.referral.guard)}
              path={routes.referral.path}
              component={ReferralPage}
              exact
            />

            <GuardedRoute
              guard={withGuardParams(routes.consents.guard)}
              path={routes.consents.path}
              component={ConsentsPage}
              exact
            />

            <GuardedRoute
              guard={withGuardParams(routes.summary.guard)}
              backGuard={withGuardParams(preventGoBackAfterFactoringGuard(routes.summary.path))}
              path={routes.summary.path}
              component={SummaryPage}
              exact
            />

            <GuardedRoute
              guard={withGuardParams(routes.success.guard)}
              path={routes.success.path}
              component={SuccessPage}
              exact
            />

            <GuardedRoute
              guard={withGuardParams(routes.factoringAgreement.guard)}
              backGuard={withGuardParams(preventGoBackAfterSuccessGuard(routes.factoringAgreement.path))}
              path={routes.factoringAgreement.path}
              component={FactoringAgreementPage}
              exact
            />
          </Switch>
        </AppShell>
      </Route>
    </Switch>
  )
}

export default Routes
