import { DiseaseGroupField, usePreConditionsLogic } from '@dentalux/ui-library-core'
import DiseaseType from '../../../../@types/DiseaseType'
import { useField } from 'formik'
import { Disease, Form } from '@dentalux/ui-library-core'

type Props = {
  diseaseType: DiseaseType
  diseases: Disease[]
  initialValues: Partial<Form>
}

const DiseaseItem: React.FC<Props> = ({ diseaseType, diseases, initialValues }) => {
  const fieldHelpers = useField('diseases')[2]

  const { errors, collapseStates, selectedDiseases, handleToggleChange, handleDiseaseCheck, handleDiseaseChange } =
    usePreConditionsLogic({
      initialValues,
      diseases,
      onChange: (diseases) => {
        fieldHelpers.setValue(diseases)
      },
    })

  return (
    <DiseaseGroupField
      key={diseaseType}
      type={diseaseType}
      diseases={diseases}
      selectedDiseases={selectedDiseases}
      allDiseases={diseases}
      error={errors[diseaseType]}
      checked={Boolean(collapseStates[diseaseType])}
      onToggle={handleToggleChange}
      onDiseaseCheck={handleDiseaseCheck}
      onDiseaseChange={handleDiseaseChange}
      hideMedications={diseaseType === DiseaseType.SURGERY}
    />
  )
}

export default DiseaseItem
