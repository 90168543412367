import { RouteChildrenProps } from 'react-router-dom'

import usePage from '../hooks/usePage'
import PageLayout from 'components/PageLayout'
import { PreConditionsForm1, diseasesForPage1, usePreConditionsLogic } from '@dentalux/ui-library-core'
import { useTranslation } from 'react-i18next'
import useSaveOnBack from 'hooks/useSaveOnBack'

const PreConditionsPage: React.FC<RouteChildrenProps> = () => {
  const { initialValues = {}, onBack, ...page } = usePage()

  const { t } = useTranslation()

  const logic = usePreConditionsLogic({
    initialValues,
    diseases: diseasesForPage1,
  })

  const handleBack = () => onBack?.({ diseases: logic.selectedDiseases })

  const formikRef = useSaveOnBack({ onBack: handleBack })

  return (
    <PageLayout title={t('category.anamnesis')} subtitle={t('subcategory.pre_existing_conditions')}>
      <PreConditionsForm1 innerRef={formikRef} loading={false} initialValues={initialValues} logic={logic} {...page} />
    </PageLayout>
  )
}

export default PreConditionsPage
