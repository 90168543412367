import { styled } from '@mui/material/styles'
import MuiGrid from '@mui/material/Grid'

import { printableMediaQuery } from '../../../../helpers/mediaQuery'

export const Root = styled(MuiGrid)(({ theme }) => ({
  '&.MuiGrid-container': {
    margin: 0,
    marginBottom: theme.spacing(4),
    border: 0,

    [printableMediaQuery]: {
      display: 'block',

      '&::after': {
        content: "''",
        display: 'block',
        clear: 'both',
      },

      '& .MuiGrid-grid-xs-12, .MuiGrid-grid-xs-6': {
        width: '100%',
        display: 'inline-block',
        boxSizing: 'border-box',

        '&.MuiGrid-grid-md-3': {
          width: '25%',
        },

        '&.MuiGrid-grid-md-4': {
          width: '33.333333%',
        },

        '&.MuiGrid-grid-md-5': {
          width: '41.666667%',
        },

        '&.MuiGrid-grid-md-6': {
          width: '50%',
        },
      },
    },
  },

  '& .MuiGrid-item': {
    padding: 0,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D0D4D8',
    marginLeft: -1,
    marginBottom: -1,

    pageBreakInside: 'avoid',
  },
}))
