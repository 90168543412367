import DiseaseType from '../@types/DiseaseType'
import DetailedDiff from '../@types/DetailedDiff'
import diffArrays from 'helpers/diffArray'

type Disease = {
  type: DiseaseType
  name: string
  medications?: string[]
  custom?: boolean
}

export type Type = Disease

export const getKey = (disease: Disease) => `${disease.type}-${disease.name}`

export const getDiff = (original: Disease[], current: Disease[]): DetailedDiff => diffArrays(original, current, getKey)
