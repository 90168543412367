import { useEffect, useRef, useState } from 'react'
import * as S from './CodeInput.styles'

export type CodeInputProps = {
  defaultValue?: string
  onComplete?: (value: string) => void
  onChange?: (value: string) => void
  disabled: boolean
}

const getInitialValues = (defaultValue: string | undefined) => {
  if (!defaultValue) return ['', '', '', '']

  return defaultValue.padEnd(4, ' ').split('')
}

const CodeInput: React.FC<CodeInputProps> = ({ defaultValue, onComplete, onChange, disabled }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [values, setValues] = useState(getInitialValues(defaultValue))

  const updateValues = (newValues: string[]) => {
    const isComplete = !newValues.some((value) => !value)

    onChange?.(newValues.map((value) => (value === '' ? ' ' : value)).join(''))
    setValues(newValues)

    if (isComplete) {
      onComplete?.(newValues.join(''))
    }
  }

  const createHandleKeyDown = (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (index > values.length - 1) return

    const key = event.key

    // Focus on the previous input when the user presses backspace on an empty field
    if (key === 'Backspace') {
      const newValues = values.map((value, valueIndex) => (valueIndex === index ? '' : value))

      if (!values[index] && index !== 0) {
        const previousInput = event.currentTarget.previousElementSibling as HTMLInputElement

        if (previousInput) {
          previousInput.focus()
        }
      }

      updateValues(newValues)

      return
    }

    if (isNaN(parseInt(key))) return

    const newValues = values.map((value, valueIndex) => (index === valueIndex ? key : value))

    if (newValues[index] !== '') {
      const nextInput = event.currentTarget.nextElementSibling as HTMLInputElement
      nextInput?.focus()
    }

    updateValues(newValues)
  }

  const handleFocus = ({ currentTarget }: React.FocusEvent<HTMLInputElement>) => {
    currentTarget.select()
  }

  const createHandleChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value || ''

    if (value === values[index] && value.length === 1) return

    if (value.length === 4 && !isNaN(parseInt(value))) {
      updateValues(value.split(''))

      const inputs = containerRef.current?.querySelectorAll('input') || []
      const lastInput = inputs[inputs.length - 1]

      lastInput?.focus()
    }
  }

  useEffect(() => {
    if (containerRef.current) {
      const firstInput: HTMLInputElement | undefined = containerRef.current.querySelectorAll('input')[0]

      firstInput?.focus()
    }
  }, [])

  return (
    <S.Container ref={containerRef}>
      {values.map((value, index) => (
        <S.Input
          disabled={disabled}
          value={value}
          key={index}
          data-index={index}
          onKeyDown={createHandleKeyDown(index)}
          onFocus={handleFocus}
          onChange={createHandleChange(index)}
          type="tel"
        />
      ))}
    </S.Container>
  )
}

export default CodeInput
