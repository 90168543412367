import { useCallback } from 'react'

import { CardContent } from '@mui/material'

import SummaryGeneralInformation from '../GeneralInformation'
import SummaryMedication from '../Medication/Medication'
import SummaryPreConditions from '../PreConditions'
import SummaryAllergies from '../Allergies'
import SummaryWishes from '../Wishes'
import { Optins, Form, FieldSettings } from '@dentalux/ui-library-core'

import * as S from './Content.styles'
import { SummaryOptins } from '../Optins/Optins'

type Props = {
  initialValues?: Partial<Form>
  optins?: Optins
}

const Content = ({ optins, initialValues = {} }: Props) => {
  const getEditableFieldProps = useCallback(
    (settings: FieldSettings) => ({
      label: settings.label.replace('field.', 'summary.'),
      value: initialValues[settings.name as keyof Form],
      isRequired: settings.isRequired as boolean,
    }),
    [initialValues]
  )

  return (
    <S.Root>
      <CardContent>
        <S.GridContainer container spacing={3}>
          <SummaryGeneralInformation getEditableFieldProps={getEditableFieldProps} />

          <SummaryPreConditions values={initialValues} />

          <SummaryMedication values={initialValues} />

          <SummaryAllergies values={initialValues} getEditableFieldProps={getEditableFieldProps} />

          <SummaryWishes values={initialValues} getEditableFieldProps={getEditableFieldProps} />

          <SummaryOptins getEditableFieldProps={getEditableFieldProps} optins={optins} />
        </S.GridContainer>
      </CardContent>
    </S.Root>
  )
}

export default Content
