import MuiStepLabel from './overrides/MuiStepLabel'
import MuiStepIcon from './overrides/MuiStepIcon'
import MuiStepper from './overrides/MuiStepper'
import MuiTypography from './overrides/MuiTypography'
import MuiFormHelperText from './overrides/MuiFormHelperText'
import MuiStep from './overrides/MuiStep'
import MuiCssBaseline from './overrides/MuiCssBaseline'
import MuiAutocomplete from './overrides/MuiAutocomplete'
import MuiFormLabel from './overrides/MuiFormLabel'
import MuiInputBase from './overrides/MuiInputBase'

import { MuiAlert, MuiButton, shadows, typography, MuiSwitch, colors, createTheme } from '@dentalux/ui-library-core'

import { ColorConfig } from '../@types/ColorConfig'

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      grey: ColorConfig
      error: ColorConfig
      aqua: ColorConfig
    }
  }
  interface PaletteOptions {
    custom: {
      grey: ColorConfig
      error: ColorConfig
      aqua: ColorConfig
    }
  }
}

const theme = createTheme({
  typography,
  shadows,
  components: {
    MuiTypography,
    MuiButton,
    MuiSwitch,
    MuiStep,
    MuiCssBaseline,
    MuiStepIcon,
    MuiStepper,
    MuiStepLabel,
    MuiFormHelperText,
    MuiAutocomplete,
    MuiInputBase,
    MuiFormLabel,
    MuiAlert,
  },
  palette: {
    mode: 'light',
    background: {
      default: colors.grey[100],
    },
    primary: {
      main: colors.aqua[800],
    },
    custom: {
      grey: {
        ...colors.grey,
      },
      error: {
        ...colors.error,
      },
      aqua: {
        ...colors.aqua,
      },
    },
  },
})

export default theme
