import { Form } from '@dentalux/ui-library-core'

const ensureConditionalFieldValues = (values: Partial<Form>): Partial<Form> => {
  const clone = { ...values }

  if (!values.isPregnant) delete clone.pregnantMonths
  if (!values.isSmoker) delete clone.cigarettesPerDay

  if (values.type !== 'PRIVATE_INSURANCE') {
    delete clone.insuranceCompanyId
  }

  if (values.type === 'PRIVATE_INSURANCE') {
    delete clone.dentalInsuranceCompany
    delete clone.dentalInsuranceCompanyId
    clone.hasDentalInsurance = false
  }

  if (!values.hasDentalInsurance) {
    delete clone.dentalInsuranceCompany
    delete clone.dentalInsuranceCompanyId
  }

  if (!values.hasFamilyInsurance) {
    delete clone.holderCity
    delete clone.holderStreet
    delete clone.holderZipcode
    delete clone.holderFirstName
    delete clone.holderLastName
    delete clone.holderBirthday
  }

  return clone
}

export default ensureConditionalFieldValues
