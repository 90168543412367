import { useEffect } from 'react'

import { setContainerWidth } from 'helpers/setContainerWidth'
import * as S from '../shared/PageLayout'
import useBeforeUnload from 'hooks/useBeforeUnload'

export type PageLayoutProps = {
  title?: string
  subtitle?: string
  className?: string
  unsavedChangesDialog?: boolean
}

const PageLayout: React.FC<PageLayoutProps> = ({
  title,
  subtitle,
  className,
  children,
  unsavedChangesDialog = true,
}) => {
  useEffect(() => {
    setContainerWidth()
  }, [])

  useBeforeUnload({ handler: (event) => event.preventDefault(), disabled: !unsavedChangesDialog })

  const hasTitle = Boolean(title)
  const hasSubtitle = Boolean(subtitle)

  return (
    <S.Container className={className}>
      {(hasTitle || hasSubtitle) && (
        <S.Header>
          {hasTitle && <S.Title>{title}</S.Title>}

          {hasSubtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
        </S.Header>
      )}

      <S.Body>{children}</S.Body>
    </S.Container>
  )
}

export default PageLayout
