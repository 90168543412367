import { useEffect, useMemo } from 'react'
import { Route, RouteProps, useHistory, useLocation } from 'react-router-dom'
import { canSkipGuard, canSkipBackGuard } from '../../config/routes'

type Props = RouteProps & {
  guard: () => { isValid: boolean; redirectTo?: string; path?: string }
  backGuard?: () => { isValid: boolean; redirectTo?: string; path?: string }
}

const GuardedRoute: React.FC<Props> = ({ guard, backGuard, ...props }) => {
  const history = useHistory()
  const location = useLocation()

  const { isValid, redirectTo, path } = useMemo(guard, [guard])

  useEffect(() => {
    const backGuardResult = backGuard?.()

    if (canSkipGuard) return

    /* Will be truthy when the current can't be accessed due to being
     * a route that can't come back after a certain path.
     *
     * Some examples:
     *
     * - Summary page can't be accessed again using the back button once the
     * factoring agreement page is displayed.
     *
     * - Factoring agreement page can't be accessed again using the back button once the
     * success page is displayed.
     */
    if (backGuardResult?.isValid === false && backGuardResult.redirectTo) {
      if (canSkipBackGuard) return

      return history.push(backGuardResult.redirectTo)
    }

    if (!isValid && redirectTo) return history.push(redirectTo)
  }, [isValid, redirectTo, history, location, path, backGuard])

  if (!isValid && location.pathname !== path && !canSkipGuard) return null

  return <Route {...props} />
}

export default GuardedRoute
