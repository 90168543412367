import { LinearProgress, Theme } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled('div')({
  position: 'relative',
})

export const Progress = styled(LinearProgress)(({ theme }: { theme: Theme }) => ({
  height: 2,
  width: '100%',
  position: 'absolute',
  top: 'calc(50% - 1px)',
  backgroundColor: theme.palette.custom.grey[400],

  '.MuiLinearProgress-bar': {
    backgroundColor: theme.palette.custom.aqua[800],
  },
}))

Progress.defaultProps = {
  variant: 'determinate',
}
