import * as Disease from 'entities/Disease'
import { useTranslation } from 'react-i18next'
import DiffArrayValue from '../../../DiffArrayValue'
import DetailedDiff from '../../../../@types/DetailedDiff'

export type DiseaseItemProps = {
  diseases: Disease.Type[]
  showMedications?: boolean
  diff?: DetailedDiff
}

const DiseaseItemValue: React.FC<DiseaseItemProps> = ({ diseases, diff = {}, showMedications }) => {
  const { t } = useTranslation()

  const values = diseases.map((disease) => {
    const diseaseName = t(`option.${disease.name}`, { defaultValue: disease.name })
    const medications =
      showMedications && disease.medications && disease.medications.length > 0
        ? ` (${disease.medications.join(', ')})`
        : ''

    return {
      key: Disease.getKey(disease),
      label: diseaseName + medications,
    }
  })

  return <DiffArrayValue prefix={diseases.length > 0 ? 'yes' : 'no'} values={values} diff={diff} />
}

export default DiseaseItemValue
