import React, { useState, createContext, useContext } from 'react'

interface AnalyticsContextData {
  startedAt: Date | null
  setStartedAt: React.Dispatch<React.SetStateAction<Date | null>>
}

export const AnalyticsContext = createContext<AnalyticsContextData>({
  startedAt: null,
  setStartedAt: () => {},
})

const AnalyticsProvider: React.FC = ({ children }) => {
  const [startedAt, setStartedAt] = useState<Date | null>(null)

  return <AnalyticsContext.Provider value={{ startedAt, setStartedAt }}>{children}</AnalyticsContext.Provider>
}

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext)

  if (context === undefined) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider')
  }

  return context
}

export default AnalyticsProvider
