import _keyBy from 'lodash/keyBy'
import _mapValues from 'lodash/mapValues'
import _difference from 'lodash/fp/difference'
import _keys from 'lodash/keys'
import _map from 'lodash/fp/map'
import _flow from 'lodash/flow'
import _identity from 'lodash/identity'

import DetailedDiff from '../@types/DetailedDiff'
import * as DiffType from 'entities/DiffType'

const diffArrays = <T>(original: T[], current: T[], getUniqueKey: (value: T) => string = _identity): DetailedDiff => {
  const originalByKey = _keyBy(original, getUniqueKey)
  const currentByKey = _keyBy(current, getUniqueKey)

  const changesFromOriginal = _mapValues(originalByKey, (value, key) => DiffType.getType(value, currentByKey[key]))

  const added = _flow(
    _difference(_keys(currentByKey)),
    _map((key) => [key, DiffType.Type.ADDED]),
    Object.fromEntries
  )(_keys(originalByKey))

  return {
    ...changesFromOriginal,
    ...added,
  }
}

export default diffArrays
