import { useEffect, useRef } from 'react'

type BeforeUnloadHandler = (event: BeforeUnloadEvent) => string | void

const useBeforeunload = ({ handler, disabled = false }: { handler: BeforeUnloadHandler; disabled?: boolean }): void => {
  const eventListenerRef = useRef<BeforeUnloadHandler | null>(null)

  useEffect(() => {
    if (disabled) return

    eventListenerRef.current = (event: BeforeUnloadEvent) => {
      const returnValue = handler?.(event)

      // Handle legacy `event.returnValue` property
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      if (typeof returnValue === 'string') {
        return (event.returnValue = returnValue)
      }

      // Chrome doesn't support `event.preventDefault()` on `BeforeUnloadEvent`,
      // instead it requires `event.returnValue` to be set
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#browser_compatibility
      if (event.defaultPrevented) {
        return (event.returnValue = '')
      }
    }
  }, [handler, disabled])

  useEffect(() => {
    if (disabled) return

    const eventListener = (event: BeforeUnloadEvent) => eventListenerRef.current?.(event)

    window.addEventListener('beforeunload', eventListener)
    return () => {
      window.removeEventListener('beforeunload', eventListener)
    }
  }, [disabled])
}

export default useBeforeunload
