import * as Sentry from '@sentry/react'
import ServerError from '../@types/ServerError'
import { AxiosError } from 'axios'
import { Clinic } from '@dentalux/ui-library-core'

export const FACTORING_PDF_LOAD_ERROR = 'FACTORING_PDF_LOAD_ERROR'
export const POST_ANAMNESIS_ERROR = 'POST_ANAMNESIS_ERROR'
export const POST_LEGAL_DOCUMENTS_ERROR = 'POST_LEGAL_DOCUMENTS_ERROR'
export const SIGN_FACTORING_AGREEMENT_ERROR = 'SIGN_FACTORING_AGREEMENT_ERROR'
export const FACTORING_AGREEMENT_NOT_FOUND_ERROR = 'FACTORING_AGREEMENT_NOT_FOUND_ERROR'
export const GET_FACTORING_AGREEMENT_ERROR = 'GET_FACTORING_AGREEMENT_ERROR'
export const LEGAL_DOCUMENTS_MISSING_DATA_ERROR = 'LEGAL_DOCUMENTS_MISSING_DATA_ERROR'

const init = (): void => {
  if (process.env.REACT_APP_SENTRY_DSN && process.env.NODE_ENV !== 'development') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.01 : 0,
      replaysOnErrorSampleRate: 1.0,
      environment: process.env.REACT_APP_ENV,
      ignoreErrors: ['Non-Error promise rejection captured', 'Request failed with status code 410'],
    })
  }
}

type CaptureEnrichedErrorParams = {
  error: AxiosError<ServerError>
  clinic?: Clinic
  code?: string
  extra?: Record<string, unknown>
}

const createEnrichAndCaptureError =
  (message: string) =>
  ({ error, clinic, code, extra }: CaptureEnrichedErrorParams) => {
    const isOnline = navigator.onLine
    const captureTime = new Date()

    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection
    const connectionType = connection?.effectiveType ?? 'Unknown'

    const traceId = error.response?.headers['x-b3-traceid']

    const captureError = () => {
      if (code === 'ANA_212_INVALID_CODE') return

      Sentry.withScope((scope) => {
        scope.setLevel('error')
        scope.setTag('clinicId', clinic?.referenceId ?? 'Unknown')

        scope.setExtras({
          traceId: traceId || 'Unknown',
          name: error.name,
          message: error.message,
          clinicName: clinic?.officialName ?? 'Unknown',
          isOnline: navigator.onLine ?? 'Unknown',
          connectionType,
          errorData: JSON.stringify(error.response?.data),
          ...extra,
        })

        code && scope.setExtra('codeType', code.length === 4 ? 'SHORT' : 'LONG')
        !isOnline && scope.setExtra('captureTime', captureTime)

        Sentry.captureMessage(`${message}: ${error.message}`)
      })
    }

    if (isOnline) return captureError()

    // Send the error once the device is back online.
    window.addEventListener('online', captureError, { once: true })
  }

const captureAnamnesisError = createEnrichAndCaptureError(POST_ANAMNESIS_ERROR)
const captureFactoringPDFLoadError = createEnrichAndCaptureError(FACTORING_PDF_LOAD_ERROR)
const captureLegalDocumentsError = createEnrichAndCaptureError(POST_LEGAL_DOCUMENTS_ERROR)
const captureSignFactoringError = createEnrichAndCaptureError(SIGN_FACTORING_AGREEMENT_ERROR)
const captureFactoringAgreementNotFoundError = createEnrichAndCaptureError(FACTORING_AGREEMENT_NOT_FOUND_ERROR)
const captureGetFactoringAgreementError = createEnrichAndCaptureError(GET_FACTORING_AGREEMENT_ERROR)
const captureLegalDocumentsMissingDataError = createEnrichAndCaptureError(LEGAL_DOCUMENTS_MISSING_DATA_ERROR)
const captureInvalidSubmittedFormError = (errors: unknown) => {
  Sentry.withScope((scope) => {
    scope.setLevel('debug')
    scope.setExtras(errors as Record<string, unknown>)

    Sentry.captureMessage('The form was sent but some fields were invalid.')
  })
}

const sentryService = {
  init,
  captureAnamnesisError,
  captureFactoringPDFLoadError,
  captureLegalDocumentsError,
  captureSignFactoringError,
  captureFactoringAgreementNotFoundError,
  captureGetFactoringAgreementError,
  captureLegalDocumentsMissingDataError,
  captureInvalidSubmittedFormError,
}

export default sentryService
