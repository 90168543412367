import * as api from 'services/api/ana'
import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query'
import ServerError from '../@types/ServerError'
import { AxiosError } from 'axios'
import { Anamnesis } from '../@types/Anamnesis'

type UseVersionParams = {
  anamnesisReferenceId: string
  version: string | number | null
}

type ErrorType = AxiosError<ServerError>

type UseVersionReturn = [Anamnesis | undefined, Omit<QueryObserverResult<Anamnesis, ErrorType>, 'data'>]

const useVersion = (
  { anamnesisReferenceId, version }: UseVersionParams,
  config: Omit<UseQueryOptions<Anamnesis, ErrorType>, 'queryFn' | 'queryKey'> = {}
): UseVersionReturn => {
  const { data, ...rest } = useQuery<Anamnesis, ErrorType>(
    ['version', anamnesisReferenceId, version],
    () => api.getAnamnesisByVersion(anamnesisReferenceId, version || ''),
    {
      enabled: Boolean(anamnesisReferenceId) && Boolean(version),
      ...config,
    }
  )

  return [data, rest]
}

export default useVersion
