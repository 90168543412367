import composeHooks from '../../helpers/composeHooks'
import Stepper, { StepperProps } from './Stepper'
import useStepper from './Stepper.hooks'

export type { StepperProps } from './Stepper'

type StepperContainerProps = {
  className?: string
}

export default composeHooks<StepperContainerProps, StepperProps>({ useStepper })(Stepper)
