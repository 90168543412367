import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import getAssetUrl from 'helpers/getAssetUrl'

export const LanguageWrapper = styled(Box)({
  cursor: 'pointer',
  display: 'flex',
})

export const Flag = styled('div')<{ country?: string }>(({ theme, country }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  flexBasis: theme.spacing(3),
  minWidth: theme.spacing(3),
  backgroundImage: `url(${getAssetUrl(`/static/flags/${country}.svg`)})`,
  border: `1px solid rgb(255, 255, 255)`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '50%',
}))
