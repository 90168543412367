export type Type = { roles: { role: { name: string } }[] }

const rolesAllowedToEditAnamnesis: Record<string, boolean> = {
  DENTIST: true,
  SENIOR_DENTIST: true,
  DENTIST_CHIEF: true,
  ZMP: true,
  HQ_ADMIN: true,
  TENANT_ADMIN: true,
}

export const canEditAnamnesis = (user: Type) => {
  return user.roles.find((r) => rolesAllowedToEditAnamnesis[r.role.name || '']) ?? false
}
