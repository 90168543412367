import { Button, Translate } from '@dentalux/ui-library-core'
import ErrorIcon from '@mui/icons-material/Error'
import * as S from './GenericError.styles'
import { ReactNode } from 'react'

type GenericErrorProps = {
  title: string
  description?: string
  icon?: ReactNode
  actionText?: ReactNode
  onActionClick?: () => void
}

const GenericError = ({ title, description, actionText, icon, onActionClick }: GenericErrorProps) => {
  return (
    <S.Container>
      <S.IconWrapper>{icon || <ErrorIcon />}</S.IconWrapper>

      <S.Title>
        <Translate children={title} />
      </S.Title>

      {description && (
        <S.Description>
          <Translate children={description} />
        </S.Description>
      )}

      {actionText && onActionClick && (
        <Button onClick={onActionClick}>
          <Translate>{actionText}</Translate>
        </Button>
      )}
    </S.Container>
  )
}

export default GenericError
