import { styled } from '@mui/material/styles'
import { Typography as MuiTypography } from '@mui/material'

export const Title = styled(MuiTypography)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(3),

  '&:first-of-type': {
    marginTop: 0,
  },
}))

Title.defaultProps = { variant: 'Sarabun H6' }
