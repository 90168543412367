import { SvgIcon, SvgIconProps } from '@mui/material'

const D21Logo = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 56 24" fill="none" sx={{ width: 56, height: 24 }}>
      <path
        d="M35.7743 0C40.5037 0 43.7875 2.8832 43.7875 7.27451C43.7875 10.425 41.9807 12.9391 38.3356 14.7834L34.7888 16.593C33.4752 17.2972 31.899 18.4372 31.899 19.8787H43.7875V23.7335H27.5969V20.4816C27.5969 16.6935 30.7166 14.2144 32.9175 13.1068L36.5965 11.2296C38.4679 10.291 39.4204 8.95058 39.4204 7.24132C39.4204 5.06139 38.0085 3.65484 35.7753 3.65484C33.3118 3.65484 31.8999 5.56575 31.9652 8.41452H27.5969C27.4979 3.21812 30.7496 0 35.7743 0Z"
        fill="#00624F"
      />
      <path
        d="M55.8136 3.32048V0.25H52.0341V1.59189C52.0337 2.28126 51.7652 2.94228 51.2876 3.42971C50.8101 3.91715 50.1625 4.19113 49.4871 4.19146H46.939V7.84927H51.3757C51.4089 7.84927 51.441 7.84481 51.4742 7.84407V23.7313H55.8124V3.31924L55.8136 3.32048Z"
        fill="#00624F"
      />
      <path
        d="M4.85728 0C6.14551 0 7.38098 0.522369 8.2919 1.45219C9.20281 2.38202 9.71456 3.64313 9.71456 4.95809V9.91619H4.85728C3.56905 9.91619 2.33358 9.39382 1.42266 8.464C0.511748 7.53417 0 6.27306 0 4.95809H0C0 3.64313 0.511748 2.38202 1.42266 1.45219C2.33358 0.522369 3.56905 0 4.85728 0V0Z"
        fill="#00624F"
      />
      <path
        d="M0 19.0419C0 17.7269 0.511749 16.4658 1.42267 15.536C2.33358 14.6062 3.56905 14.0838 4.85728 14.0838H9.71456V19.0419C9.71456 20.3569 9.20281 21.618 8.29189 22.5478C7.38098 23.4776 6.14551 24 4.85728 24V24C3.56905 24 2.33358 23.4776 1.42267 22.5478C0.511749 21.618 0 20.3569 0 19.0419V19.0419Z"
        fill="#00624F"
      />
      <path
        d="M18.6554 24C17.3672 24 16.1317 23.4776 15.2208 22.5478C14.3099 21.618 13.7981 20.3569 13.7981 19.0419L13.7981 14.0838L18.6554 14.0838C19.9436 14.0838 21.1791 14.6062 22.09 15.536C23.0009 16.4658 23.5127 17.7269 23.5127 19.0419V19.0419C23.5127 20.3569 23.0009 21.618 22.09 22.5478C21.1791 23.4776 19.9436 24 18.6554 24V24Z"
        fill="#00624F"
      />
      <path
        d="M23.5127 4.95809C23.5127 6.27306 23.0009 7.53417 22.09 8.464C21.1791 9.39382 19.9436 9.91619 18.6554 9.91619H13.7981V4.95809C13.7981 3.64313 14.3099 2.38202 15.2208 1.45219C16.1317 0.52237 17.3672 0 18.6554 0V0C19.9436 0 21.1791 0.52237 22.09 1.45219C23.0009 2.38202 23.5127 3.64313 23.5127 4.95809V4.95809Z"
        fill="#00624F"
      />
    </SvgIcon>
  )
}

export default D21Logo
