import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100vh',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  progress: {},
}))
