import { Theme } from '@mui/material'
import { styled } from '@mui/material/styles'

const getColorForChangeType = ({
  changeType,
  highlight,
  theme,
}: {
  changeType?: string
  highlight?: boolean
  theme: Theme
}) => {
  const colorsByType: Record<string, string> = {
    ADDED: '#219653',
    CHANGED: '#219653',
    UNCHANGED: theme.palette.custom.grey[800],
  }

  if (highlight) return colorsByType.CHANGED

  return colorsByType[changeType || ''] || 'inherit'
}

export const DiseaseItem = styled('span')<{ changeType?: string; highlight?: boolean }>((props) => ({
  color: getColorForChangeType(props),
}))

export const Container = styled('div')<{ highlight?: boolean }>(({ highlight, theme }) => ({
  color: highlight ? '#219653' : 'inherit',
}))
