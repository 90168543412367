import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import Hidden from '@mui/material/Hidden'

import menuItems, { MenuItem } from '../../config/sideMenu'

import * as S from './SideNav.styles'
import * as StyledList from '../shared/ListItem'
import { createMenuItem } from '../../factory/menuItem'
import { Typography } from '@mui/material'
import { languageCodeToCountryCodeFallback } from 'helpers/languageCodeToCountryCodeFallback'
import { Flag } from '../LanguageSwitch/LanguageSwitch.styles'
import { Translate } from '@dentalux/ui-library-core'

type StepProgress = {
  percentage: number
  completed: boolean
}

type MenuItemIdentifier = string

export type SideNavProps = {
  currentStep?: string
  progresses?: Record<MenuItemIdentifier, StepProgress>
  visitedSteps?: string[]
  className?: string
  isInClinic?: boolean
  open?: boolean
  onLanguageChange?: (language: string) => void
  onStepClick?: (menuItem: MenuItem) => void
  onClose: () => void
  onReset?: () => void
}

const SideNav: React.FC<SideNavProps> = ({
  currentStep,
  progresses = {},
  className,
  visitedSteps = [],
  open,
  isInClinic,
  onStepClick,
  onLanguageChange,
  onClose,
  onReset,
}) => {
  const { t, i18n } = useTranslation()
  const handleMenuItemClick = (menuItem: MenuItem) => () => onStepClick?.(menuItem)

  const handleLanguageChange = (newLanguage: 'en' | 'de') => () => {
    i18n.changeLanguage(newLanguage)
    localStorage.setItem('language', newLanguage)

    onLanguageChange?.(newLanguage)
  }

  const renderStepItem = (menuItem: MenuItem) => {
    const menuItemHelpers = createMenuItem(menuItem)
    const stepProgress = progresses[menuItem.label]

    return (
      <StyledList.ListItem
        onClick={handleMenuItemClick(menuItem)}
        selected={currentStep ? menuItemHelpers.isSelected?.(currentStep) : false}
        key={menuItem.path}
        completed={stepProgress?.completed}
        disabled={!menuItemHelpers.isSomeStepVisited(visitedSteps)}
      >
        <S.ListItemIcon>
          <S.StepIconWrapper>{menuItem.icon}</S.StepIconWrapper>
        </S.ListItemIcon>

        <StyledList.ListItemText
          primary={
            <S.ListItemTextContainer>
              {t(menuItem.label)}

              {stepProgress && (
                <S.ListItemProgressContainer completed={stepProgress.completed}>
                  {stepProgress.completed && <S.CheckIcon aria-label="Checkmark" />}

                  <Hidden mdUp>
                    {!stepProgress.completed && <S.ListItemCompleted>Completed</S.ListItemCompleted>}

                    {!stepProgress.completed && (
                      <S.ListItemProgress>{stepProgress.percentage.toFixed()}%</S.ListItemProgress>
                    )}
                  </Hidden>
                </S.ListItemProgressContainer>
              )}
            </S.ListItemTextContainer>
          }
        />
      </StyledList.ListItem>
    )
  }

  const renderLanguageItem = (displayValue: string, languageCode: 'en' | 'de') => {
    const isSelected = i18n.language === languageCode

    const countryCode = languageCodeToCountryCodeFallback.get(languageCode) || languageCode

    return (
      <StyledList.ListItem
        key={languageCode}
        selected={isSelected}
        onClick={handleLanguageChange(languageCode)}
        aria-current={isSelected}
      >
        <S.ListItemIcon>
          <Flag country={countryCode} />
        </S.ListItemIcon>

        <StyledList.ListItemText primary={<Typography>{displayValue}</Typography>} />

        {isSelected && (
          <S.ListItemSecondaryAction>
            <S.CheckIcon />
          </S.ListItemSecondaryAction>
        )}
      </StyledList.ListItem>
    )
  }

  return (
    <S.Container variant="persistent" open={open} className={className} onClose={onClose}>
      <S.TopContainer>
        <S.CloseContainer>
          <S.CloseButton onClick={onClose} aria-label="Close sidenav">
            <CloseIcon />
          </S.CloseButton>
        </S.CloseContainer>

        <S.List disablePadding>{menuItems.map(renderStepItem)}</S.List>

        <S.List disablePadding>
          {renderLanguageItem('Deutsch', 'de')}
          {renderLanguageItem('English', 'en')}
        </S.List>
      </S.TopContainer>

      <S.BottomContainer>
        <S.List disablePadding>
          {isInClinic && onReset && (
            <StyledList.ListItem onClick={onReset}>
              <S.ListItemIcon>
                <RefreshIcon />
              </S.ListItemIcon>

              <StyledList.ListItemText
                primary={
                  <Typography>
                    <Translate children="reset" />
                  </Typography>
                }
              />
            </StyledList.ListItem>
          )}
        </S.List>
      </S.BottomContainer>
    </S.Container>
  )
}

export default SideNav
