import { styled } from '@mui/material/styles'

export const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
}))

export const Input = styled('input')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: 'none',
  width: 66,
  height: 66,
  margin: 6,
  borderRadius: theme.spacing(1),
  boxShadow: '0px 1px 3px rgba(0, 71, 52, 0.2)',
  // Remove arrows on Firefox
  appearance: 'textfield',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.custom.aqua[600],
  fontSize: theme.spacing(3),
  lineHeight: theme.spacing(4),
  textAlign: 'center',

  // Remove arrows on Chrome, Safari, Edge, and Opera
  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    appearance: 'none',
    margin: 0,
  },

  '&:focus': {
    background:
      'linear-gradient(143.98deg, rgba(13, 173, 218, 0.1) -4.1%, rgba(7, 164, 208, 0.1) 51.44%, rgba(1, 144, 184, 0.1) 103.06%)',
    outline: 'none',
  },
  '&:disabled': {
    background: 'lightgray',
    outline: 'none',
    borderColor: 'gray',
  },
}))

Input.defaultProps = {
  type: 'number',
}
