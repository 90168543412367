import qs from 'querystring'
import { useCallback } from 'react'
import { useSetAnswers } from '../providers/AnswersProvider'
import { usePatient } from '../providers/PatientProvider'
import ServerError from '../@types/ServerError'
import AxiosErrorSummary from '../@types/AxiosErrorSummary'
import CodeScreen from 'screens/Code'
import useOnMount from 'hooks/useOnMount'
import { Form } from '@dentalux/ui-library-core'

const hasCodeOnSearchParams = () => {
  const searchParamsWithoutQuestionMark = window.location.search.substr(1)
  const codeFromSearchParams = qs.parse(searchParamsWithoutQuestionMark)?.code

  return typeof codeFromSearchParams === 'string'
}

const CodePage: React.FC = () => {
  const setAnswers = useSetAnswers()
  const patient = usePatient()

  const error = patient?.error as AxiosErrorSummary<ServerError> | undefined

  const handleSubmit = useCallback(
    (code: string) => {
      setAnswers((previous: Partial<Form>) => ({ ...previous, code }))
    },
    [setAnswers]
  )

  useOnMount(() => {
    if (!error && hasCodeOnSearchParams()) return
  })

  return <CodeScreen error={error} onSubmit={handleSubmit} />
}

export default CodePage
