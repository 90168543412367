import { useCallback, useEffect } from 'react'
import { RouteChildrenProps } from 'react-router-dom'

import useNavigation from '../hooks/useNavigation'
import { useAnswers, useSetAnswers } from '../providers/AnswersProvider'
import { SHORT_CODE_LENGTH, usePatient } from '../providers/PatientProvider'
import { useAnalytics } from '../providers/AnalyticsProvider'
import IntroScreen from '../screens/Intro'
import usePage from '../hooks/usePage'
import ClinicEntity from '../entities/Clinic'
import { Form } from '@dentalux/ui-library-core'

type Props = RouteChildrenProps

const IntroPage: React.FC<Props> = () => {
  const page = usePage()
  const { goToNextStep } = useNavigation()
  const patient = usePatient()
  const analytics = useAnalytics()

  const answers = useAnswers()
  const setAnswers = useSetAnswers()

  const handleSubmit = useCallback(() => {
    page.onSubmit({})
  }, [page])

  const handleLanguageChange = (language: string) => {
    setAnswers((previous: Partial<Form>) => ({
      ...previous,
      language: language.toUpperCase(),
    }))
  }

  useEffect(
    () => {
      const stepsCompleted = patient?.data?.anamnesisState

      const isSomeStepIncomplete = stepsCompleted ? stepsCompleted.some((state) => state.status !== 'COMPLETE') : true

      if (isSomeStepIncomplete) analytics.setStartedAt(new Date())
    },
    // Disabling the lint for the missing dependency. The missing dependency is the `analytics`,
    // if we add it to the dependencies, it will create an infinite loop.
    // eslint-disable-next-line
    [patient]
  )

  useEffect(() => {
    const stepsCompleted = patient?.data?.anamnesisState

    const isSomeStepIncomplete = stepsCompleted ? stepsCompleted.some((state) => state.status !== 'COMPLETE') : true

    if (!isSomeStepIncomplete) goToNextStep()
  }, [goToNextStep, patient])

  const dataProtectionUrl = ClinicEntity.getDataProtectionUrl(patient?.data?.clinic)

  return (
    <IntroScreen
      clinicName={patient?.data?.clinic?.officialName}
      dataProtectionUrl={dataProtectionUrl}
      isInClinic={answers?.code?.length === SHORT_CODE_LENGTH}
      {...page}
      onStartForm={handleSubmit}
      onLanguageChange={handleLanguageChange}
    />
  )
}

export default IntroPage
