import * as S from './DiffArrayValue.styles'
import { useTranslation } from 'react-i18next'
import DetailedDiff from '../../@types/DetailedDiff'
import * as DiffType from 'entities/DiffType'

type DiffValue = { key: string; label: string }

export type DiffArrayValueProps = {
  values: DiffValue[]
  diff: DetailedDiff
  prefix?: string
}

const DiffArrayValue: React.FC<DiffArrayValueProps> = ({ values, diff, prefix }) => {
  const { t } = useTranslation()
  const getChangeType = (value: DiffValue) => diff?.[value.key]
  const keyPreffix = values[0]?.key.split('-')[0] || null

  const shouldHighlightEverything = keyPreffix
    ? Object.entries(diff).some(([key, value]) => key.startsWith(keyPreffix) && value === DiffType.Type.REMOVED)
    : false

  const renderItem = (value: DiffValue, index: number) => {
    const isLast = values.length - 1 === index
    const changeType = getChangeType(value)

    return (
      <S.DiseaseItem
        key={value.key}
        changeType={typeof changeType === 'string' ? changeType : undefined}
        highlight={shouldHighlightEverything}
      >
        {value.label}
        {!isLast && ', '}
      </S.DiseaseItem>
    )
  }

  return (
    <S.Container highlight={shouldHighlightEverything}>
      {prefix && t(prefix) + (values.length > 0 ? ', ' : '')}
      {values.map(renderItem)}
    </S.Container>
  )
}

export default DiffArrayValue
