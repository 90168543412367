import { typography } from '@dentalux/ui-library-core'
import { Box, styled } from '@mui/material'

export const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
}))

export const IconWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.custom.aqua[300],
  marginRight: theme.spacing(2),
  marginTop: 3,
}))

export const Text = styled(Box)({
  ...typography['Sarabun Body 2'],
})
