import { styled } from '@mui/material/styles'
import { Modal } from '@dentalux/ui-library-core'

const { ModalDialog: Dialog, DialogContent, DialogActions } = Modal

export { Dialog, DialogContent, DialogActions }

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',

  '> :first-of-type': {
    marginBottom: theme.spacing(1),
  },
}))

export const Illustration = styled('img')(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5),
}))
