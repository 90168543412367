import _isEqual from 'lodash/isEqual'

export enum Type {
  ADDED = 'ADDED',
  CHANGED = 'CHANGED',
  REMOVED = 'REMOVED',
  UNCHANGED = 'UNCHANGED',
}

export const getType = <T>(originalValue: T, newValue: T): Type => {
  if (originalValue && newValue === undefined) return Type.REMOVED
  if (_isEqual(originalValue, newValue)) return Type.UNCHANGED

  return Type.CHANGED
}

export const hasChanged = (type: unknown): boolean => {
  if (typeof type !== 'string') return false

  return type !== Type.UNCHANGED
}
