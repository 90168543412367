import { Components, Theme } from '@mui/material'

import { MuiTypography as BaseTypography } from '@dentalux/ui-library-core'

const MuiTypography: Components<Theme>['MuiTypography'] = {
  defaultProps: {
    ...BaseTypography.defaultProps,
    variant: 'Sarabun Body 1',
  },
}

export default MuiTypography
