import { useLocation } from 'react-router-dom'
import menuItems, { MenuItem } from '../../config/sideMenu'
import { useAnswers } from '../../providers/AnswersProvider'
import { useVisitedSteps } from '../../providers/VisitedStepsProvider'
import { Form } from '@dentalux/ui-library-core'

type UseStepperReturn = {
  answers: Partial<Form>
  visitedSteps: string[]
  progress: number
  activeStepIndex: number
}

const useStepper = (): UseStepperReturn => {
  const answers = useAnswers()
  const visitedSteps = useVisitedSteps()
  const { pathname } = useLocation()

  const currentPartIndex = menuItems.findIndex((item) => item.steps.includes(pathname))

  const currentPart: MenuItem | undefined = menuItems[currentPartIndex]

  const completed =
    currentPart?.steps.filter((_, index) => {
      if (!currentPart) return false

      return index < currentPart?.steps.indexOf(pathname)
    }) || []

  const partProgress = ((completed.length / currentPart?.steps.length) * 100) / 3
  const stepsProgress = (currentPartIndex / (menuItems.length - 1)) * 100

  const progress = stepsProgress + partProgress

  return {
    answers,
    visitedSteps,
    progress: progress > 100 ? 100 : progress,
    activeStepIndex: currentPartIndex,
  }
}

export default useStepper
