import { useEffect, useState } from 'react'

import * as i18n from './services/i18n'
import { SecurityProvider } from '@dentalux/security'

import { HashRouter as Router } from 'react-router-dom'

import AnswersProvider from './providers/AnswersProvider'
import VisitedStepsProvider from './providers/VisitedStepsProvider'
import PatientProvider from './providers/PatientProvider'

import Routes from './Routes'
import FactoringAgreementProvider from './providers/FactoringAgreementProvider'
import { getLegalDocuments, GetLegalDocumentsReturn, getTranslations } from './services/contentful'
import LegalDocumentsProvider from './providers/LegalDocumentsProvider'
import AnalyticsProvider from './providers/AnalyticsProvider'
import { Form } from '@dentalux/ui-library-core'

import serverMock from './services/serverMock'
import ThemeProvider from './providers/ThemeProvider'
import FullPageLoading from 'components/FullPageLoading'
import SourceProvider from './providers/SourceProvider'
import DatePickersUtilsProvider from 'providers/DatePickersUtilsProvider'
import ReactQueryConfigProvider from 'providers/ReactQueryProvider'
import NotificationMessageProvider from 'providers/NotificationMessageProvider'
import { Box } from '@mui/material'

if (process.env.REACT_APP_MOCK_API) {
  serverMock()
}

type Props = {
  /* The value to initialize the answers, should only be used to simplify the tests purposes */
  initialAnswers?: Partial<Form>
  /* The value to initialize the answers, should only be used to simplify the tests purposes */
  initialVisitedSteps?: string[]
}

const App = ({ initialAnswers, initialVisitedSteps }: Props) => {
  const [loadingTranslations, setLoadingTranslations] = useState(true)
  const [legalDocuments, setLegalDocuments] = useState<GetLegalDocumentsReturn>({ en: {}, de: {} })

  useEffect(() => {
    getTranslations('ana-frontend')
      .then(i18n.init)
      .catch((e) => {
        console.warn({ ...e })
      })
      .finally(() => {
        setLoadingTranslations(false)
      })
  }, [setLoadingTranslations])

  useEffect(() => {
    getLegalDocuments().then(setLegalDocuments)
  }, [setLegalDocuments])

  if (loadingTranslations) return <Box />

  const { origin, pathname, hash } = window.location

  return (
    <ThemeProvider>
      <ReactQueryConfigProvider>
        <DatePickersUtilsProvider>
          <NotificationMessageProvider>
            <Router>
              <SourceProvider>
                <LegalDocumentsProvider value={legalDocuments}>
                  <AnswersProvider initialValues={initialAnswers}>
                    <PatientProvider>
                      <FactoringAgreementProvider>
                        <VisitedStepsProvider initialValues={initialVisitedSteps}>
                          <AnalyticsProvider>
                            <SecurityProvider
                              config={{
                                loading: <FullPageLoading />,
                                env: process.env.REACT_APP_HADES_ENV as 'staging' | 'production',
                                redirectUri: `${origin}${pathname}${hash}`,
                                defaultLanguage: i18n.default.language as 'en' | 'de',
                                enableImpersonation: true,
                              }}
                            >
                              <Routes />
                            </SecurityProvider>
                          </AnalyticsProvider>
                        </VisitedStepsProvider>
                      </FactoringAgreementProvider>
                    </PatientProvider>
                  </AnswersProvider>
                </LegalDocumentsProvider>
              </SourceProvider>
            </Router>
          </NotificationMessageProvider>
        </DatePickersUtilsProvider>
      </ReactQueryConfigProvider>
    </ThemeProvider>
  )
}

export default App
