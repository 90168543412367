const maxGridColumns = 12

/* This function receives a parent element and returns its grid items */
export const getGridItems = (parentElement: Element): Element[] => {
  return Array.from(parentElement.querySelectorAll('.MuiGrid-item'))
}

/* This function gets the column size using the Material ui class names.
 * We prefer the medium sizes (md) because it's the one used to render the
 * PDF, but we fallback to the `xs` if `md` is not defined.
 *
 * <Grid item xs={12} md={6} /> -> 6
 * <Grid item xs={12} /> -> 12
 */
export const getElementColumnSize = (element: Element): number => {
  return Array.from(element.classList).reduce<number>((acc, className, index, array) => {
    const mdPrefix = 'MuiGrid-grid-md-'
    const xsPrefix = 'MuiGrid-grid-xs-'

    const mdClassName = className?.startsWith(mdPrefix) ? className : undefined
    const xsClassName = className?.startsWith(xsPrefix) ? className : undefined

    const mdSize = Number(mdClassName?.replace(mdPrefix, ''))
    const xsSize = Number(xsClassName?.replace(xsPrefix, ''))

    const isLast = index === array.length - 1

    if (acc || mdSize) return acc || mdSize
    if (isLast) return xsSize

    return acc
  }, 0)
}

/*
 * This function will group the elements by row. It will use the Material UI grid class names
 * to determine which columns are a row and then group them together.
 *
 * Ex.:
 *
 * Given this grid items:
 *
 * <Grid item xs={12} md={6} />
 * <Grid item xs={12} md={6} />
 * <Grid item xs={12} />
 *
 * The result is:
 *
 * [
 *   [<Grid item xs={12} md={6} />, <Grid item xs={12} md={6} />],
 *   [<Grid item xs={12} />]
 * ]
 */
export const groupElementsByRow = (elements: Element[]): Element[][] => {
  return elements.reduce<Element[][]>(
    (acc, item) => {
      const rowsLength = acc.length
      const lastIndex = rowsLength > 0 ? rowsLength - 1 : 0
      const lastRow = acc[lastIndex]

      const size = getElementColumnSize(item)

      const rowSize = lastRow.reduce((sum, col) => {
        return getElementColumnSize(col) + sum
      }, 0)

      if (rowSize + size <= maxGridColumns) {
        return acc.map((row, index) => (index === lastIndex ? [...lastRow, item] : row))
      }

      return [...acc, [item]]
    },
    [[]]
  )
}

/*
 * This function will set the column height. First, it will check what is
 * the column with the highest height and then will use this height into
 * all elements of the row, making them equal.
 *
 * The `float: left` will fix the alignment.
 */
export const setRowsElementsHeight = (elements: Element[][]): void => {
  elements.forEach((row) => {
    const colHeights = row.map((item) => item.getBoundingClientRect().height || 0)
    const highestHeight = Math.max(...colHeights)

    row.forEach((item) => {
      item.setAttribute('style', `height: ${highestHeight}px; float: left;`)
    })
  })
}

export const setRowsElementsLabelHeight = (elements: Element[][]): void => {
  const labelSelector = 'label'

  elements.forEach((row) => {
    const labelHeights = row.map((item) => {
      const label = item.querySelector(labelSelector)

      return label?.getBoundingClientRect().height || 0
    })

    const highestHeight = Math.max(...labelHeights)

    row.forEach((item) => {
      const label = item.querySelector(labelSelector)

      label?.setAttribute('style', `height: ${highestHeight}px`)
    })
  })
}

/*
 * This function will make sure the columns have the same height when
 * the media type is `print`.
 *
 * This proccess is needed because we can't use flex-box with `page-break-*`
 * css attributes, because they won't work well together. That's why we need
 * to stick to `display: block` and `display: inline-block` to handle
 * the PDF rendering.
 */
export const matchColumnSizes = (parentElement: Element | null): void => {
  const isPrint = window.matchMedia('print').matches

  if (parentElement && isPrint) {
    const gridItems = getGridItems(parentElement)

    if (gridItems) {
      const gridItemsByRow = groupElementsByRow(gridItems)

      setRowsElementsHeight(gridItemsByRow)
    }
  }
}

/*
 * This function will match the question headers height.
 */
export const matchHeaderSizes = (parentElement: Element | null): void => {
  if (parentElement) {
    const gridItems = getGridItems(parentElement)

    if (gridItems) {
      const gridItemsByRow = groupElementsByRow(gridItems)

      setRowsElementsLabelHeight(gridItemsByRow)
    }
  }
}
