import { useTranslation } from 'react-i18next'
import formatDefault from '@dentalux/common/date/formatDefault'
import { Typography, GlobalStyles } from '@mui/material'

import SummaryScreen from './components/Content'

import { Form, Clinic } from '@dentalux/ui-library-core'

import { Optins } from '@dentalux/ui-library-core'

import * as S from './PrintableSummary.styles'
import D21Logo from 'components/icons/D21Logo'

type Props = {
  values: Partial<Form>
  clinic?: Clinic
  optins?: Optins
}

const PrintableSummaryScreen = ({ values, clinic, optins }: Props) => {
  const { t } = useTranslation()

  return (
    <S.Root>
      <GlobalStyles styles={S.printableMediaStyles()} />

      <S.Header>
        <S.LogoWrapper>
          <D21Logo />
        </S.LogoWrapper>
      </S.Header>

      <S.Content>
        <SummaryScreen
          optins={optins}
          initialValues={{
            ...values,
            dataProtection: true,
            dataTruthyness: true,
          }}
        />
      </S.Content>

      <S.SignatureBlock>
        <S.DateAndPlaceWrapper>
          <Typography>
            {clinic?.city ? `${clinic.city}, ` : ''} {formatDefault(new Date())}
          </Typography>

          <S.SignatureLine />

          <Typography>{t('place_and_date')}</Typography>
        </S.DateAndPlaceWrapper>

        <S.SignatureWrapper>
          <S.InvisiblePlaceholder>SignaturePlaceholder</S.InvisiblePlaceholder>

          <S.SignatureLine />

          <Typography>{t('signature')}</Typography>
        </S.SignatureWrapper>
      </S.SignatureBlock>
    </S.Root>
  )
}

export default PrintableSummaryScreen
