import { useCallback } from 'react'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import DiseaseType from '../../../../@types/DiseaseType'
import diseaseTypeHelpers from '../../../../helpers/diseaseType'
import Title from '../Title'
import Group from '../Group'
import PreviewField from '../PreviewField'
import { DiseaseGroupFieldProps, Form } from '@dentalux/ui-library-core'

type Props = {
  values: Partial<Form>
}

const SummaryPreConditions = ({ values }: Props) => {
  const { t } = useTranslation()

  const getPreConditionEditableFieldProps = useCallback(
    (settings: { diseaseType: DiseaseType }) => {
      const selectedDiseases = values?.diseases || []

      const diseasesForCurrentType = selectedDiseases.filter((disease) => disease.type === settings.diseaseType)
      const hasDiseaseForThisType = diseasesForCurrentType.length > 0

      const valueParts = diseasesForCurrentType.reduce(
        (parts, currentDisease) => {
          const medications = currentDisease.medications?.join(', ') || ''

          return [
            ...parts,
            `${t(`option.${currentDisease.name}`, { defaultValue: currentDisease.name })}${
              medications.length > 0 ? ` (${medications})` : ''
            }`,
          ]
        },
        [t('yes')]
      )

      const value = hasDiseaseForThisType ? valueParts.join(', ') : t('no')

      return {
        label: t(diseaseTypeHelpers.getQuestionTranslationKey(settings.diseaseType).replace('field.', 'summary.')),
        value,
      }
    },
    [t, values?.diseases]
  )

  const renderPreConditionSummary = (options: Pick<DiseaseGroupFieldProps, 'hideMedications' | 'type'>) => {
    return <PreviewField {...getPreConditionEditableFieldProps({ diseaseType: options.type })} />
  }

  return (
    <>
      <Title>{t('subcategory.pre_existing_conditions')}</Title>

      <Group>
        {[
          DiseaseType.INFECTIOUS,
          DiseaseType.HEART,
          DiseaseType.CIRCULATORY_VASCULAR,
          DiseaseType.LUNG,
          DiseaseType.BONE_MUSCULAR_COGNITIVE_TISSUE,
          DiseaseType.CANCER,
          DiseaseType.METABOLIC,
          DiseaseType.GASTRIC_INTESTINAL,
          DiseaseType.LIVER_BILIARY,
          DiseaseType.KIDNEY_URINARY_TRACT,
          DiseaseType.PSYCHOLOGICAL,
          DiseaseType.OTHER,
        ].map((type) => (
          <Grid item key={type} xs={12} md={6}>
            {renderPreConditionSummary({ type, hideMedications: false })}
          </Grid>
        ))}
      </Group>
    </>
  )
}

export default SummaryPreConditions
