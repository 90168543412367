import { RouteChildrenProps } from 'react-router-dom'

import usePage from '../hooks/usePage'
import useSaveOnBack from 'hooks/useSaveOnBack'
import { useTranslation } from 'react-i18next'
import PageLayout from 'components/PageLayout'
import { WishesForm4 } from '@dentalux/ui-library-core'

const Wishes4Page: React.FC<RouteChildrenProps> = () => {
  const { initialValues = {}, onBack, ...page } = usePage()
  const { t } = useTranslation()

  const formikRef = useSaveOnBack({ onBack })

  return (
    <PageLayout title={t('category.additional_details')} subtitle={t('subcategory.let_us_know_about_you')}>
      <WishesForm4 innerRef={formikRef} initialValues={initialValues} loading={false} {...page} />
    </PageLayout>
  )
}

export default Wishes4Page
