import { useMemo, useContext, useEffect, createContext } from 'react'

import { QueryObserverResult, useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { AxiosError } from 'axios'

import { getPatient, getReferral } from '../services/api/ana'

import Patient from '../@types/Patient'
import { useAnswers, useSetAnswers } from './AnswersProvider'
import { routes } from '../config/routes'

export type PatientProviderContextType = {
  /* The QueryResult with the patient information retrieved from the provided code */
  shouldSkipReferral?: QueryObserverResult<boolean>
  patient?: QueryObserverResult<Patient>
  code?: string
}

export const PatientContext = createContext<PatientProviderContextType>({})

export type PatientProviderProps = {
  initialData?: Patient
}

const LONG_CODE_LENGTH = 10
export const SHORT_CODE_LENGTH = 4

const isQueryEnabled = (code: string | undefined): boolean =>
  !!(code?.length === SHORT_CODE_LENGTH) || !!(code && code.length >= LONG_CODE_LENGTH)

const PatientProvider: React.FC<PatientProviderProps> = ({ children, initialData }) => {
  const { code } = useAnswers()
  const history = useHistory()
  const location = useLocation()
  const setAnswers = useSetAnswers()

  const shouldSkipReferral = useQuery(['referral', code], () => getReferral(code || ''), {
    initialData: true,
    enabled: false,
    onSuccess: (shouldSkipReferral: boolean) => {
      setAnswers((previous) => ({
        ...previous,
        shouldSkipReferral,
      }))
    },
  })

  const patient = useQuery(['patient', code], () => getPatient(code || ''), {
    initialData,
    enabled: isQueryEnabled(code),
    retry: (_, error: AxiosError) =>
      error.response?.status !== 410 && error.response?.status !== 404 && error.response?.status !== 412,
    onSuccess: (patient: Patient) => {
      shouldSkipReferral.refetch()
      setAnswers((previous) => ({
        ...previous,
        code,
        patientHasHealthAssistant: patient.hasHealthAssistant,
        bookedForDifferentPerson: patient.bookedForDifferentPerson,
      }))
    },
  })

  const contextValue = useMemo(
    () => ({
      patient,
      shouldSkipReferral,
      code,
    }),
    [patient, code, shouldSkipReferral]
  )

  useEffect(() => {
    if (patient.data && code && location.pathname === routes.code.path) {
      history.push(routes.intro.path)
    }
  }, [patient.data, code, history, location.pathname, patient])

  return <PatientContext.Provider value={contextValue}>{children}</PatientContext.Provider>
}

export const usePatient = () => {
  const context = useContext(PatientContext)

  if (context === undefined) {
    throw new Error('usePatient must be used within PatientProvider.')
  }

  return context.patient
}

export default PatientProvider
