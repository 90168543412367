import enLocale from 'date-fns/locale/en-GB'
import deLocale from 'date-fns/locale/de'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useTranslation } from 'react-i18next'

const localeMap = {
  en: enLocale,
  de: deLocale,
}

const DatePickersUtilsProvider: React.FC = ({ children }) => {
  const { i18n } = useTranslation()

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={localeMap[i18n.language as keyof typeof localeMap]}
    >
      {children}
    </LocalizationProvider>
  )
}

export default DatePickersUtilsProvider
