import { Components, CSSObject, Theme } from '@mui/material'

const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiOutlinedInput-root': {
        padding: 'unset',
        ...(theme.typography['Sarabun Body 1'] as CSSObject),

        '.MuiAutocomplete-input': {
          height: '23px',
          padding: '22px 16px 3px',
          zIndex: 2,
        },
      },
    }),
    listbox: ({ theme }) => ({
      padding: 0,

      '& .MuiAutocomplete-option': {
        display: 'block',
        color: theme.palette.custom.grey[800],
        backgroundColor: theme.palette.custom.grey[0],
        padding: theme.spacing(1.5, 2),

        '&[aria-selected="true"]': {
          color: theme.palette.custom.aqua[900],
          backgroundColor: theme.palette.custom.aqua[75],
        },

        '&.Mui-focused': {
          backgroundColor: theme.palette.custom.grey[100],
        },
      },
    }),
    paper: ({ theme }) => ({
      borderRadius: theme.spacing(0, 0, 0.5, 0.5),
      ...(theme.typography['Sarabun Body 1'] as CSSObject),
    }),
  },
}

export default MuiAutocomplete
