import { useMemo } from 'react'

import PageLayout from 'components/PageLayout'
import { Formik } from 'formik'
import getAssetUrl from 'helpers/getAssetUrl'
import * as S from './Success.styles'
import { useTranslation } from 'react-i18next'
import { FormLayout, Translate, SubmitButton } from '@dentalux/ui-library-core'
import { LayoutItem } from '@dentalux/ui-library-core'

export type Props = {
  variant?: string | undefined
  registered: boolean
  inClinic: boolean
  onSubmit?: () => void
}

const SuccessScreen: React.FC<Props> = ({ inClinic, variant = 'default', registered, onSubmit }) => {
  const { t } = useTranslation()

  const layout: LayoutItem[] = [
    {
      type: 'custom',
      key: 'thankYouImage',
      xs: 12,
      render: () => {
        return (
          <S.Frame>
            <S.Image src={getAssetUrl('/images/success-illustration.png')} alt="thank you illustration" />
          </S.Frame>
        )
      },
    },
    {
      type: 'custom',
      key: 'assistantEncouragement',
      xs: 12,
      render: () => {
        if (inClinic) return null

        return <S.Text>{t(registered ? 'page.success.assistantLogin' : 'page.success.assistantRegister')}</S.Text>
      },
    },
  ]

  const textsByVariant: Record<string, Record<string, string>> = useMemo(
    () => ({
      default: {
        title: inClinic ? t('page.success.in_clinic.title') : t('page.success.title'),
        subtitle: inClinic ? t('page.success.in_clinic.subtitle') : '',
      },
      satisfied_filling_color: {
        title: t('page.success.inlays.title'),
        subtitle: t('page.success.inlays.subtitle'),
      },
    }),
    [inClinic, t]
  )

  const submitButton = (
    <SubmitButton loading={false} onClick={onSubmit} type="button">
      <Translate
        children={inClinic ? 'restart_anamnesis' : registered ? 'login_patient_account' : 'open_patient_account'}
      />
    </SubmitButton>
  )

  const texts = textsByVariant[variant]

  return (
    <PageLayout unsavedChangesDialog={false} title={texts.title} subtitle={texts.subtitle}>
      <Formik initialValues={{}} onSubmit={() => {}}>
        <FormLayout items={layout} submitButton={submitButton} />
      </Formik>
    </PageLayout>
  )
}

export default SuccessScreen
