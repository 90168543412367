import { CSSObject, styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'

export const Root = styled(Box)({})

export const Label = styled('label')(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  boxSizing: 'border-box',
  padding: theme.spacing(0.5, 1),
  backgroundColor: theme.palette.custom.aqua['50'],
  ...(theme.typography['Sarabun Caption'] as CSSObject),
}))

export const Value = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1),
  minHeight: theme.spacing(6),
  wordWrap: 'break-word',
}))

export const Asterisk = styled(Box)(({ theme }) => ({
  color: theme.palette.custom.error[500],
}))

Value.defaultProps = { variant: 'Sarabun Body 1' }
