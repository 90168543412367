const basePathByEnv: Record<string, string> = {
  production: 'https://ana.dental21.de',
  staging: 'https://staging-ana.dental21.de',
}

const basePath =
  basePathByEnv[process.env.REACT_APP_ENV || ''] ||
  // this is used in preview
  process.env.REACT_APP_PUBLIC_URL ||
  // will fallback to this in development
  ''

const getAssetUrl = (url: string): string => {
  return `${basePath}${url}`
}

export default getAssetUrl
