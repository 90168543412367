import { useCallback, useState, useRef, useMemo, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import Signature from 'signature_pad'
import { AnaDialog } from '@dentalux/ui-library-core'

import Button from '@mui/material/Button'
import signaturePadHelpers from '../SignaturePad/SignaturePad.helpers'
import SignaturePad from '../SignaturePad'
import Logger from 'services/logger'

import * as S from './SignatureModal.styles'

type Props = {
  name: string
  signature?: string
  isOpen?: boolean
  isSubmitting?: boolean
  onClose?: () => void
  onSubmit?: (signatureEncoded: string) => void
  onSignatureChange: (dataURL: string) => void
}

const signatureSizeThresholdForHighDPIScreens = 1
const signatureSizeThresholdForLowDPIScreens = 1

const signatureSizeThreshold =
  window.devicePixelRatio > 1 ? signatureSizeThresholdForHighDPIScreens : signatureSizeThresholdForLowDPIScreens

const SignatureModal = ({
  isOpen = false,
  isSubmitting = false,
  name,
  signature,
  onSignatureChange,
  onClose,
  onSubmit,
}: Props) => {
  const { t } = useTranslation()

  const signaturePadRef = useRef<Signature | undefined>()
  const [pixelsCount, setPixelsCount] = useState(0)

  // We divide by the device pixel ratio to have similar values for screens
  // with different DPIs.
  const isSignatureBigEnough = useMemo(
    () => pixelsCount / window.devicePixelRatio > signatureSizeThreshold,
    [pixelsCount]
  )

  const handleSubmit = useCallback(() => {
    const signatureEncoded = signaturePadRef.current?.toDataURL()

    Logger.debug('SignatureModal.handleSubmit', {
      signatureEncoded: (signatureEncoded ?? '').length,
    })

    if (onSubmit) onSubmit(signatureEncoded || '')
  }, [onSubmit])

  const handleSignatureChange = useCallback(
    (value: string, pixelsCount?: number) => {
      Logger.debug('SignatureModal.handleSignatureChange', {
        value,
        pixelsCount,
      })

      onSignatureChange(value)

      if (pixelsCount !== undefined) setPixelsCount(pixelsCount)
    },
    [onSignatureChange, setPixelsCount]
  )

  const handleCanvasReady = useCallback((canvas: HTMLCanvasElement) => {
    Logger.debug('SignatureModal.handleCanvasReady')

    setTimeout(() => {
      setPixelsCount(signaturePadHelpers.countPaintedPixels(canvas))
    }, 0)
  }, [])

  useEffect(() => {
    Logger.debug(isSignatureBigEnough ? 'The signature is big enough' : 'The signature is not big enough', {
      isSignatureBigEnough,
      pixelsCount,
      devicePixelRatio: window.devicePixelRatio,
      signatureSizeThreshold,
    })
  }, [isSignatureBigEnough, pixelsCount])

  return (
    <AnaDialog
      open={isOpen}
      title={t('field.data_truthyness')}
      actions={
        <>
          <Button onClick={onClose} variant="borderless">
            {t('close')}
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={isSubmitting || !isSignatureBigEnough}
            data-testid="signature-pad-sign-btn"
            variant="borderless"
          >
            {t('finish')}
          </Button>
        </>
      }
    >
      <SignaturePad
        innerRef={signaturePadRef}
        onChange={handleSignatureChange}
        onCanvasReady={handleCanvasReady}
        initialValue={signature}
        name={name}
      />

      <S.Message>{t('signature_helper')}</S.Message>
    </AnaDialog>
  )
}

export default SignatureModal
