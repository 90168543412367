import { Form, FieldSettings } from '@dentalux/ui-library-core'

/* Helper function that will return the needed isRequired value for given field.
 * Needed for visualizing '*' when the field is required
 */
export const getIsRequiredFromFunction = (
  formikValues: Partial<Form>,
  isRequired: (formikValues: Partial<Form>) => boolean
) => {
  return isRequired(formikValues) // call the isRequired function specified in ..src/config/fields
}

export const getIsRequired = (isRequired: FieldSettings['isRequired']) => {
  if (typeof isRequired === 'boolean' || typeof isRequired === 'undefined') return isRequired

  return undefined
}
