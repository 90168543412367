import { useRef, useLayoutEffect, ReactNode } from 'react'

import * as S from './Group.styles'
import { matchColumnSizes, matchHeaderSizes } from './Group.helpers'

const Group = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const ref = useRef<HTMLDivElement | null>(null)

  useLayoutEffect(() => matchColumnSizes(ref.current as Element | null), [ref, children])

  useLayoutEffect(() => matchHeaderSizes(ref.current as Element | null), [ref, children])

  return (
    <S.Root ref={ref} item xs={12} container>
      {children}
    </S.Root>
  )
}

export default Group
