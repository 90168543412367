import getAssetUrl from '../../helpers/getAssetUrl'
import { PageProps } from '../../hooks/usePage'
import { useTranslation } from 'react-i18next'
import * as S from './Intro.styles'
import * as PageS from '../../components/shared/PageLayout'
import Callout from '../../components/Callout'
import LanguageSwitch from 'components/LanguageSwitch/LanguageSwitch'
import { Translate } from '@dentalux/ui-library-core'
import { Form } from '@dentalux/ui-library-core'

export type Props = PageProps & {
  clinicName?: string
  dataProtectionUrl: string
  isInClinic?: boolean
  onStartForm: (values: Partial<Form>) => void
  onLanguageChange: (language: string) => void
}

const Intro: React.FC<Props> = ({
  clinicName = '',
  dataProtectionUrl,
  isInClinic = false,
  onStartForm,
  onLanguageChange,
}) => {
  const { t } = useTranslation()

  return (
    <S.Container>
      <PageS.Header>
        <S.LanguageSwitchContainer>
          <LanguageSwitch onChange={onLanguageChange} />
        </S.LanguageSwitchContainer>
        <PageS.Title>{t('category.anamnesis')}</PageS.Title>

        <PageS.Subtitle>{t('field.welcome', { clinicName })}</PageS.Subtitle>
      </PageS.Header>
      <S.ImageContainer>
        <S.Image src={getAssetUrl('/images/welcome-illustration.png')} alt="welcome illustration" />
      </S.ImageContainer>

      <S.Layout>
        <Callout
          children={
            <S.TextContainer>
              <S.Text>
                <Translate
                  link={dataProtectionUrl}
                  options={{
                    postProcess: ['markdownBlock'],
                  }}
                >
                  {'anamnesis_information.content'}
                </Translate>
              </S.Text>
              {!isInClinic && <S.Text>{t('anamnesis_information.see_you_in_person')}</S.Text>}
              <S.Text>{t('anamnesis_information.practice_team', { clinicName })}</S.Text>
            </S.TextContainer>
          }
        />
      </S.Layout>

      <S.Button loading={false} onClick={onStartForm}>
        {t('start')}
      </S.Button>
    </S.Container>
  )
}

export default Intro
