import uniq from 'lodash/uniq'
import isEqual from 'lodash/isEqual'

import { Comments, KeyedComment } from '../@types/Comments'

const keyedCommentToPair = (comment: KeyedComment) => [comment.key, comment.value]

/*
 * getKeyedCommentsDiff
 *
 * Compare to comments versions and decides whether or not a comment for a key has changed.
 */
const getKeyedCommentsDiff = (
  originalComments: KeyedComment[],
  currentComments: KeyedComment[]
): [string, boolean][] => {
  const originalByKey = Object.fromEntries(originalComments.map(keyedCommentToPair))
  const currentByKey = Object.fromEntries(currentComments.map(keyedCommentToPair))

  return [...Object.keys(originalByKey), ...Object.keys(currentByKey)].map((key) => [
    key,
    !isEqual(originalByKey[key as keyof Comments], currentByKey[key as keyof Comments]),
  ])
}

const getDiff = (original: Partial<Comments>, current: Partial<Comments>) => {
  const allKeys = uniq([...Object.keys(original), ...Object.keys(current)])

  const diff = allKeys.map((key) => [key, !isEqual(original[key as keyof Comments], current[key as keyof Comments])])

  const wishesDiff = getKeyedCommentsDiff(original.wishesAndExpectations || [], current.wishesAndExpectations || [])
  const diseasesDiff = getKeyedCommentsDiff(original.diseases || [], current.diseases || [])

  return Object.fromEntries([...diff, ...wishesDiff, ...diseasesDiff])
}

const CommentEntity = {
  getDiff,
}

export default CommentEntity
