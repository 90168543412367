import { createContext, useContext } from 'react'
import getSource from '../helpers/getSource'
import { useLocation } from 'react-router-dom'

const Context = createContext<string>('')

const SourceProvider: React.FC = ({ children }) => {
  const location = useLocation()
  const source = getSource(location.search)

  return <Context.Provider value={source}>{children}</Context.Provider>
}

export const useSource = () => {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error('useSource must be used within SourceProvider')
  }

  return context
}

export default SourceProvider
