import ReferralScreen from 'screens/Referral'
import usePage from '../hooks/usePage'

const ReferralPage: React.FC = () => {
  const page = usePage()

  return <ReferralScreen {...page} />
}

export default ReferralPage
