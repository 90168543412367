import { object } from 'yup'
import { FieldSettings } from '@dentalux/ui-library-core'

const buildValidationSchema = <T extends string>(fields: Record<T, FieldSettings>) => {
  return object().shape(
    Object.fromEntries(
      Object.values<FieldSettings>(fields)
        .map((settings) => [settings.name, settings.validation])
        .filter(([_, validation]) => Boolean(validation))
    )
  )
}

export default buildValidationSchema
