import { CircularProgress } from '@mui/material'

import useStyles from './FullPageLoading.styles'

const FullPageLoading: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress className={classes.progress} />
    </div>
  )
}

export default FullPageLoading
