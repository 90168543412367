import DiseaseType from '../@types/DiseaseType'
import { format } from 'date-fns'
import { Form } from '@dentalux/ui-library-core'

import _uniq from 'lodash/uniq'
import _get from 'lodash/fp/get'
import _identity from 'lodash/identity'
import dateHelpers from 'helpers/date'
import * as Disease from 'entities/Disease'
import * as DiffType from 'entities/DiffType'
import diffArrays from 'helpers/diffArray'
import DetailedDiff from '../@types/DetailedDiff'
import { Anamnesis } from '../@types/Anamnesis'

const fromForm = (form: Partial<Form>): Anamnesis => {
  return {
    lifeStyle: {
      consumesAlcohol: form.consumesAlcohol ?? false,
      consumesDrugs: form.consumesDrugs ?? false,
      isPregnant: form.isPregnant ?? false,
      isSmoker: form.isSmoker ?? false,
      occupation: form.occupation ?? null,
      pregnantMonths: form.pregnantMonths ?? null,
      cigarettesPerDay: form.cigarettesPerDay ?? null,
    },
    insurance: {
      id: '',
      holderFirstName: form.holderFirstName ?? null,
      holderLastName: form.holderLastName ?? null,
      holderBirthday: form.holderBirthday ?? null,
      holderCity: form.holderCity ?? null,
      holderStreet: form.holderStreet ?? null,
      hasCareLevel: form.hasCareLevel ?? null,
      careLevelLimitedInTime: form.careLevelLimitedInTime ?? null,
      holderZipcode: form.holderZipcode ?? null,
      company: form.insuranceCompany ?? '',
      type: form.type ?? '',
      companyId: form.insuranceCompany ?? null,
      hasDentalInsurance: form.hasDentalInsurance ?? null,
      hasFamilyInsurance: form.hasFamilyInsurance ?? null,
      dentalInsuranceCompany: form.dentalInsuranceCompany ?? null,
      livesOnDifferentAddress: form.livesOnDifferentAddress ?? false,
      dentalInsuranceCompanyId: form.dentalInsuranceCompanyId ?? null,
      hasCivilServiceAllowance: form.hasCivilServiceAllowance ?? null,
    },
    person: {
      firstName: form.firstName ?? '',
      lastName: form.lastName ?? '',
      address: {
        city: form.city ?? '',
        street: form.street ?? '',
        zipCode: form.zipCode ?? '',
      },
      email: form.email ?? null,
      birthday: form.birthday ?? '',
      phone: form.phone ?? '',
      gender: form.gender ?? '',
      placeOfBirth: form.placeOfBirth ?? '',
      alternativePhone: form.alternativePhone ?? null,
      contactPersonLastName: form.contactPersonLastName ?? null,
      contactPersonFirstName: form.contactPersonFirstName ?? null,
      contactPersonRelation: form.contactPersonRelation ?? null,
      filedForDifferentPerson: form.filedForDifferentPerson ?? null,
      id: '',
      referenceId: '',
    },
    hasAllergies: form.hasAllergies ?? 'UNKNOWN',
    diseases: form.diseases || [],
    wishesAndExpectations: form.wishesAndExpectations || [],
    medications: form.medications || [],
    weightOver120Kg: form.weightOver120Kg ?? false,
    heightOver2M: form.heightOver2M ?? false,
    referral: form.referral ?? null,
    allergyPass: form.allergyPass ?? false,
    comments: {},
  }
}

const toForm = (anamnesis: Anamnesis): Partial<Form> => {
  const {
    lifeStyle = {} as Anamnesis['lifeStyle'],
    insurance = {} as Anamnesis['insurance'],
    person: { address, ...person } = {} as Anamnesis['person'],
    comments: _,
    ...rest
  } = anamnesis

  const parseBirthday = (value: string) => {
    if (dateHelpers.BDAY_REGEX.test(value)) {
      return value
    }

    return format(new Date(person.birthday), 'dd.MM.yyyy')
  }

  return {
    ...lifeStyle,
    ...person,
    ...address,
    ...rest,
    filedForDifferentPerson: person.filedForDifferentPerson ?? false,
    contactPersonLastName: person.contactPersonLastName ?? undefined,
    contactPersonFirstName: person.contactPersonFirstName ?? undefined,
    contactPersonRelation: person.contactPersonRelation ?? undefined,
    email: person.email ?? undefined,
    alternativePhone: person.alternativePhone ?? undefined,
    occupation: lifeStyle.occupation ?? undefined,
    referral: rest.referral ?? undefined,
    birthday: person?.birthday ? parseBirthday(person.birthday) : '',
    consumesAlcohol: lifeStyle.consumesAlcohol ?? false,
    consumesDrugs: lifeStyle.consumesDrugs ?? false,
    isPregnant: lifeStyle.isPregnant ?? false,
    isSmoker: lifeStyle.isSmoker ?? false,
    pregnantMonths: lifeStyle.pregnantMonths ?? undefined,
    cigarettesPerDay: lifeStyle.cigarettesPerDay ?? undefined,
    hasFamilyInsurance: Boolean(insurance?.holderFirstName) ?? false,

    // insurance
    insuranceCompany: insurance?.company,
    insuranceCompanyId: insurance?.companyId || undefined,
    dentalInsuranceCompany: insurance?.dentalInsuranceCompany ?? undefined,
    dentalInsuranceCompanyId: insurance?.dentalInsuranceCompanyId ?? undefined,
    hasCivilServiceAllowance: insurance?.hasCivilServiceAllowance ?? false,
    type: insurance?.type ?? undefined,
    hasCareLevel: insurance?.hasCareLevel ?? undefined,
    careLevelLimitedInTime: insurance?.careLevelLimitedInTime ?? undefined,
    hasDentalInsurance: Boolean(insurance?.dentalInsuranceCompany || insurance?.dentalInsuranceCompanyId),
    entitledToIntegrationAssistance: insurance?.entitledToIntegrationAssistance ?? undefined,
    integrationAssistanceBasedOnType: insurance?.integrationAssistanceBasedOnType ?? undefined,
    holderFirstName: insurance?.holderFirstName ?? undefined,
    holderLastName: insurance?.holderLastName ?? undefined,
    holderBirthday: insurance?.holderBirthday ?? undefined,
    holderCity: insurance?.holderCity ?? undefined,
    holderStreet: insurance?.holderStreet ?? undefined,
    holderZipcode: insurance?.holderZipcode ?? undefined,
    livesOnDifferentAddress: insurance?.livesOnDifferentAddress ?? false,

    hasAllergies:
      rest.hasAllergies ??
      (rest.diseases.some(
        (disease) => disease.type === DiseaseType.OTHER_ALLERGIES || disease.type === DiseaseType.MEDICATION_ALLERGIES
      )
        ? 'YES'
        : 'NO'),
    wishesAndExpectations: (rest.wishesAndExpectations || []).map((wish) => ({
      ...wish,
      flag: wish.score ? undefined : wish.flag,
    })),
    medications: rest.medications ?? [],
    weightOver120Kg: rest.weightOver120Kg ?? undefined,
    heightOver2M: rest.heightOver2M ?? undefined,
  }
}

const getDiff = (originalAnamnesis: Anamnesis, currentAnamnesis: Anamnesis): DetailedDiff => {
  const originalForm = toForm(originalAnamnesis)
  const currentForm = toForm(currentAnamnesis)

  const diseasesDiff = Disease.getDiff(originalForm.diseases || [], currentForm.diseases || [])

  const wishesDiff = diffArrays(
    originalForm.wishesAndExpectations || [],
    currentForm.wishesAndExpectations || [],
    _get('key')
  )

  // Get all the keys from both versions to make sure that we don't skip any of them.
  // If we use only the keys from `originalForm`, we might not calculate the diff
  // for newly added fields in the current version
  const allFormKeys = _uniq([...Object.keys(originalForm), ...Object.keys(currentForm)])

  const simpleFormDiff = Object.fromEntries(
    allFormKeys.map((key) => {
      const diffType = DiffType.getType(originalForm[key as keyof Form], currentForm[key as keyof Form])

      return [key, diffType]
    })
  )

  return {
    ...simpleFormDiff,
    ...wishesDiff,
    diseases: diseasesDiff,
    medications: diffArrays(originalForm.medications || [], currentForm.medications || [], _identity),
  } as DetailedDiff
}

export default {
  toForm,
  fromForm,
  getDiff,
}
