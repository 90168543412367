import { CircularProgress, Typography } from '@mui/material'
import { Button } from '@dentalux/ui-library-core'
import { useTranslation } from 'react-i18next'
import * as S from './ConnectionErrorDialog.styles'

type ConnectionErrorDialogProps = {
  isOpen: boolean
  onClose?: () => void
  onRetry?: () => void
  isLoading?: boolean
}

const ConnectionErrorDialog: React.VFC<ConnectionErrorDialogProps> = ({ isOpen, isLoading, onRetry, onClose }) => {
  const { t } = useTranslation()

  return (
    <S.Dialog open={isOpen} onClose={!isLoading ? onClose : undefined}>
      <S.DialogContent>
        <S.Root>
          <Typography variant="Sarabun H4">{t('offline_error_title')}</Typography>
          <Typography variant="Sarabun H5">{t('offline_error_subtitle')}</Typography>

          <S.Illustration src="/images/connection-error-illustration.svg" />

          {onRetry && (
            <Button
              onClick={onRetry}
              disabled={isLoading}
              aria-busy={Boolean(isLoading)}
              endIcon={isLoading && <CircularProgress size={14} color="inherit" />}
            >
              {t('offline_error_button')}
            </Button>
          )}
        </S.Root>
      </S.DialogContent>
    </S.Dialog>
  )
}

export default ConnectionErrorDialog
