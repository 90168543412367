import enLocale from 'date-fns/locale/en-US'
import deLocale from 'date-fns/locale/de'
import format from 'date-fns/fp/format'
import startOfMonth from 'date-fns/fp/startOfMonth'
import subYears from 'date-fns/fp/subYears'
import pipe from '@dentalux/common/pipe'
import formatWithOptions from 'date-fns/fp/formatWithOptions'
import parse from 'date-fns/parse'
import isValid from 'date-fns/isValid'

const localesByCode = {
  en: enLocale,
  de: deLocale,
}

export const SHORT_BDAY_FORMAT = 'dd.MM.yy'
export const LONG_BDAY_FORMAT = 'dd.MM.yyyy'
export const BDAY_REGEX = /\d{2}\.\d{2}\.\d{2,4}/
export const ISO_DATE_REGEX = /^\d{4}-\d{2}-\d{2}/

export type SupportedLocale = keyof typeof localesByCode

export const getLocale = (language?: SupportedLocale): Locale => {
  if (!language) return localesByCode.en

  return localesByCode[language]
}

export const getStartOfMonthFiveYearsAgo = (): Date => {
  return pipe(subYears(5), startOfMonth)(new Date())
}

export const toISODate = format('yyyy-MM-dd')

export const toMonthAndYear = (date: Date, config: { language?: SupportedLocale } = {}): string => {
  return formatWithOptions({ locale: getLocale(config.language) || localesByCode.en })('LLLL yyyy')(date)
}

export const toShortMonthFormat = (date: Date, config: { language?: SupportedLocale } = {}): string => {
  return formatWithOptions({ locale: getLocale(config.language) || localesByCode.en })('dd MMM. yyyy')(date)
}

/*
 * formatBirthday
 *
 * Converts a string on the format `dd.MM.yy` or `dd.MM.yyyy` into `yyyy-MM-dd`
 */
export const formatBirthday = (date: string) => {
  const valueTrimmed = date.trim()
  const asDate = new Date(date)

  if (!valueTrimmed) return valueTrimmed
  if (isValid(asDate) && ISO_DATE_REGEX.test(valueTrimmed)) return toISODate(asDate)
  if (!BDAY_REGEX.test(valueTrimmed)) throw new Error('Wrong birthday format')

  const format = valueTrimmed.length === 8 ? SHORT_BDAY_FORMAT : LONG_BDAY_FORMAT

  return toISODate(parse(valueTrimmed, format, new Date()))
}

const dateHelpers = {
  getStartOfMonthFiveYearsAgo,
  toISODate,
  toMonthAndYear,
  getLocale,
  toShortMonthFormat,
  formatBirthday,

  SHORT_BDAY_FORMAT,
  LONG_BDAY_FORMAT,
  BDAY_REGEX,
}

export default dateHelpers
