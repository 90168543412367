import { createServer } from 'miragejs'

const fakePatient = {
  referenceId: '7b5824ab-09dc-4235-84f1-cffd9a621391',
  firstName: 'EndToEnd',
  lastName: 'Testing',
  gender: 'MALE',
  clinic: {
    referenceId: '091b3f7d-c56b-4972-b148-183eb761815f',
    name: 'Staging - Solutio',
    officialName: 'Clinic official',
    city: 'Berlin',
  },
  anamnesisState: [
    { step: 'ANAMNESIS', status: 'MISSING' },
    { step: 'DENTAL_TOS_ACCEPTED', status: 'MISSING' },
    { step: 'FACTORING_AGREEMENT', status: 'MISSING' },
  ],
}

const fakeFactoringAgreement = {
  documentKey: 'http://localhost:3000/static/factoring-example.pdf',
  factoringType: 'BFS',
  signed: false,
}

const mock = (): void => {
  createServer({
    routes() {
      this.urlPrefix = 'https://staging-ana-backend.dental21.de'
      this.namespace = 'api'

      this.passthrough('https://preview.contentful.com/**')
      this.passthrough('http://localhost:3000/**')

      this.get('/anamnesis/patient', () => fakePatient)

      this.get(`/patient/${fakePatient.referenceId}/factoring-agreement`, () => fakeFactoringAgreement)

      this.post(`/patient/${fakePatient.referenceId}/factoring-agreement/sign`, () => ({
        ...fakeFactoringAgreement,
        signed: true,
      }))

      this.post('/anamnesis', () => ({}))

      this.patch(`/patient/${fakePatient.referenceId}/legal-documents`, () => ({}))
    },
  })
}

export default mock
