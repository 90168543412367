import { styled } from '@mui/material/styles'
import MuiTypography from '@mui/material/Typography'

export const Button = styled('button', {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected?: boolean }>(({ theme, selected }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(0.5),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: theme.spacing(1.5, 0),
  cursor: 'pointer',
  height: '100%',

  border: '1px solid transparent',
  borderColor: selected ? theme.palette.primary.main : 'transparent',
  color: selected ? theme.palette.primary.main : theme.palette.custom.grey[800],

  '&:focus-visible': {
    outline: 'unset',
  },
}))

export const IconWrapper = styled('span')(() => ({
  textAlign: 'center',

  '& .MuiSvgIcon-root': {
    color: 'inherit',
  },
}))

export const Text = styled(MuiTypography)(() => ({
  textAlign: 'center',
}))

Text.defaultProps = {
  variant: 'Sarabun Subtitle 2',
}
