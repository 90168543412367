import { useAnswers } from 'providers/AnswersProvider'
import { usePatient } from 'providers/PatientProvider'
import { useMutation } from 'react-query'
import api from 'services/api'
import sentryService from 'services/sentry'
import * as GenericError from 'entities/GenericError'
import { AxiosError } from 'axios'
import { PostOptins } from 'services/api/ana'

export const usePostOptins = () => {
  const answers = useAnswers()
  const patient = usePatient()

  return useMutation(
    (optins: PostOptins) => {
      return api.ana.postOptins(optins)
    },
    {
      onError: (error: AxiosError) => {
        sentryService.captureLegalDocumentsError({ error, code: answers.code, clinic: patient?.data?.clinic })
      },
      retry: (failureCount, error) => failureCount <= 3 && GenericError.isNetworkError(error),
    }
  )
}
