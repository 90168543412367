import { printableMediaQuery } from '../../../../helpers/mediaQuery'

import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const StyledGridStreet = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: 'calc(50% - 1px)',
  },

  [printableMediaQuery]: {
    maxWidth: 'calc(50% - 1px)',
  },
}))

export const StyledGridEmail = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: 'calc(50% - 2px)',
  },

  [printableMediaQuery]: {
    maxWidth: 'calc(50% - 2px)',
  },
}))
