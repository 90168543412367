import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import MuiDialog from '@mui/material/Dialog'

const overlayWidth = 440
const summaryMaxWidth = 786

export const StyledDialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },

  '& .MuiDialog-scrollPaper': {
    alignItems: 'flex-start',

    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },

  '.MuiDialog-paper': {
    borderRadius: 10,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',

    [theme.breakpoints.up('sm')]: {
      minWidth: overlayWidth,
    },

    [theme.breakpoints.up('md')]: {
      marginLeft: summaryMaxWidth / 2 - overlayWidth / 2 + 60,
    },
  },
}))

export default makeStyles(
  () => ({
    title: {
      color: '#FF647C',
      fontSize: 18,
      paddingBottom: 0,
      lineHeight: '24px',
    },

    subtitle: {
      fontSize: 14,
      marginTop: 9,
    },

    content: {
      padding: 18,
      display: 'flex',
    },

    list: {
      paddingLeft: 18,
      paddingBottom: 0,
      marginBottom: 0,
      marginTop: 0,

      '& li': {
        fontSize: 14,
      },
    },

    iconWrapper: {
      marginRight: 16,
      backgroundColor: 'rgba(255, 100, 124, 0.3)',
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
    },

    closeBtn: {
      backgroundColor: '#ECEEF0',
      borderRadius: 8,

      '& .MuiButton-label': {
        textTransform: 'none',
        color: '#001429',
        fontSize: 13,
      },
    },

    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: 18,
      paddingTop: 0,
    },

    errorDetails: {
      backgroundColor: '#ECEEF0',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 18,
      paddingRight: 18,
      fontSize: 12,
    },

    errorCode: {
      fontStyle: 'italic',
    },

    errorDescription: {
      fontStyle: 'italic',
    },
  }),
  { name: 'ServerErrorOverlay' }
)
