import axios from 'axios'

import { addAuthInterceptors } from '@dentalux/security'
import { InsuranceCompany } from '@dentalux/ui-library-core'

const http = axios.create({
  baseURL: process.env.REACT_APP_INSURANCE_URL,
})

// @ts-ignore
addAuthInterceptors(http)

export const getInsuranceCompanies = async (): Promise<InsuranceCompany[]> => {
  const { data } = await http.get('/insurance-companies')

  return data
}
