import { useContext, createContext } from 'react'
import { GetLegalDocumentsReturn } from '../services/contentful'

type LegalDocumentsType = GetLegalDocumentsReturn
type Props = { value: LegalDocumentsType }

export const LegalDocumentsContext = createContext<LegalDocumentsType>({
  en: {},
  de: {},
})

const LegalDocumentsProvider: React.FC<Props> = ({ value, children }) => {
  return <LegalDocumentsContext.Provider value={value}>{children}</LegalDocumentsContext.Provider>
}

export const useLegalDocuments = (): LegalDocumentsType => {
  const context = useContext(LegalDocumentsContext) || {}

  if (context === undefined) {
    throw new Error('useLegalDocuments must be used within LegalDocumentsProvider')
  }

  return context
}

export default LegalDocumentsProvider
