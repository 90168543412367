import { styled } from '@mui/material/styles'
import ListItemButton from '@mui/material/ListItemButton'
import MuiListItemText from '@mui/material/ListItemText'

export const ListItem = styled(ListItemButton)<{ completed?: boolean }>(
  ({ theme }) => ({
    color: theme.palette.custom.grey[800],
    padding: theme.spacing(1.5, 2),
    margin: 0,
    borderBottom: '0.5px solid #ECEEF0',

    '&.Mui-selected': {
      color: theme.palette.primary.main,
      background: theme.palette.custom.grey[50],

      '&:hover': {
        background: theme.palette.custom.grey[100],
      },

      '& path': {
        fill: theme.palette.primary.main,
      },
    },

    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  }),
  {
    name: 'SideNavListItem',
  }
)

export const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  margin: 0,
}))
