import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import isEqual from 'lodash/isEqual'
import * as Sentry from '@sentry/react'

import PageLayout from 'components/PageLayout'

import {
  addressSchema,
  insuranceSchema,
  lifestyleSchema,
  getFormInitialErrors,
  personalInformationSchema,
  SelectInput,
} from '@dentalux/ui-library-core'
import { PageProps } from 'hooks/usePage'

import ServerError from '../../@types/ServerError'

import ensureConditionalFieldValues from 'helpers/ensureConditionalFieldsValues'
import ServerErrorOverlay from './components/ServerErrorOverlay'
import { getValidationSchemaForClinic } from '@dentalux/ui-library-core'
import { Clinic, Form, InsuranceCompany } from '@dentalux/ui-library-core'

import Summary from 'components/Summary'
import * as S from './Anamnesis.styles'
import { useFormik } from 'formik'
import { Comments } from '../../@types/Comments'
import CloseModal from 'components/CloseModal/CloseModal'
import CloseIcon from '@mui/icons-material/Close'
import Revision from '../../@types/Revision'
import { format, parse } from 'date-fns'
import { Button, CircularProgress } from '@mui/material'
import scrollToErrorMessage from 'helpers/scrollToErrorMessage'
import MuiIconButton from '@mui/material/IconButton'
import { createLayout as createAnamnesisLayout, createOptinsItems } from 'config/summary'
import { createLayout as createHighlightsLayout } from 'config/highlights'
import AnamnesisEntity from 'entities/Anamnesis'
import RevisionEntity from 'entities/Revision'
import { Anamnesis } from '../../@types/Anamnesis'
import CommentEntity from 'entities/Comment'
import DetailedDiff from '../../@types/DetailedDiff'
import { useCredentials } from '@dentalux/security'
import * as User from 'entities/User'
import { SelectChangeEvent } from '@mui/material'
import { useParams } from 'react-router-dom'
import { AnaPageUrlParams } from 'pages/Anamnesis'
import { useOptins } from 'hooks/useOptins'

export type AnamnesisScreenProps = Omit<PageProps, 'onSubmit'> & {
  anamnesisReferenceId: string
  clinic?: Clinic
  error?: ServerError
  insuranceCompanies: InsuranceCompany[]
  initialComments: Partial<Comments> | null
  versions: Revision[]
  version: number | string | null
  onVersionChange: (version: number | string) => void
  originalAnamnesis: Anamnesis | null
  isSaving?: boolean
  onSubmit: (values: Partial<Form>, comments: Partial<Comments>) => Promise<unknown>
  onClose: () => void
}

const createFormSchema = (clinic: Clinic | undefined) => {
  return Yup.object()
    .shape({})
    .concat(personalInformationSchema as Yup.ObjectSchema)
    .concat(clinic ? (getValidationSchemaForClinic(clinic) as Yup.ObjectSchema) : (addressSchema as Yup.ObjectSchema))
    .concat(lifestyleSchema as Yup.ObjectSchema)
    .concat(insuranceSchema as Yup.ObjectSchema)
}

const AnamnesisScreen: React.FC<AnamnesisScreenProps> = ({
  initialValues = {},
  insuranceCompanies,
  clinic,
  versions = [],
  error,
  initialComments,
  version,
  originalAnamnesis,
  isSaving = false,
  onVersionChange,
  onSubmit,
  onClose,
}) => {
  const { t, i18n } = useTranslation()
  const { authentication: user } = useCredentials()

  const { anamnesisReferenceId } = useParams<AnaPageUrlParams>()

  const [optins] = useOptins({ anamnesisReferenceId })

  const [closingModalAction, setIsClosingModalAction] = useState<'cancel' | 'exit' | undefined>()
  const [closeAfterSave, setCloseAfterSave] = useState(false)

  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
  const [comments, setComments] = useState<Partial<Comments>>(initialComments || {})

  const versionsOptions = useMemo(() => {
    return versions.map((version, index) => {
      const name = RevisionEntity.getAuthor(version, index === versions.length - 1)
      const date = format(parse(version.createdAt, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd.MM.yyyy, HH:mm')
      const parts = [name, date]

      return {
        label: parts.filter(Boolean).join(', '),
        value: version.version.toString(),
      }
    })
  }, [versions])

  useEffect(() => {
    if (initialComments) {
      setComments(initialComments)
    }
  }, [initialComments])

  const handleFormikSubmit = useCallback(
    async (values: Partial<Form>) => {
      const newValues = ensureConditionalFieldValues(values)

      try {
        await onSubmit(newValues, comments)

        if (closeAfterSave) {
          onClose()
        }
      } catch (e) {
        Sentry.captureException(e)

        setCloseAfterSave(false)
      }
    },
    [comments, onSubmit, closeAfterSave, onClose]
  )

  const handleClosingModalClose = useCallback(() => {
    setIsClosingModalAction(undefined)
  }, [setIsClosingModalAction])

  const validationSchema = useMemo(() => createFormSchema(clinic), [clinic])

  const initialErrors = useMemo(
    // @ts-ignore
    () => getFormInitialErrors(validationSchema, initialValues),
    [validationSchema, initialValues]
  )

  const formik = useFormik({
    initialValues,
    onSubmit: handleFormikSubmit,
    validationSchema,
    initialErrors,
    enableReinitialize: true,
  })

  const diff: DetailedDiff = useMemo(() => {
    if (!originalAnamnesis) return {}

    return AnamnesisEntity.getDiff(originalAnamnesis, AnamnesisEntity.fromForm(formik.values))
  }, [originalAnamnesis, formik.values])

  const highlightsLayout = useMemo(() => {
    return createHighlightsLayout({
      t,
      i18n,
      privateInsuranceCompanies: insuranceCompanies,
    })
  }, [t, i18n, insuranceCompanies])

  const anamnesisLayout = useMemo(() => {
    return createAnamnesisLayout({
      t,
      i18n,
      canEdit: !!User.canEditAnamnesis(user),
      privateInsuranceCompanies: insuranceCompanies,
      diseasesDiff: diff.diseases as DetailedDiff,
      medicationsDiff: diff.medications as DetailedDiff,
    })
  }, [t, i18n, user, insuranceCompanies, diff.diseases, diff.medications])

  const optinsLayout = useMemo(() => {
    return createOptinsItems({
      t,
      i18n,
      optins,
      reviewOptins: true,
    })
  }, [t, i18n, optins])

  const isEditing = useMemo(() => {
    return !isEqual(formik.values, initialValues) || !isEqual(comments, initialComments)
  }, [comments, initialComments, formik.values, initialValues])

  const commentsDiff = useMemo(() => {
    return CommentEntity.getDiff(originalAnamnesis?.comments || {}, comments)
  }, [originalAnamnesis, comments])

  const handleSubmit = useCallback(() => {
    formik.handleSubmit()
    scrollToErrorMessage()
  }, [formik])

  const handleModalSave = useCallback(() => {
    setCloseAfterSave(true)
    handleSubmit()

    if (closingModalAction !== 'exit') {
      handleClosingModalClose()
    }
  }, [closingModalAction, handleSubmit, handleClosingModalClose])

  const handleReset = useCallback(() => {
    if (closingModalAction === 'exit') {
      onClose()
    } else {
      formik.resetForm()
      handleClosingModalClose()
    }
  }, [formik, closingModalAction, onClose, handleClosingModalClose])

  const handleFormCancel = useCallback(() => {
    if (isEditing) setIsClosingModalAction('cancel')
  }, [isEditing, setIsClosingModalAction])

  const handleFormExit = useCallback(() => {
    if (!isEditing) onClose()
    else setIsClosingModalAction('exit')
  }, [isEditing, onClose, setIsClosingModalAction])

  const handleCloseError = () => setShowErrorModal(false)

  const handleSummaryChange = (values: Partial<Form>) => {
    formik.setValues(values, true)
  }

  const handleCommentsChange = (newComments: Partial<Comments>) => {
    setComments(newComments)
  }

  const handleVersionChange = (e: SelectChangeEvent<unknown>) => {
    onVersionChange(e.target.value as string)
  }

  useEffect(() => {
    if (error) {
      setShowErrorModal(true)
    }
  }, [error, setShowErrorModal])

  return (
    <PageLayout>
      {showErrorModal && error && <ServerErrorOverlay error={error} open={showErrorModal} onClose={handleCloseError} />}
      <CloseModal
        title="unsaved_changes"
        body="lose_all_changes"
        isOpen={Boolean(closingModalAction)}
        isSaving={isSaving || closeAfterSave}
        buttons={{
          save: {
            text: 'save',
            onClick: handleModalSave,
          },
          noSave: {
            text: 'proceed_without_saving',
            onClick: handleReset,
          },
          cancel: { text: 'cancel', onClick: handleClosingModalClose },
        }}
        onClose={handleClosingModalClose}
      />
      <S.StickyHeader>
        <S.ExitButtonContainer>
          <MuiIconButton onClick={handleFormExit}>
            <CloseIcon />
          </MuiIconButton>
        </S.ExitButtonContainer>

        <S.TopActions>
          <S.SelectWrapper>
            <SelectInput
              value={version ? version.toString() : ''}
              name="version"
              label="version"
              onChange={handleVersionChange}
              options={versionsOptions}
            />
          </S.SelectWrapper>

          {user && User.canEditAnamnesis(user) && (
            <S.ActionsContainer>
              <Button
                disabled={isSaving || !isEditing}
                onClick={handleFormCancel}
                data-testid="cancel-btn"
                type="button"
                variant="borderless"
              >
                {t('cancel')}
              </Button>

              <Button
                disabled={isSaving || (formik.values === formik.initialValues && comments === initialComments)}
                data-testid="submit-btn"
                type="button"
                endIcon={isSaving ? <CircularProgress color="inherit" size={14} thickness={5} /> : undefined}
                onClick={handleSubmit}
              >
                {t('save')}
              </Button>
            </S.ActionsContainer>
          )}
        </S.TopActions>
      </S.StickyHeader>

      <S.Container>
        <Summary
          title="highlights"
          values={formik.values}
          layout={highlightsLayout}
          onChange={handleSummaryChange}
          errors={formik.errors}
          onCommentsChange={handleCommentsChange}
          comments={comments}
          diff={diff}
          commentsDiff={commentsDiff}
          anamnesisStandaloneMode
        />
      </S.Container>
      <S.Container>
        <Summary
          title="anamnesis.summary"
          values={formik.values}
          layout={anamnesisLayout}
          onChange={handleSummaryChange}
          errors={formik.errors}
          anamnesisStandaloneMode
          onCommentsChange={handleCommentsChange}
          comments={comments}
          diff={diff}
          commentsDiff={commentsDiff}
        />
      </S.Container>
      <S.Container>
        <Summary
          title="anamnesis.optins"
          subtitle="anamnesis.optinsHelper"
          layout={optinsLayout}
          anamnesisStandaloneMode
        />
      </S.Container>
    </PageLayout>
  )
}

export default AnamnesisScreen
