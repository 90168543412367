import { DATA_PROTECTION_PATH, DATA_PROTECTION_PDF, PATIENT_INFORMATION_PATH } from 'config/constants'
import { Clinic } from '@dentalux/ui-library-core'

const removeTrailingSlash = (s: string) => s.replace(/\/$/, '')

const patientAccountInformationUrl = process.env.REACT_APP_PATIENT_ACCOUNT_URL + '/information'

const getInformationUrl = (clinic?: Pick<Clinic, 'webSiteUrl'>) => {
  const webSiteUrl = clinic?.webSiteUrl

  // Fallback to patient account information if the website url is not available
  if (!webSiteUrl) return patientAccountInformationUrl

  return removeTrailingSlash(webSiteUrl) + PATIENT_INFORMATION_PATH
}

const patientAccountTermsUrl = process.env.REACT_APP_PATIENT_ACCOUNT_URL + '/terms'

const getDataProtectionUrl = (clinic?: Pick<Clinic, 'webSiteUrl'>) => {
  const webSiteUrl = clinic?.webSiteUrl

  // Fallback to static PDF if the website url is not available
  if (!webSiteUrl) return DATA_PROTECTION_PDF

  return removeTrailingSlash(webSiteUrl) + DATA_PROTECTION_PATH
}

const ClinicEntity = {
  getInformationUrl,
  getDataProtectionUrl,
  patientAccountTermsUrl,
}

export default ClinicEntity
